// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import store from './store'
import moment from 'moment'
import * as vars from './vars.json'

const crypto = require('crypto')
const key = 'fjkmnsdfghnbfdvbndbhnfbghjnbvnhf'
const iv = crypto.randomBytes(16)

var accounting = require('accounting')

export function enviarEvento (nombreEvento, parametros = {}) {
}

export function validTimeuscription (objEnter) {
  let banContinue = true
  const suscription = store.state.suscriptionPlan.suscription
  const dateInit = new Date(suscription.created)
  const currentDate = new Date()

  const differenceTime = currentDate.getTime() - dateInit.getTime()
  const differenceDays = differenceTime / (1000 * 3600 * 24)
  if (differenceDays > suscription.days) {
    banContinue = false
  }

  return banContinue
}

export function encrypt (objEnter) {
  const text = JSON.stringify(objEnter)
  const cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv)
  let encrypted = cipher.update(text)
  encrypted = Buffer.concat([encrypted, cipher.final()])

  const obj = { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') }
  return obj
}

export function decrypt (obj) {
  const iv = Buffer.from(obj.iv, 'hex')
  const encryptedText = Buffer.from(obj.encryptedData, 'hex')
  const decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv)
  let decrypted = decipher.update(encryptedText)
  decrypted = Buffer.concat([decrypted, decipher.final()])
  const txt = JSON.parse(decrypted.toString())
  return txt
}

export function validIdentNumberRUC (type, number) {
  if (type) {
    const cedula = number
    let digitoRegion = 0
    let correct
    digitoRegion = cedula.substring(0, 2)
    if (digitoRegion >= 1 && digitoRegion <= 24) {
      let total = 0
      for (let i = 0; i < cedula.length; i++) {
        if (i % 2 === 0) {
          var aux = cedula[i] * 2
          if (aux > 9) aux -= 9
          total += aux
        } else {
          if (i !== 9) {
            total += parseInt(cedula[i])
          }
        }
      }
      const valid = Math.ceil(total / 10) * 10 - total
      const ultDigito = cedula.split('')[cedula.length - 1]
      if (Number(ultDigito) === Number(valid)) {
        correct = 1
      } else {
        correct = 2
      }
    } else {
      correct = 3
    }
    return correct
  } else {
    const ruc = number
    if (ruc !== null) {
      if (ruc.length === 13) {
        // Obtenemos el digito de la region que sonlos dos primeros digitos
        const digitoRegion = ruc.substring(0, 2)
        // Pregunto si la region existe ecuador se divide en 24 regiones
        if (digitoRegion >= 1 && digitoRegion <= 24) {
          const ultimosDigitos = ruc.substring(10, 13)
          if (ultimosDigitos === '001') {
            const tercerDigito = ruc.substring(2, 3)
            var coeficiente = []
            if (tercerDigito < 6) {
              coeficiente = [2, 1, 2, 1, 2, 1, 2, 1, 2]
            } else if (tercerDigito === '6') {
              coeficiente = [3, 2, 7, 6, 5, 4, 3, 2]
            } else if (tercerDigito === '9') {
              coeficiente = [4, 3, 2, 7, 6, 5, 4, 3, 2]
            } else {
              return 'RUC incorrecto'
            }
            let multiplicador
            let sumatoria = 0
            for (let coefic = 0; coefic < coeficiente.length; coefic++) {
              multiplicador = ruc.substring(coefic, coefic + 1) * coeficiente[coefic]
              if (tercerDigito < 6) {
                if (multiplicador > 9) {
                  multiplicador = multiplicador - 9
                }
                sumatoria = sumatoria + multiplicador
              } else {
                sumatoria = sumatoria + multiplicador
              }
            }
            let respuesta
            let divisor
            if (tercerDigito < 6) {
              divisor = 10
            } else {
              divisor = 11
            }
            respuesta = sumatoria % divisor
            if (respuesta !== 0) {
              respuesta = divisor - respuesta
            }
            if (respuesta.toString() === ruc.substring(coeficiente.length, (coeficiente.length + 1))) {
              return 1
            } else {
              const ultimosDigitos = ruc.substring(10, 13)
              if (ruc.length === 13 && ultimosDigitos === '001') {
                return 1
              }
              return 0
            }
          } else {
            return 0
          }
        } else {
          return 0
        }
      } else {
        return 0
      }
    }
    return 1
  }
}

export function validIdentNumber (cedula) {
  let digitoRegion = 0
  let correct
  cedula = cedula.toString()
  digitoRegion = cedula.toString().substring(0, 2)
  if (digitoRegion >= 1 && digitoRegion <= 24) {
    let total = 0
    for (let i = 0; i < cedula.length; i++) {
      if (i % 2 === 0) {
        var aux = cedula[i] * 2
        if (aux > 9) aux -= 9
        total += aux
      } else {
        if (i !== 9) {
          total += parseInt(cedula[i])
        }
      }
    }
    const valid = Math.ceil(total / 10) * 10 - total
    const ultDigito = cedula.toString().split('')[cedula.length - 1]
    if (Number(ultDigito) === Number(valid)) {
      correct = 1
    } else {
      correct = 2
    }
  } else {
    correct = 3
  }
  return correct
}

export function JSONToCSVConvertor (JSONData, ReportTitle, ShowLabel) {
  // If JSONData is not an object then JSON.parse will parse the JSON string in an Object
  const arrData = typeof JSONData !== 'object' ? JSON.parse(JSONData) : JSONData

  let CSV = 'sep=,' + '\r\n\n'

  // This condition will generate the Label/Header
  if (ShowLabel) {
    let row = ''

    // This loop will extract the label from 1st index of on array
    // eslint-disable-next-line no-unused-vars
    for (const index in arrData[0]) {
      // Now convert each value to string and comma-seprated
      row += index + ','
    }

    row = row.slice(0, -1)

    // append Label row with line break
    CSV += row + '\r\n'
  }

  // 1st loop is to extract each row
  for (let i = 0; i < arrData.length; i++) {
    let row = ''

    // 2nd loop will extract each column and convert it in string comma-seprated
    // eslint-disable-next-line no-unused-vars
    for (const index in arrData[i]) {
      row += '"' + arrData[i][index] + '",'
    }

    row.slice(0, row.length - 1)

    // add a line break after each row
    CSV += row + '\r\n'
  }

  if (CSV === '') {
    alert('Invalid data')
    return
  }

  // Generate a file name
  let fileName = 'Informacion_'
  // this will remove the blank-spaces from the title and replace it with an underscore
  fileName += ReportTitle.replace(/ /g, '_')

  // Initialize file format you want csv or xls
  const uri = 'data:text/csv;charset=utf-8,' + escape(CSV)

  // Now the little tricky part.
  // you can use either>> window.open(uri);
  // but this will not work in some browsers
  // or you will not get the correct file extension

  // this trick will generate a temp <a /> tag
  const link = document.createElement('a')
  link.href = uri

  // set the visibility hidden so it will not effect on your web-layout
  link.style = 'visibility:hidden'
  link.download = fileName + '.csv'

  // this part will append the anchor tag and remove it after automatic click
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

// metodo para calcular la edad actual de los pacientes en dias, meses y años
export function getAge (dateString) {
    var start = moment(dateString)
    var end = moment()

    var years = end.diff(start, 'year')
    start.add(years, 'years')

    var months = end.diff(start, 'months')
    start.add(months, 'months')

    var days = end.diff(start, 'days')
    return [years, months, days]
}
export function logOut () {
  firebase.auth().signOut().then(() => {
    localStorage.clear()
    store.dispatch('users/cleanStates')
    store.dispatch('patients/cleanData')
    store.dispatch('general/clearData')
    store.dispatch('financial/clearData')
  })
}

export function specialCharactersTildes (s) {
  var r = s.toLowerCase()
  r = r.replace(new RegExp(/[àáâãäå]/g), 'a')
  r = r.replace(new RegExp(/[èéêë]/g), 'e')
  r = r.replace(new RegExp(/ñ/g), 'n')
  r = r.replace(new RegExp(/[òóôõö]/g), 'o')
  r = r.replace(new RegExp(/[ùúûü]/g), 'u')
  return r.toUpperCase()
}

export function printData (data, profile, myWindow) {
  return new Promise(resolve => {
    const srtObligado = data.companyData.obligadoContabilidad ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>OBLIGADO A LLEVAR CONTABILIDAD: </strong>SI</p>' : '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>OBLIGADO A LLEVAR CONTABILIDAD: </strong>NO</p>'
    const srtAgente = (data.companyData.agenteRetencion.toString() !== 'NO APLICA' && data.companyData.agenteRetencion.toString() !== '') ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">AGENTE DE RETENCIÓN</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>RESOLUCIÓN No. </strong> ' + data.companyData.agenteRetencion + '</p>' : ''
    const srtContEspe = (data.companyData.contribuyenteEspecial.toString() !== 'NO APLICA' && data.companyData.contribuyenteEspecial.toString() !== '') ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">Contribuyente Especial Nro.</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">' + data.companyData.contribuyenteEspecial + '</p>' : ''
    const strRegMic = data.companyData.regimenMicroempresas ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">CONTRIBUYENTE RÉGIMEN MICROEMPRESAS</p>' : ''
    const strRegEmp = data.companyData.regimenRimpeEmprendedor ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">CONTRIBUYENTE RÉGIMEN RIMPE - EMPRENDEDOR</p>' : ''
    const strRegNeg = data.companyData.regimenRimpeNegocio ? '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">CONTRIBUYENTE RÉGIMEN RIMPE - NEGOCIO POPULAR</p>' : ''

    let strRegMicro = ''

    if (strRegMic !== '') {
      strRegMicro = strRegMic
    }
    if (strRegEmp !== '') {
      strRegMicro = strRegEmp
    }
    if (strRegNeg !== '') {
      strRegMicro = strRegNeg
    }

    const strClient = (data.client.businessName && data.client.businessName !== '') ? data.client.businessName : data.client.name + ' ' + data.client.lastName
    let strProds = ''
    for (let i = 0; i < data.lstProductos.length; i++) {
      const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].valorUnitario)) * parseFloat(data.lstProductos[i].cantidad), 3)
      strProds = strProds + ' <tr> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="producto">$' + (data.lstProductos[i].descuento ? data.lstProductos[i].descuento : '0.00') + '</td> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="precio">$' + strActiveValue + '</td> ' +
        '    </tr> '
    }
    let strHtml = '<html><head><title>my div</title>' +
      '</head><body >' +
      '<div>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 14px; font-family: Courier New, monospace">' + profile.razonSocial + '</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>RUC: </strong>' + profile.rucEstablecimieto + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>TELÉFONO: </strong>' + profile.telefonoEstablecimiento + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">' + profile.direccionEstablecimiento + '</p>' +
      '<br>' +
      srtObligado +
      srtAgente +
      srtContEspe +
      strRegMicro +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>FACTURA: </strong>' + data.numsecuencial + '</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>NÚMERO DE AUTORIZACIÓN</strong></p>' +
      '<p style="text-align: left; margin: 2px; font-size: 10px; font-family: Courier New, monospace">' + data.claveAcceso + '</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>CLAVE DE ACCESO SRI</strong></p>' +
      '<p style="text-align: left; margin: 2px; font-size: 10px; font-family: Courier New, monospace">' + data.claveAcceso + '</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>AMBIENTE: </strong>' + data.companyData.ambiente.texto + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>FECHA: </strong>' + data.fechaEmision + '</p>' +
      '<p style="margin: 2px; font-size: 10px; font-family: Courier New, monospace"><strong>M. PAGO: </strong>' + data.formapago.formapago + '</p>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>CLIENTE: </strong>' + strClient + '</p>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>RUC/CI: </strong>' + data.client.ide + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>DIR. </strong>' + data.client.address + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>EMAIL: </strong>' + data.client.mail + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>TLF: </strong>' + data.client.cellphone + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<table> ' +
      '<thead> ' +
      '    <tr> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="cantidad">CANT</th> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="producto">PRODUCTO</th> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="producto">DCTO.</th> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="precio">TOT.</th> ' +
      '    </tr> ' +
      '</thead> ' +
      '<tbody> ' +
      strProds +
      '</tbody> ' +
      '</table> ' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>SUB TOTAL: </strong>$' + data.subtotal + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>DCTO: </strong>$' + data.totdcto + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>IVA ' + vars.validIva + '%: </strong>$' + (parseFloat(data.total) - parseFloat(data.subtotal)).toFixed(2) + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>TOTAL: </strong>$' + data.total + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace" class="centrado">¡GRACIAS POR SU COMPRA! ' +
      '<br>J2RTECH.COM</p></div>' +
      '<br>' +
      '<br>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '</body></html>'
    strHtml = specialCharactersTildes(strHtml)
    myWindow.document.write(strHtml)
    myWindow.document.close()
    myWindow.print()
    myWindow.close()
    resolve(true)
  })
}

export function printDataNote (data, profile, name) {
  return new Promise(resolve => {
    const myWindow = window.open('', name, 'fullscreen="yes"')
    const strClient = (data.client.businessName && data.client.businessName !== '') ? data.client.businessName : data.client.name + ' ' + data.client.lastName
    let strProds = ''
    for (let i = 0; i < data.lstProductos.length; i++) {
      const strActiveValue = accounting.toFixed((parseFloat(data.lstProductos[i].producto.activeWValue)) * parseFloat(data.lstProductos[i].cantidad), 3)
      strProds = strProds + ' <tr> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="cantidad">' + data.lstProductos[i].cantidad + '</td> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="producto">' + data.lstProductos[i].producto.name + '</td> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="producto">$' + (data.lstProductos[i].descuento ? data.lstProductos[i].descuento : '0.00') + '</td> ' +
        '        <td style="font-size: 10px; font-family: Courier New, monospace" class="precio">$' + strActiveValue + '</td> ' +
        '    </tr> '
    }
    let strHtml = '<html><head><title>print-note</title>' +
      '</head><body >' +
      '<div>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 14px; font-family: Courier New, monospace">' + profile.razonSocial + '</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>RUC: </strong>' + profile.rucEstablecimieto + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>TELÉFONO: </strong>' + profile.telefonoEstablecimiento + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace">' + profile.direccionEstablecimiento + '</p>' +
      '<br>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>TICKET: </strong>' + data.numsecuencial + '</p>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>FECHA: </strong>' + data.fechaEmision + '</p>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>CLIENTE: </strong>' + strClient + '</p>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>RUC/CI: </strong>' + data.client.ide + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>DIR. </strong>' + data.client.address + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>EMAIL: </strong>' + data.client.mail + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>TLF: </strong>' + data.client.cellphone + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<table> ' +
      '<thead> ' +
      '    <tr> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="cantidad">CANT</th> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="producto">PRODUCTO</th> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="producto">DCTO.</th> ' +
      '        <th style="font-size: 10px; font-family: Courier New, monospace" class="precio">TOT.</th> ' +
      '    </tr> ' +
      '</thead> ' +
      '<tbody> ' +
      strProds +
      '</tbody> ' +
      '</table> ' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>SUB TOTAL: </strong>$' + data.subtotal + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>IVA ' + vars.validIva + '%: </strong>$' + (parseFloat(data.total) - parseFloat(data.subtotal)).toFixed(2) + '</p>' +
      '<p style="text-align: left; margin: 2px; font-size: 12px; font-family: Courier New, monospace"><strong>TOTAL: </strong>$' + data.total + '</p>' +
      '<p style="margin: 2px; font-size: 12px; font-family: Courier New, monospace" class="centrado">¡GRACIAS POR SU COMPRA! ' +
      '<br>J2RTECH.COM</p></div>' +
      '<br>' +
      '<br>' +
      '<br>' +
      '<p style="margin: 2px; font-size: 12px; font-family: monospace">-----------------------------------</p>' +
      '</body></html>'
    strHtml = specialCharactersTildes(strHtml)
    myWindow.document.write(strHtml)
    myWindow.document.close()
    myWindow.print()
    myWindow.close()
    resolve(true)
  })
}
