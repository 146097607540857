// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const chat = {
  namespaced: true,
  state: {
    lstDrMessage: [],
    lstAdminMessage: [],
    lstUsers: [],
    lstDrData: {},
  },
  mutations: {
    SET_CHAT_DR_MESSAGE_LST: (state, data) => {
      state.lstDrMessage = data
    },
    SET_CHAT_ADMIN_MESSAGE_LST: (state, data) => {
      state.lstAdminMessage = data
    },
    SET_CHAT_USERS_LST: (state, data) => {
      state.lstUsers = data
    },
    SET_CHAT_DRSDATA: (state, data) => {
      state.lstDrData = data
    },
  },
  actions: {
    loadDrData ({ commit, rootState }, [drId]) {
      firebase.database().ref(`users/${drId}`).on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            commit('SET_CHAT_DRSDATA', res)
          } else {
            commit('SET_CHAT_DRSDATA', [])
          }
        } else {
          commit('SET_CHAT_DRSDATA', [])
        }
      })
    },
    loadChatUsersLst ({ commit, rootState }) {
      firebase.database().ref('users/').on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('SET_CHAT_USERS_LST', arr)
          } else {
            commit('SET_CHAT_USERS_LST', [])
          }
        } else {
          commit('SET_CHAT_USERS_LST', [])
        }
      })
    },
    loadChatDrMessageLst ({ commit, rootState }, [currentDate, drIdSelected]) {
      firebase.database().ref(`chat/${drIdSelected}/${currentDate}`).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('SET_CHAT_DR_MESSAGE_LST', arr)
          } else {
            commit('SET_CHAT_DR_MESSAGE_LST', [])
          }
        } else {
          commit('SET_CHAT_DR_MESSAGE_LST', [])
        }
      })
    },
    loadChatAdminMessageLst ({ commit, rootState }, [currentDate, drIdSelected]) {
      firebase.database().ref(`chat/${drIdSelected}/${currentDate}`).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('SET_CHAT_ADMIN_MESSAGE_LST', arr)
          } else {
            commit('SET_CHAT_ADMIN_MESSAGE_LST', [])
          }
        } else {
          commit('SET_CHAT_ADMIN_MESSAGE_LST', [])
        }
      })
    },
    saveChatMessage ({ state }, [currentDrId, messageFormated, currentDate, sender]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`/chat/${currentDrId}/${currentDate}`).push({ message: messageFormated, creator: sender }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  },
}
