<template lang='pug'>
  div#user-profile(fluid='' tag='section')
    <!--v-dialog(v-if="dialogFac" fullscreen="" v-model='dialogFac' width='800')-->
    <!--v-card-->
    <!--v-toolbar(text='' color='primary' dark='')-->
    <!--v-toolbar-title Validación de datos para Facturación Electrónica-->
    <!--v-btn(icon="" dark="" @click="exitFacDialog" style="position: absolute; top: 0px; right: 10px;")-->
    <!--v-icon.primary.rounded mdi-close-->
    <!--v-card-text-->
    <!--v-row-->
    <!--v-col(cols="12")-->
    <!--v-stepper(v-model='e1')-->
    <!--v-stepper-header-->
    <!--v-stepper-step(:complete='e1 > 1' step='1') Información de Perfil de Empresa-->
    <!--v-divider-->
    <!--v-stepper-step(:complete='e1 > 2' step='2') Pasos para adquirir el Certificado Electrónico-->
    <!--v-divider-->
    <!--v-stepper-step(:complete='e1 > 3' step='3') Subir Certificado Electrónico-->
    <!--v-divider-->
    <!--v-stepper-items-->
    <!--v-stepper-content(step='1')-->
    <!--business-profile-->
    <!--v-btn(color='primary' @click='nextEventProcess' :loading="loadingSaveData")-->
    <!--| Siguiente-->
    <!--v-stepper-content(step='2')-->
    <!--electronic-certification-information-->
    <!--v-btn(color='red' text='' @click='e1 = 1') Atras-->
    <!--v-btn(color='primary' @click='nextEventProcessTwo' :loading="loadingSaveData")-->
    <!--| Siguiente-->
    <!--v-stepper-content(step='3')-->
    <!--electronic-certification-controller-->
    <!--v-btn(color='red' text='' @click='e1 = 2') Atras-->
    <!--v-btn(color='primary' @click='exitFacDialog')-->
    <!--| Finalizar-->
    v-dialog(v-model='phoneInputDialog' persistent max-width='290')
      v-card
        v-card-title.headline
          | Número telefónico requerido
        v-card-text Por la seguridad e integridad de sus datos, recomendamos ingresar su número de teléfono para facilitar la recuperación de su cuenta en caso de ser necesario.
        v-card-actions
          v-spacer
            v-btn(color='blue darken-1' text='' @click='phoneInputDialog = false')
              | Aceptar
    v-dialog(v-model='confirmationDialogVerify' persistent max-width='290')
      v-card
        v-card-title.headline
          | Cambiar Contraseña
        v-card-text Se enviará un link a tu correo electrónico registrado para restablecer tu contraseña. Estás seguro de continuar?
        v-card-actions
          v-spacer
            v-btn(color='blue darken-1' @click='verifyPassword')
              | ACEPTAR
            v-btn(color='red darken-1' text='' @click='confirmationDialogVerify = false')
              | CANCELAR
    v-row(justify='center')
      v-dialog(v-model='dialog' scrollable='' max-width='400px')
        v-card
          v-card-title Selecciona una Imagen
          v-divider
          v-card-text(style='text-align: center;')
            img(:src='imageUrl' height='150' v-if='imageUrl' alt='imagen de selección')
            v-text-field(label='Seleccionar' @click='pickFile' v-model='imageName' prepend-icon='mdi-upload')
            input(type='file' style='display: none' ref='image' accept='image/*' @change='onFilePicked')
          v-divider
          v-card-actions
            v-spacer
            v-btn(color='red' text='' @click='dialog=false') CANCELAR
            v-btn(color='blue darken-1' @click='upload') ACEPTAR
    v-row(justify='center')
      v-dialog(v-model='dialogLogo' scrollable='' max-width='400px')
        v-card
          v-card-title Selecciona una Imagen
          v-divider
          v-card-text(style='text-align: center;')
            img(:src='imageLogoUrl' height='150' v-if='imageLogoUrl' alt='imagen de selección')
            v-text-field(label='Seleccionar' @click='pickFileLogo' v-model='imageNameLogo' prepend-icon='mdi-upload')
            input(type='file' style='display: none' ref='imageLogo' accept='image/*' @change='onFilePickedLogo')
          v-divider
          v-card-actions
            v-spacer
            v-btn(color='red' text='' @click='dialogLogo=false') CANCELAR
            v-btn(color='blue darken-1' @click='uploadLogo') ACEPTAR
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' text='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-row(justify='center')
      v-col(cols='12' md='8')
        //v-row
        //  v-card
            //v-row(style='display:flex; justify-content:flex-start; align-items:center;')
            //  v-col(cols="9" style='display:flex; justify-content:flex-start;')
            //    label.mx-2(style="padding:0; font-size: 15px; font-weight: bold;") Módulo de Facturación
            //  v-col(cols="3" style='display:flex; justify-content:flex-end;')
            //    v-switch.ml-1(v-model='user.billing' inset @change="changeSwitch()")
            //v-col(cols="4" v-if="user.billing" style='padding:0;')
            //v-btn(color='secondary' @click='dialogFac = true')
            //v-icon mdi-wrench
        base-material-card
          template(v-slot:heading='')
            v-row
              v-col(cols="8")
                .display-2.font-weight-light
                  | Editar Perfil
                .subtitle-1.font-weight-light
                  | Completa tu perfil
              //- v-col(cols="8")
              //-   v-row
              //-     v-col(cols="2")
              //-       v-switch(v-model='user.billing' inset @change="changeSwitch()")
              //-     v-col(cols="7" style="margin-top: 18px;")
              //-       label(style="font-size: 20px; font-weight: bold;") Módulo de Facturación
              //-     v-col(cols="3" v-if="user.billing" style="margin-top: 12px;")
              //-       v-btn.mr-0(color='secondary' @click='dialogFac = true' style="width: 100%")
              //-         | ABRIR
          v-form
            //- v-container.py-0
            v-row
              //- <!--v-col(cols='12' md='4')-->
              //-   <!--v-text-field(label='Company (disabled)' disabled='')-->
              //- <!--v-col(cols='12' md='4')-->
              //-   <!--v-text-field.purple-input(label='User Name')-->
              v-col(cols='12' md='4')
                v-text-field.purple-input(label='Correo' v-model='user.email' disabled)
              v-col(cols='12' md='4')
                v-text-field.purple-input(:label='banCode ? "Nombres" : "Nombre de la Clínca"' v-model='user.firstName')
              v-col(cols='12' md='4')
                v-text-field.purple-input(:label='banCode ? "Apellidos" : "Representante"' v-model='user.lastName')
              v-col(cols='12' md="6")
                v-text-field.purple-input(label='Teléfono' type='number' v-model='user.phone')
              v-col(cols='12' md="6")
                v-text-field.purple-input(label='Identificación' type='number' v-model='user.ide')
              v-col(cols='12' md="12")
                v-text-field.purple-input(label='Dirección de Consultorio o Clínica' v-model='user.address')
              v-col(cols='12' md='4')
                v-select(v-model='paisVal' :items='paisLst' item-text='id' label='País' hide-details='' prepend-icon='mdi-map' single-line='')
              v-col(cols='12' md='4')
                v-select(v-model='provinciaVal' :items='provinciaLst' item-text='id' label='Provincia' hide-details='' prepend-icon='mdi-map' single-line='')
              v-col(cols='12' md='4')
                v-select(v-model='cantonVal' :items='cantonLst' item-text='id' label='Ciudad' hide-details='' prepend-icon='mdi-map' single-line='')
              v-col(cols='12' md='8')
                v-select(v-model='parroquiaVal' :items='parroquiaLst' item-text='id' label='Parroquia' hide-details='' prepend-icon='mdi-map' single-line='')
              v-col(cols='12' md='4')
                v-text-field.purple-input(label='Código Postal' type='number' v-model='user.postalCode')
              v-col(cols='12')
                v-textarea.purple-input(:label='banCode ? "Acerca de mí" : "Acerca de la Clínica"' value='' v-model='user.information')
              v-row
                v-col.text-right(cols="12" md="4" offset-md="4")
                  v-btn.mr-0(color='secondary' @click='confirmationDialogVerify = true' style="width: 100%")
                    | CAMBIAR CONTRASEÑA
                v-col.text-right(cols='12' md="4")
                  v-btn.mr-0(color='primary' @click='updateProfile' :loading="loadingSaveUser" style="width: 100%")
                    | GUARDAR CAMBIOS
      v-col(cols='12' md='4')
        base-material-card.v-card-profile(v-bind:avatar='user.urlImg' title='Datos')
          v-card-text.text-center
            h6.display-1.mb-3.dark-grey--text(style='font-family: "ceraProBlack";')
              | {{banCode ? "Profesional" : "Clínica"}}
            h4.display-2.font-weight-bold.mb-3.black--text
              | {{banCode ? "Dr." : ""}} {{user.firstName}} {{user.lastName}}
            p.font-weight-light.dark-grey--text
              | Creado: {{user.create}}
            p.font-weight-light.dark-grey--text
              | Actualizado: {{user.update}}
            p.font-weight-light.dark-grey--text.mb-5
              | Estado: {{user.status != null ? (user.status != false ? 'ACTIVO' : 'INACTIVO') : 'INACTIVO'}}
            v-btn.mr-0(color='primary' rounded='' @click='dialog = true')
              | SUBIR IMAGEN
              br
              | DE PERFIL
        base-material-card.v-card-profile(v-bind:avatar='user.urlImgLogo' title='Datos' style="margin-top: 100px;")
          v-card-text.text-center
            v-btn.mr-0(color='primary' rounded='' @click='dialogLogo = true')
              | SUBIR LOGO
</template>
<script>

  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebase/storage'
  import 'firebase/database'
  import {enviarEvento} from "@/functions";

  export default {
    data: () => ({
      e1: 1,
      dialogFac: false,
      phoneInputDialog: false,
      banCode: true,
      userState: null,
      paisVal: '',
      provinciaVal: '',
      cantonVal: '',
      parroquiaVal: '',
      paisLstFilter: [],
      provinciaLstFilter: [],
      parroquiaLstFilter: [],
      cantonLstFilter: [],
      provinciaLst: [],
      cantonLst: [],
      parroquiaLst: [],
      loadingSaveUser: false,
      confirmationDialogVerify: false,
      imageName: '',
      imageNameLogo: '',
      imageUrl: '',
      imageLogoUrl: '',
      imageFile: '',
      imageFileLogo: '',
      dialog: false,
      dialogLogo: false,
      currentDay: '',
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      user: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        ide: '',
        address: '',
        provincia: '',
        canton: '',
        parroquia: '',
        indexPais: '',
        indexProvincia: '',
        indexCanton: '',
        indexParroquia: '',
        pais: '',
        postalCode: '',
        information: '',
        create: '',
        update: '',
        urlImg: 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7',
        status: '',
        urlImgLogo: 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2FchatLogo.png?alt=media&token=52660b44-7a4f-4e9d-801e-79a4e01d0c16',
        billing: false,
        sendWhatsapp: '',
      },
    }),
    computed: {
      country () {
        return this.$store.state.countries.countries
      },
      paisLst () {
        return this.$store.state.paises.paises
      },
      userStateVal () {
        return this.$store.state.users.user
      },
    },
    watch: {
      userStateVal () {
        this.user.urlImg = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7'
        this.userState = this.$store.state.users.user
        if (this.userState?.selectAccount === 'personal') {
          this.banCode = true
        } else {
          this.banCode = false
        }
        this.$store.dispatch('getLocalStorageData', 'user').then(res => {
          this.user = res
          // this.paisVal = res.pais
          // if (!this.paisVal || this.paisVal === '') {
          //   this.paisVal = this.$store.state.paises.paises[0]
          // }
          this.provinciaVal = res.provincia
          this.cantonVal = res.canton
          this.parroquiaVal = res.parroquia
          if (this.user.phone === '') {
            this.phoneInputDialog = true
          }
          if (this.user.urlImg === '') {
            this.user.urlImg = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7'
          }
          if (!this.user.urlImg) {
            this.user.urlImg = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7'
          }
          if (this.user.urlImgLogo === '') {
            this.user.urlImgLogo = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2FchatLogo.png?alt=media&token=52660b44-7a4f-4e9d-801e-79a4e01d0c16'
          }
          if (!this.user.urlImgLogo) {
            this.user.urlImgLogo = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2FchatLogo.png?alt=media&token=52660b44-7a4f-4e9d-801e-79a4e01d0c16'
          }
        })
        this.$store.dispatch('getCurrentDay').then(res => {
          this.currentDay = res
        })
      },
      paisVal () {
        this.user.pais = this.paisVal !== undefined ? this.paisVal : ''
        this.paisLstFilter = this.paisLst.filter((item) => {
          return (item.id === this.paisVal)
        })
        if (this.paisLstFilter[0]) {
          this.provinciaLst = this.paisLstFilter[0].provincia
        }
      },
      provinciaVal () {
        this.user.provincia = this.provinciaVal !== undefined ? this.provinciaVal : ''
        this.provinciaLstFilter = this.provinciaLst.filter(item => {
          return (item.id === this.provinciaVal)
        })
        if (this.provinciaLstFilter[0]) {
          this.cantonLst = this.provinciaLstFilter[0].cantones
        }
      },
      cantonVal () {
        this.user.canton = this.cantonVal !== undefined ? this.cantonVal : ''
        this.cantonLstFilter = this.cantonLst.filter(item => {
          return (item.id === this.cantonVal)
        })
        if (this.cantonLstFilter[0]) {
          this.parroquiaLst = this.cantonLstFilter[0].parroquias
        }
      },
      parroquiaVal () {
        this.user.parroquia = this.parroquiaVal !== undefined ? this.parroquiaVal : ''
        this.parroquiaLstFilter = this.parroquiaLst.filter(item => {
          return (item.id === this.parroquiaVal)
        })
        if (this.parroquiaLstFilter[0]) {
          this.user.postalCode = this.parroquiaLstFilter[0].codigoPostal
        }
      },
    },
    mounted () {
      enviarEvento('user_profile', { categoria: 'interaccion', etiqueta: 'inicio' })
      this.user.urlImg = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7'
      this.userState = this.$store.state.users.user
      if (this.userState?.selectAccount === 'personal') {
        this.banCode = true
      } else {
        this.banCode = false
      }
      this.$store.dispatch('getLocalStorageData', 'user').then(res => {
        this.user = res
        // this.paisVal = res.pais
        // if (!this.paisVal || this.paisVal === '') {
        //   this.paisVal = this.$store.state.paises.paises[0]
        // }
        this.provinciaVal = res.provincia
        this.cantonVal = res.canton
        this.parroquiaVal = res.parroquia
        if (this.user.phone === '') {
          this.phoneInputDialog = true
        }
        if (this.user.urlImg === '') {
          this.user.urlImg = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7'
        }
        if (!this.user.urlImg) {
          this.user.urlImg = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7'
        }
        if (this.user.urlImgLogo === '') {
          this.user.urlImgLogo = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2FchatLogo.png?alt=media&token=52660b44-7a4f-4e9d-801e-79a4e01d0c16'
        }
        if (!this.user.urlImgLogo) {
          this.user.urlImgLogo = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2FchatLogo.png?alt=media&token=52660b44-7a4f-4e9d-801e-79a4e01d0c16'
        }
      })
      this.$store.dispatch('getCurrentDay').then(res => {
        this.currentDay = res
      })
    },
    methods: {
      nextEventProcess () {
        // this.loadingSaveData = true
        this.e1 = 2
      },
      nextEventProcessTwo () {
        // this.loadingSaveData = true
        this.e1 = 3
      },
      exitFacDialog () {
        this.dialogFac = false
      },
      changeSwitch () {
        this.updateProfile()
      },
      verifyPassword () {
        firebase.auth().sendPasswordResetEmail(this.user.email).then(() => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Correo enviado con éxito',
          }
          this.confirmationDialogVerify = false
          firebase.auth().signOut().then(() => {
            localStorage.clear()
            this.$store.dispatch('users/cleanStates')
            this.$store.dispatch('patients/cleanData')
            this.$store.dispatch('general/clearData')
            this.$store.dispatch('financial/clearData')
            this.$router.push('/')
          })
        }).catch(() => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Lo sentimos, tu correo no está registrado',
          }
        })
      },
      pickFile () {
        this.$refs.image.click()
      },
      pickFileLogo () {
        this.$refs.imageLogo.click()
      },
      updateProfile () {
        this.loadingSaveUser = true
        this.user.update = this.currentDay
        const currentUser = firebase.auth().currentUser
        this.user.uidSesion = localStorage.getItem('uidSesion')
        if (this.user.accounts) {
          if (this.user.billing) {
            this.$store.dispatch('users/getUserData', currentUser.uid).then(res => {
              if (res.valuidfac) {
                this.user.valuidfac = res.valuidfac
                firebase.database().ref(`users/${currentUser.uid}`).set(
                  this.user,
                ).then(() => {
                  this.loadingSaveUser = false
                  this.snackbar = {
                    show: true,
                    color: 'green',
                    text: 'Usuario actualizado correctamente',
                  }
                  setTimeout(() => {
                    if (this.user.billing) {
                      this.$router.push('/dashboard/pages/perfilempresa')
                    }
                  }, 3000)
                  this.$store.dispatch('setLocalStorageData', { item: 'user', obj: this.user })
                  this.$store.dispatch('getLocalStorageData', 'user')
                }, () => {
                  this.loadingSaveUser = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al actualizar el usuario',
                  }
                })
              }
            })
          } else {
            firebase.database().ref(`users/${currentUser.uid}`).set(
              this.user,
            ).then(() => {
              this.loadingSaveUser = false
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Usuario actualizado correctamente',
              }
              setTimeout(() => {
                if (this.user.billing) {
                  this.$router.push('/dashboard/pages/perfilempresa')
                }
              }, 3000)
              this.$store.dispatch('setLocalStorageData', { item: 'user', obj: this.user })
              this.$store.dispatch('getLocalStorageData', 'user')
            }, () => {
              this.loadingSaveUser = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al actualizar el usuario',
              }
            })
          }
        } else {
          this.loadingSaveUser = false
          this.loadingSaveUser = false
        }
      },
      onFilePicked (e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageName = files[0].name
          if (this.imageName.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageUrl = fr.result
            this.imageFile = files[0]
          })
        } else {
          this.imageName = ''
          this.imageFile = ''
          this.imageUrl = ''
        }
      },
      onFilePickedLogo (e) {
        const files = e.target.files
        if (files[0] !== undefined) {
          this.imageNameLogo = files[0].name
          if (this.imageNameLogo.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.imageLogoUrl = fr.result
            this.imageFileLogo = files[0]
          })
        } else {
          this.imageNameLogo = ''
          this.imageFileLogo = ''
          this.imageLogoUrl = ''
        }
      },
      upload () {
        const storageRef = firebase.storage().ref()
        const currentUser = firebase.auth().currentUser
        const mountainsRef = storageRef.child(`images/${currentUser.uid}`)
        mountainsRef.put(this.imageFile).then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            this.imageUrl = downloadURL
            this.user.urlImg = this.imageUrl
            firebase.database().ref(`users/${currentUser.uid}/urlImg`).set(
              this.imageUrl,
            ).then(() => {
              this.$store.dispatch('setLocalStorageData', { item: 'user', obj: this.user })
            })
            this.dialog = false
          })
        })
      },
      uploadLogo () {
        const storageRef = firebase.storage().ref()
        const currentUser = firebase.auth().currentUser
        const mountainsRef = storageRef.child(`images/${currentUser.uid}-logo`)
        mountainsRef.put(this.imageFileLogo).then(snapshot => {
          snapshot.ref.getDownloadURL().then(downloadURL => {
            this.imageLogoUrl = downloadURL
            this.user.urlImgLogo = this.imageLogoUrl
            firebase.database().ref(`users/${currentUser.uid}/urlImgLogo`).set(
              this.imageLogoUrl,
            ).then(() => {
              this.$store.dispatch('setLocalStorageData', { item: 'user', obj: this.user })
            })
            this.dialogLogo = false
          })
        })
      },
    },
  }
</script>
<style lang="scss">
  .v-application .subtitle-1{
    font-size: 1.4rem !important;
  }
</style>
