<template lang="pug">
  v-container.col-md-12.col-12
    v-dialog(v-if="dialogPatient" fullscreen="" v-model='dialogPatient' width='800')
      v-card
        patient(:seeData="false" @finishCreate="finishCreate" :selectedPatient="selectedPatient" :idPatient="idPatient" @exitPatient="dialogPatient = false")
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(v-model='snackbar.show' :timeout='30000' top='' right='' :color='snackbar.color')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-row
      v-col(cols="12" md="5")
        base-material-card.px-5.py-3(icon='mdi-clipboard-text' title='Preguntas Generales')
          v-container#patient-table(fluid='' tag='section')
            v-row
              //- <!--v-col(cols="1" md="1")-->
              //-   <!--v-tooltip(bottom='')-->
              //-     <!--template(v-slot:activator='{ attrs, on }')-->
              //-       <!--v-btn(v-on="on" icon='' color='secondary' @click="downLoadExcelFile")-->
              //-         <!--v-icon mdi-arrow-down-bold-box-->
              //-     <!--span Descargar Lista de Anamnesis-->
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 50px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='primary' @click="newPatient")
                      v-icon(style="font-size: 30px;") mdi-account-plus
                  span Agregar Nuevo Paciente
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 90px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='orange' @click="viewPatient")
                      v-icon(style="font-size: 30px;") mdi-eye
                  span Ver datos del paciente
              v-col(cols="12" md="12" style="padding: 0px; margin: 0px;" v-if="patientSelected == null" )
                v-autocomplete(@change="changePatient" v-model='valuePatient' :items='patientLstFilter' return-object item-text="data.data.completeData"  label='Pacientes')
                  template(v-slot:no-data='')
                    v-list-item
                      v-list-item-title
                        | Aún no hay pacientes, regístralos desde el menú
                        strong  Pacientes
              v-col(cols="12" md="12")
                v-progress-linear(indeterminate='' color='primary' v-if="progressBar")
                v-simple-table
                  thead
                    tr
                      th.primary--text
                        | Pregunta
                      th.text-center.primary--text
                        | Acciones
                  tbody
                    tr(v-for="(pregunta, index) in arrPreguntasLst" :key="index")
                      td(style="cursor: pointer;") {{pregunta.questions}}
                      td(style="cursor: pointer;").text-center
                        v-btn(icon='' color='red' @click="deleteAntecedente(pregunta.id)")
                          v-icon mdi-delete
                        v-btn(icon='' color='blue' @click="passQuestion(pregunta.questions)")
                          v-icon mdi-arrow-right
      v-col(cols="12" md="7")
        v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading
          v-row.text-start.v-card--material__heading.mb-n6.v-sheet.theme--dark.elevation-6.primary.pa-7(justify='center' no-gutters='')
            | Preguntas Frecuentes
          v-row
            v-col(cols="6" md="4").text-center
              v-tooltip(bottom='')
                template(v-slot:activator='{ attrs, on }')
                  v-btn(v-on="on" icon='' color='success' @click="addQuestion")
                    v-icon(style="font-size: 30px;") mdi-format-annotation-plus
                span Agregar Pregunta
            v-col(cols="6" md="4").text-center
              v-tooltip(bottom='')
                template(v-slot:activator='{ attrs, on }')
                  v-btn(v-on="on" icon='' color='red' @click="deleteQuestion")
                    v-icon(style="font-size: 30px;") mdi-bookmark-remove
                span Eliminar Pregunta
            v-col(cols="6" md="4").text-center
              v-tooltip(bottom='')
                template(v-slot:activator='{ attrs, on }')
                  v-btn(v-on="on" icon='' color='primary' @click="saveQuestions")
                    v-icon(style="font-size: 30px;") mdi-content-save
                span Guardar
            v-col(cols="12" md="12"  v-for="(pregunta, index) in arrPreguntasDoctor" :key="index")
              v-row
                v-col(cols="12" md="4")
                  v-text-field(:id="'question_' + index" label='Pregunta' :value="pregunta.pregunta" @keyup="setQuestion(index)")
                v-col(cols="10" md="6")
                  v-text-field(:id="'respuesta_' + index" label='Respuesta' :value="pregunta.respuesta" @keyup="setResponse(index)")
                v-col(cols="2" style="padding-top: 30px;")
                  v-tooltip(bottom='')
                    template(v-slot:activator='{ attrs, on }')
                      v-btn(v-on="on" icon='' color='green' @click="addGlobalQuestion(pregunta.pregunta)" :loading="loadingSaveDataGloba")
                        v-icon(style="font-size: 30px;") mdi-arrow-up
                    span Agregar a Generales
</template>
<script>
  export default {
    components: {
      Patient: () => import('@/views/dashboard/component/patient/PatientProfile'),
    },
    props: {
      patientSelected: null,
      idPatient: null,
    },
    data: () => ({
      loadingSaveData: false,
      loadingSaveDataGloba: false,
      selectedPatient: {},
      progressBar: false,
      patientID: '',
      valuePatient: null,
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      dialogPatient: false,
      patientLstFilter: [],
      searchTable: '',
      search: null,
    }),
    computed: {
      arrPreguntasLst () {
        return this.$store.state.questions.globalQuestion
      },
      patientLst () {
        return this.$store.state.patients.pacientes
      },
      arrPreguntasDoctor () {
        return this.$store.state.questions.questionsLst
      },
    },
    watch: {
      patientLst () {
        this.patientLstFilter = this.patientLst
      },
    },
    created () {
      this.patientLstFilter = this.$store.state.patients.pacientes
      if (this.patientSelected != null) {
        this.valuePatient = {
          data: {
            data: this.patientSelected,
          },
          id: this.idPatient,
        }
      } else {
        if (this.patientLstFilter) {
          this.valuePatient = this.patientLstFilter[0]
          if (this.valuePatient) {
            this.selectedPatient = this.valuePatient.data.data
          }
        }
      }
      if (this.valuePatient) {
        this.$store.dispatch('questions/loadQuestion', this.valuePatient.id)
        this.$store.dispatch('questions/loadGlobalQuestion', this.valuePatient.id)
      }
    },
    methods: {
      passQuestion (question) {
        this.arrPreguntasDoctor.push({
          pregunta: question,
          respuesta: '',
        })
      },
      deleteAntecedente (idQuestion) {
        this.$store.dispatch('questions/deleteGlobalQuestion', idQuestion).then(res => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Pregunta eiminada correctamente',
          }
        }, reject => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al eliminar la pregunta',
          }
        })
      },
      addGlobalQuestion (question) {
        this.loadingSaveDataGloba = true
        this.$store.dispatch('questions/addGlobalQuestion', question).then(res => {
          this.loadingSaveDataGloba = false
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Pregunta guardada correctamente',
          }
          if (this.valuePatient) {
            this.$store.dispatch('questions/loadQuestion', this.valuePatient.id)
            this.$store.dispatch('questions/loadGlobalQuestion', this.valuePatient.id)
          }
        }, reject => {
          this.loadingSaveDataGloba = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar la pregunta',
          }
          if (this.valuePatient) {
            this.$store.dispatch('questions/loadQuestion', this.valuePatient.id)
            this.$store.dispatch('questions/loadGlobalQuestion', this.valuePatient.id)
          }
        })
      },
      saveQuestions () {
        if (this.arrPreguntasDoctor.length > 0) {
          this.loadingSaveData = true
          const data = {
            patientId: this.valuePatient.id,
            question: this.arrPreguntasDoctor,
          }
          this.$store.dispatch('questions/addQuestionPatient', data).then(res => {
            this.loadingSaveData = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Preguntas guardadas correctamente',
            }
            if (this.valuePatient) {
              this.$store.dispatch('questions/loadQuestion', this.valuePatient.id)
              this.$store.dispatch('questions/loadGlobalQuestion', this.valuePatient.id)
            }
          }, reject => {
            this.loadingSaveData = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar las preguntas',
            }
          })
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'No tienes preguntas para guardar',
          }
        }
      },
      newPatient () {
        this.selectedPatient = null
        this.dialogPatient = true
      },
      viewPatient () {
        if (this.valuePatient) {
          this.idPatient = this.valuePatient.id
          this.selectedPatient = this.valuePatient.data.data
          this.dialogPatient = true
        }
      },
      setQuestion (index) {
        this.arrPreguntasDoctor[index].pregunta = document.getElementById('question_' + index).value
      },
      setResponse (index) {
        this.arrPreguntasDoctor[index].respuesta = document.getElementById('respuesta_' + index).value
      },
      deleteQuestion () {
        this.arrPreguntasDoctor.splice((this.arrPreguntasDoctor.length - 1), 1)
      },
      addQuestion () {
        this.arrPreguntasDoctor.push({
          pregunta: '',
          respuesta: '',
        })
      },
      changePatient () {
        this.selectedPatient = this.valuePatient.data.data
        this.$store.dispatch('questions/loadQuestion', this.valuePatient.id)
      },
      finishCreate () {
        this.dialogPatient = false
      },
    },
  }
</script>
<style lang='css'>
  .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar){
    font-family: 'ceraProMedium';
  }
</style>
