<template lang="pug">
  div
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-dialog(v-model='dialogPatient' fullscreen="" width='800')
      v-card
        patient(@finishCreate="finishCreate" :selectedPatient="selectedPatient" @exitPatient="dialogPatient = false")
    v-dialog(v-model='dialogEvent' width='500' v-if="selectEvent.name" persistent)
      v-card
        v-card-title.headline.primary.lighten-2(primary-title='' style="color: white; font-weight: bold;")
          | CITA ODONTOLÓGICA
        v-card-text
          v-row(style="padding: 0px; margin: 0px;")
            v-col(cols="12" md="4" style="padding: 0px; margin: 0px; text-align: center")
              img(style="width: 150px; height: 160px;" :src="selectEvent.img" alt='Imagen de seleccionar evento')
            v-col(cols="12" md="8" style='padding-top:0px; padding-bottom:0px;')
              v-text-field.mt-3(v-model='selectEvent.name' label='Nombre' disabled)
              v-row
                v-col(cols='12' md='6')
                  v-text-field.mt-3(v-model='selectEvent.celPhone' label='Celular' disabled)
                v-col(cols='12' md='6' style='display:flex; align-items:center;')
                  v-btn(style="width: 100%;" color='primary' @click="sendWhatsAppMess(selectEvent)" :disabled='disableRemember')
                    | RECORDAR
                    br
                    | CITA
            v-col(v-if='userRol === "clinica"' cols="12" md="12" style='padding:0px;')
              v-text-field.mt-3(v-model='selectEvent.doctor' label='Doctor' disabled)
            v-col(cols="12" md="12" style='padding:0px;')
              v-textarea(solo='' name='input-7-4' label='Motivo Consulta' height="70px" v-model="selectEvent.obs" disabled)
            v-col(cols="12" md="12" style='padding:0px;')
              v-textarea(solo='' name='input-7-4' label='Enfermedad - Problema Actual' height="70px" v-model="selectEvent.pro" disabled)
            v-col(cols="12" md="6" style='padding:0px;')
              v-menu(v-model='startMenu1' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field(v-model='selectEvent.start' label='Inicio' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                v-date-picker(v-model='selectEvent.start' no-title @input='startMenu1 = false' style='margin:0px;')
            v-col(cols="12" md="6" style='padding:0px;')
              v-menu(v-model='endMenu' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on, attrs }')
                  v-text-field(v-model='selectEvent.end' label='Fin' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                v-date-picker(v-model='selectEvent.end' no-title @input='endMenu = false' style='margin:0px;')
        v-divider
        v-card-actions
          v-spacer
          v-btn(color='primary' @click='closeEvent()')
            | Salir
          v-btn(color='red' text='' @click='cancelAppoiment')
            | Cancelar Cita
    v-row(justify='center')
      v-col(cols='12' md='12')
        base-material-card
          template(v-slot:heading='')
            .display-2.font-weight-light
              | Agenda
          v-row
            v-dialog(v-model='dialogAppointment' width='500' persistent)
              v-card
                v-card-title.headline.primary.lighten-2(primary-title='' style="color: white; font-weight: bold;")
                  | AGENDAR CITA
                v-card-text
                  v-menu(v-model='nowMenu' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                    template(v-slot:activator='{ on, attrs }')
                      v-text-field(v-model='now' label='seleccionar Fecha' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                    v-date-picker(v-model='now' no-title @input='nowMenu = false' style='margin:0px;')
                  v-row(style="padding: 0px; margin: 0px;")
                    v-col(cols="12" md="6" style="padding: 0px; margin: 0px;")
                      v-text-field(v-model='time' label='Hora Desde' prepend-icon='mdi-timetable' type="time")
                      //- <!--v-menu(ref='menu' v-model='menuTime' :close-on-content-click='false' :nudge-right='40' :return-value.sync='time' transition='scale-transition' offset-y='' max-width='290px' min-width='290px')-->
                      //-   <!--template(v-slot:activator='{ on }')-->
                      //-     <!--v-text-field(v-model='time' label='Hora Desde' prepend-icon='mdi-timetable' readonly='' v-on='on')-->
                      //-   <!--v-time-picker(:ampm-in-title="true" v-if='menuTime' v-model='time' full-width='' @click:minute='$refs.menu.save(time)')-->
                    v-col(cols="12" md="6" style="padding: 0px; margin: 0px;")
                      v-text-field(v-model='time2' label='Hora Hasta' prepend-icon='mdi-timetable' type="time")
                      //- <!--v-menu(ref='menu2' v-model='menuTime2' :close-on-content-click='false' :nudge-right='40' :return-value.sync='time2' transition='scale-transition' offset-y='' max-width='290px' min-width='290px')-->
                      //-   <!--template(v-slot:activator='{ on }')-->
                      //-     <!--v-text-field(v-model='time2' label='Hora Hasta' prepend-icon='mdi-timetable' readonly='' v-on='on')-->
                      //-   <!--v-time-picker(:ampm-in-title="true" v-if='menuTime2' v-model='time2' full-width='' @click:minute='$refs.menu2.save(time2)')-->
                  v-row(style="padding: 0px; margin: 0px;")
                    v-col(cols="11" md="11" style="padding: 0px; margin: 0px;")
                      v-autocomplete(v-model='valuePatient' :items='itemsPatient' return-object item-text="data.data.completeData"  label='Paciente')
                        template(v-slot:no-data='')
                          v-list-item
                            v-list-item-title
                              | Aún no hay pacientes, regístralos desde el menú
                              strong  Pacientes
                    v-col(cols="1" md="1" style="padding: 0px; margin: 0px;")
                      v-tooltip(bottom='')
                        template(v-slot:activator='{ on, attrs }')
                          v-btn(icon='' @click="createPatient" color='primary' dark='' v-bind='attrs' v-on='on')
                            v-icon(large) mdi-account-plus
                        span Crear Paciente
                    v-col(v-if='userRol === "clinica"' cols="12" style="padding: 0px; margin: 0px;")
                      v-autocomplete(v-model='valueDoctor' :items='itemsDr' return-object item-text="data.data.completeData"  label='Doctor')
                        template(v-slot:no-data='')
                          v-list-item
                            v-list-item-title
                              | Aún no hay doctores, regístralos desde el menú
                              strong  Doctores
                  v-textarea(solo='' name='input-7-4' label='Motivo Consulta' height="60px" v-model="observaciones")
                  v-textarea(solo='' name='input-7-4' label='Enfermedad - Problema Actual' height="60px" v-model="problema")
                  v-row
                    v-col(cols="6")
                      v-btn.mr-0(color='primary' @click='addEvent' style="width: 100%;" :loading="loadingAgendar")
                        | AGENDAR CITA
                    v-col(cols="6")
                      v-btn.mr-0(color='red' @click='dialogAppointment = false' style="width: 100%;")
                        | SALIR
            v-col.pl-4(cols='12' md='12')
              v-row
                v-col(cols="8" md="4" style="text-align: center; padding: 0px 0px 0px 15px;")
                  v-autocomplete(@change="changePatientFilter" v-model='valuePatientFilter' :items='itemsPatient' return-object item-text="data.data.completeData"  label='Filtrar por Paciente')
                    template(v-slot:no-data='')
                      v-list-item
                        v-list-item-title
                          | Aún no hay pacientes, regístralos desde el menú
                          strong  Pacientes
                v-col(cols="4" md="3" style="padding-top: 20px; padding-bottom:0;")
                  v-checkbox(@change="changeAll" v-model='allAppoitment' :label='`Ver todas las citas`' style='margin:0px;')
                v-col(cols="3" md="1" style="text-align: center;")
                  v-btn(fab='' small='' left='' color='primary' @click='$refs.calendar.prev()')
                    v-icon(dark='') mdi-chevron-left
                v-col(cols="6" md="3" style="padding-top: 5px;")
                  v-menu(v-model='startMenu' :close-on-content-click='false' :nudge-right='40' transition='scale-transition' offset-y='' min-width='290px')
                    template(v-slot:activator='{ on, attrs }')
                      v-text-field(v-model='start' label='Fecha de la Cita' prepend-icon='mdi-calendar' readonly='' v-bind='attrs' v-on='on')
                    v-date-picker(v-model='start' no-title @input='startMenu = false' style='margin:0px;')
                v-col(cols="3" md="1" style="text-align: center; padding:12px 0px;")
                  v-btn(fab='' small='' right='' color='primary' @click='$refs.calendar.next()')
                    v-icon(dark='') mdi-chevron-right
                //- <!--v-col(cols="12" md="2")-->
                //-   <!--v-btn.mr-0(color='secondary' @click='currentDate')-->
                //-     <!--| Fecha Actual-->
                //- <!--v-col(cols="12" md="2")-->
                //-   <!--v-btn.mr-0(@click='currentDate')-->
                //-     <!--| Historial-->
              v-row
                v-col(cols='6' style='padding-top:0;')
                  v-select(v-model='type' :items='typeOptions' label='Ver' hide-details='' outlined='' dense='')
                v-col(cols='6' style='padding-top:0;')
                  v-btn.mr-0(color="primary" @click='openAppointment' style="width: 100%;")
                    | AGENDAR CITA
              v-sheet(height='600')
                v-calendar(
                  @click:more="moreEvent($event)"
                  @click:day="dayFunction($event)"
                  @click:event="eventFunction($event)"
                  locale="es"
                  ref='calendar'
                  v-model='start'
                  :type='type'
                  :start='start'
                  :end='end'
                  :min-weeks='minWeeks'
                  :max-days='maxDays'
                  :now='now'
                  :dark='true'
                  :weekdays='weekdays'
                  :first-interval='intervals.first'
                  :interval-minutes='intervals.minutes'
                  :interval-count='intervals.count'
                  :interval-height='intervals.height'
                  :interval-style='intervalStyle'
                  :show-interval-label='showIntervalLabel'
                  :short-intervals='shortIntervals'
                  :short-months='shortMonths'
                  :short-weekdays='shortWeekdays'
                  :color='color'
                  :events='events'
                  :event-overlap-mode='mode'
                  :event-overlap-threshold='45'
                  :event-color='getEventColor'
                  :event-more="true"
                )
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebase/database'
  import {enviarEvento} from "@/functions";
  const weekdaysDefault = [0, 1, 2, 3, 4, 5, 6]

  const intervalsDefault = {
    first: 0,
    minutes: 60,
    count: 24,
    height: 48,
  }

  const stylings = {
    default (interval) {
      return undefined
    },
    workday (interval) {
      const inactive = interval.weekday === 0 ||
        interval.weekday === 6 ||
        interval.hour < 9 ||
        interval.hour >= 17
      const startOfHour = interval.minute === 0
      const dark = this.dark
      const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

      return {
        backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
        borderTop: startOfHour ? undefined : '1px dashed ' + mid,
      }
    },
    past (interval) {
      return {
        backgroundColor: interval.past ? (this.dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
      }
    },
  }
  export default {
    components: {
      Patient: () => import('@/views/dashboard/component/patient/PatientProfile'),
    },
    data: () => ({
      userRol: '',
      disableRemember: false,
      selectedPatient: {
        hcNumber: '',
        pais: '',
        canton: '',
        provincia: '',
        parroquia: '',
        postalCode: '',
        paisRes: '',
        cantonRes: '',
        provinciaRes: '',
        parroquiaRes: '',
        postalCodeRes: '',
        names: '',
        lastNames: '',
        tipoId: 'Cédula',
        id: '',
        email: '',
        sex: '',
        estadoCivil: '',
        phone1: '',
        phone2: '',
        phone3: '',
        active: true,
        notas: '',
        city: '',
        country: '',
        place: '',
        nationality: '',
        bornDate: '',
        years: '0',
        months: '0',
        days: '0',
        cityHome: '',
        countryHome: '',
        parish: '',
        street: '',
        numberStreet: '',
        street2: '',
        neighborhood: '',
        referenceHome: '',
        ethnicity: '',
        ethnicityNat: '',
        town: '',
        educationLevel: '',
        stateEducationLevel: '',
        companyWorkType: '',
        ocupation: '',
        sureIess: '',
        sureSecundary: '',
        contactName: '',
        contactKinship: '',
        contactPhone: '',
        contactAddress: '',
        imagePatientUrl: '',
        idimg: '',
        addEdit: false,
        referedBy: '',
        fatherName: '',
        motherName: '',
        billName: '',
      },
      loadingAgendar: false,
      allAppoitment: true,
      patientId: null,
      banEventSelect: false,
      observaciones: '',
      problema: '',
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      dialogAppointment: false,
      valuePatient: '',
      valueDoctor: '',
      valuePatientFilter: '',
      dialogPatient: false,
      selectEvent: {},
      dialogEvent: false,
      time: null,
      time2: null,
      menuTime: false,
      menuTime2: false,
      patient: {},
      date: new Date().toString().substr(4, 12),
      menu: false,
      dark: false,
      startMenu: false,
      start: '2019-01-27',
      endMenu: false,
      end: '2019-01-27',
      nowMenu: false,
      startMenu1: false,
      minWeeks: 1,
      now: null,
      events: [],
      eventsComplete: [],
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      names: ['Meeting', 'Holiday', 'PTO', 'Travel', 'Event', 'Birthday', 'Conference', 'Party'],
      type: 'month',
      typeOptions: [
        { text: 'Todo el día', value: 'day' },
        { text: 'Toda la semana', value: 'week' },
        { text: 'Todo el mes', value: 'month' },
      ],
      patients: [
        { name: 'Juan Molina', id: '1' },
        { name: 'Richi', id: '2' },
        { name: 'Cheo', id: '3' },
      ],
      mode: 'stack',
      modeOptions: [
        { text: 'Stack', value: 'stack' },
        { text: 'Column', value: 'column' },
      ],
      weekdays: weekdaysDefault,
      weekdaysOptions: [
        { text: 'Sunday - Saturday', value: weekdaysDefault },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
      ],
      intervals: intervalsDefault,
      intervalsOptions: [
        { text: 'Normal', value: intervalsDefault },
        { text: 'Oficina', value: { first: 16, minutes: 30, count: 20, height: 48 } },
      ],
      maxDays: 7,
      maxDaysOptions: [
        { text: '7 days', value: 7 },
        { text: '5 days', value: 5 },
        { text: '4 days', value: 4 },
        { text: '3 days', value: 3 },
      ],
      styleInterval: 'default',
      styleIntervalOptions: [
        { text: 'Default', value: 'default' },
        { text: 'Workday', value: 'workday' },
        { text: 'Past', value: 'past' },
      ],
      color: 'primary',
      colorOptions: [
        { text: 'Primary', value: 'primary' },
        { text: 'Secondary', value: 'secondary' },
        { text: 'Accent', value: 'accent' },
        { text: 'Red', value: 'red' },
        { text: 'Pink', value: 'pink' },
        { text: 'Purple', value: 'purple' },
        { text: 'Deep Purple', value: 'deep-purple' },
        { text: 'Indigo', value: 'indigo' },
        { text: 'Blue', value: 'blue' },
        { text: 'Light Blue', value: 'light-blue' },
        { text: 'Cyan', value: 'cyan' },
        { text: 'Teal', value: 'teal' },
        { text: 'Green', value: 'green' },
        { text: 'Light Green', value: 'light-green' },
        { text: 'Lime', value: 'lime' },
        { text: 'Yellow', value: 'yellow' },
        { text: 'Amber', value: 'amber' },
        { text: 'Orange', value: 'orange' },
        { text: 'Deep Orange', value: 'deep-orange' },
        { text: 'Brown', value: 'brown' },
        { text: 'Blue Gray', value: 'blue-gray' },
        { text: 'Gray', value: 'gray' },
        { text: 'Black', value: 'black' },
      ],
      shortIntervals: false,
      shortMonths: false,
      shortWeekdays: false,
    }),
    computed: {
      intervalStyle () {
        return stylings[this.styleInterval].bind(this)
      },
      hasIntervals () {
        return this.type in {
          week: 1, day: 1, '4day': 1, 'custom-daily': 1,
        }
      },
      hasEnd () {
        return this.type in {
          'custom-weekly': 1, 'custom-daily': 1,
        }
      },
      itemsPatient () {
        return this.$store.state.patients.pacientes
      },
      itemsDr () {
        return this.$store.state.doctors.doctores
      },
      lstAgenda () {
        return this.$store.state.agenda.lstAgenda
      },
    },
    watch: {
      lstAgenda () {
        this.getEvents()
      },
    },
    created () {
      this.detectUserRol()
      // se identifica el tipo de dispositivo
      if (this.detectMob() === true) {
        this.type = 'day'
      } else {
        this.type = 'month'
      }
      this.patientId = this.$route.params.id
      this.getEvents()
      if (this.patientId) {
        for (let i = 0; i < this.itemsPatient.length; i++) {
          if (this.itemsPatient[i].id === this.patientId) {
            this.valuePatient = this.itemsPatient[i]
          }
        }
      }
      this.$store.dispatch('getCurrentDay').then(res => {
        this.start = res
        this.now = res
      })
      this.$store.dispatch('getCurrentTime').then(res => {
        this.time = res
      })
      this.$store.dispatch('getCurrentTimeInterval', 1).then(res => {
        this.time2 = res
      })
    },
    methods: {
      getRandomColor () {
        var letters = '0123456789ABCDEF'
        var newPathColor = '#'
        for (var i = 0; i < 6; i++) {
          newPathColor += letters[Math.floor(Math.random() * 16)]
        }
        return newPathColor
      },
      detectUserRol () {
        this.$store.dispatch('agenda/loadUserRol').then(userType => {
          this.userRol = userType
        })
      },
      detectMob () {
        const toMatch = [
          /Android/i,
          /webOS/i,
          /iPhone/i,
          /iPad/i,
          /iPod/i,
          /BlackBerry/i,
          /Windows Phone/i,
        ]

        return toMatch.some((toMatchItem) => {
          return navigator.userAgent.match(toMatchItem)
        })
      },
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      getLinkWhastapp (number, message) {
        var url = 'https://api.whatsapp.com/send?phone=' +
          number +
          '&text=' +
          encodeURIComponent(message)
        return url
      },
      sendWhatsAppMess (selectEvent) {
        const user = this.$store.state.users.user
        // https://api.whatsapp.com/send?phone=+{{ *YOURNUMBER* }}&text=%20{{ *YOUR MESSAGE* }}
        if (selectEvent.celPhone) {
          if (selectEvent.celPhone.length === 10) {
            var patientNumber = selectEvent.celPhone.replace(selectEvent.celPhone[0], '+593')
            var messToClient = 'Saludos estimado/a ' + selectEvent.name + ' le recordamos gentilmente que se aproxima su cita dental en la siguiente fecha ' + selectEvent.start.substr(0, 10) + ' a la hora: ' + selectEvent.start.substr(11, 5) + '.' + '\n' + 'Agradezco la confirmación.' + '\n' + '¡Buen día!' + '\n' + 'Dr(a). ' + user.firstName + ' ' + user.lastName
            this.openLink(this.getLinkWhastapp(patientNumber, messToClient))
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'El número de celular del paciente debe tener 10 dígitos, para editarlo diríjase a PACIENTES y seleccione editar en el paciente correspondiente, edite el número de celular y vuelva a crear la cita',
            }
          }
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'La cita no tiene un número de celular registrado, por favor cancele la cita y agréguela nuevamente para validar el celular del paciente.',
          }
        }
        // .padStart(2, '0')
        // .substr(0, 8)
        // new Date().toString().substr(4, 12),
        // if (this. === this.date) {
        //   this.disableRemember = true
        // }
      },
      clearData () {
        this.time = null
        this.time2 = null
        this.valuePatient = ''
        this.valueDoctor = ''
        this.observaciones = ''
        this.problema = ''
      },
      openAppointment () {
        this.dialogAppointment = true
        this.clearData()
      },
      changeAll () {
        if (this.allAppoitment) {
          this.events = this.eventsComplete
        }
      },
      changePatientFilter () {
        this.events = this.eventsComplete.filter(item => {
          return item.idPatient === this.valuePatientFilter.id
        })
        this.allAppoitment = false
      },
      closeEvent () {
        this.banEventSelect = false
        this.dialogEvent = false
      },
      dayFunction (event) {
        if (!this.banEventSelect) {
          this.now = event.date
          this.dialogAppointment = true
          this.clearData()
        }
      },
      createPatient () {
        this.selectedPatient = {
          hcNumber: '',
          pais: '',
          canton: '',
          provincia: '',
          parroquia: '',
          postalCode: '',
          paisRes: '',
          cantonRes: '',
          provinciaRes: '',
          parroquiaRes: '',
          postalCodeRes: '',
          names: '',
          lastNames: '',
          tipoId: 'Cédula',
          id: '',
          email: '',
          sex: '',
          estadoCivil: '',
          phone1: '',
          phone2: '',
          phone3: '',
          active: true,
          notas: '',
          city: '',
          country: '',
          place: '',
          nationality: '',
          bornDate: '',
          years: '0',
          months: '0',
          days: '0',
          cityHome: '',
          countryHome: '',
          parish: '',
          street: '',
          numberStreet: '',
          street2: '',
          neighborhood: '',
          referenceHome: '',
          ethnicity: '',
          ethnicityNat: '',
          town: '',
          educationLevel: '',
          stateEducationLevel: '',
          companyWorkType: '',
          ocupation: '',
          sureIess: '',
          sureSecundary: '',
          contactName: '',
          contactKinship: '',
          contactPhone: '',
          contactAddress: '',
          imagePatientUrl: '',
          idimg: '',
          addEdit: false,
          referedBy: '',
          fatherName: '',
          motherName: '',
          billName: '',
        }
        this.dialogPatient = true
      },
      moreEvent (event) {
        this.start = event.date
        this.type = 'day'
      },
      cancelAppoiment () {
        this.banEventSelect = false
        let cont = 0
        let ban = true
        while (ban && cont < this.events.length) {
          if (this.events[cont] === this.selectEvent) {
            this.events.splice(cont, 1)
            ban = false
          }
          cont++
        }
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/agenda_citas/${currentUser.uid}/${this.selectEvent.idPatient}/${this.selectEvent.id}/`).remove()
        this.dialogEvent = false
      },
      eventFunction (event) {
        this.banEventSelect = true
        this.selectEvent = event.event
        if (!this.selectEvent.img) {
          this.selectEvent.img = 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/images%2Fuser.jpg?alt=media&token=b0ccde55-1ee5-4969-a983-24375e04e3f7'
        }
        this.dialogEvent = true
      },
      currentDate () {
        this.$store.dispatch('getCurrentDay').then(res => {
          this.start = res
          this.now = res
        })
      },
      viewDay ({ date }) {
        this.start = date
        this.type = 'day'
      },
      getEventColor (event) {
        return event.color
      },
      showIntervalLabel (interval) {
        return interval.minute === 0
      },
      async addEvent () {
        if (this.valuePatient === '') {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes selecionar el paciente para continuar',
          }
          return
        }
        if (this.userRol === 'clinica') {
          if (this.valueDoctor === '') {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Debes selecionar el doctor para continuar',
            }
            return
          }
        }
        const events = this.eventsComplete
        // const allDay = this.rnd(0, 3) === 0
        if (!this.time) {
          // this.time = '00:00'
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes selecionar la hora DESDE para continuar',
          }
          return
        }
        if (!this.time2) {
          // this.time2 = '00:00'
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes selecionar la hora HASTA para continuar',
          }
          return
        }
        this.loadingAgendar = true
        // setTimeout(() => {
        let data = {}
        if (this.userRol === 'clinica') {
          // se evalua si el dr ya tiene asignado un color
          await this.$store.dispatch('agenda/loadDrColor', this.valueDoctor.uid).then(async drColorLoaded => {
            let drColor = ''
            if (drColorLoaded.length === 0) {
              // si no tiene asignado un color, se le asigna un color random
              const newRndColor = this.getRandomColor()
              await this.$store.dispatch('agenda/saveDrColor', [this.valueDoctor.uid, newRndColor])
              drColor = newRndColor
            } else {
              // si es que si tiene asignado un color, se carga el color para crear la cita con ese color
              drColor = drColorLoaded
            }
            data = {
              name: this.valuePatient.data.data.names.toUpperCase() + ' ' + this.valuePatient.data.data.lastNames.toUpperCase() + ' - ' + 'DR. ' + this.valueDoctor.data.data.names.toUpperCase() + ' ' + this.valueDoctor.data.data.lastNames.toUpperCase(),
              start: this.now + ' ' + this.time,
              end: this.now + ' ' + this.time2,
              color: drColor,
              doctor: this.valueDoctor.data.data.names + ' ' + this.valueDoctor.data.data.lastNames,
              phone: this.valuePatient.data.data.phone1,
              celPhone: this.valuePatient.data.data.phone2,
              obs: this.observaciones,
              pro: this.problema,
              img: this.valuePatient.data.data.imagePatientUrl,
              idPatient: this.valuePatient.id,
            }
          })
        } else {
          data = {
            name: this.valuePatient.data.data.names.toUpperCase() + ' ' + this.valuePatient.data.data.lastNames.toUpperCase(),
            start: this.now + ' ' + this.time,
            end: this.now + ' ' + this.time2,
            color: 'primary',
            phone: this.valuePatient.data.data.phone1,
            celPhone: this.valuePatient.data.data.phone2,
            obs: this.observaciones,
            pro: this.problema,
            img: this.valuePatient.data.data.imagePatientUrl,
            idPatient: this.valuePatient.id,
          }
        }
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/agenda_citas/${currentUser.uid}/${this.valuePatient.id}`).push(
          {
            data: data,
          }).then((res) => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'La cita se guardó correctamente',
          }
          this.dialogAppointment = false
          this.loadingAgendar = false
          if (this.userRol === 'clinica') {
            // se evalua si el dr ya tiene asignado un color
            this.$store.dispatch('agenda/loadDrColor', this.valueDoctor.uid).then(drColorLoaded => {
              let drColor = ''
              if (drColorLoaded.length === 0) {
                // si no tiene asignado un color, se le asigna un color random
                const newRndColor = this.getRandomColor()
                this.$store.dispatch('agenda/saveDrColor', [this.valueDoctor.uid, newRndColor])
                drColor = newRndColor
              } else {
                // si es que si tiene asignado un color, se carga el color para crear la cita con ese color
                drColor = drColorLoaded
              }
              events.push({
                name: this.valuePatient.data.data.names.toUpperCase() + ' ' + this.valuePatient.data.data.lastNames.toUpperCase() + ' - ' + 'DR. ' + this.valueDoctor.data.data.names.toUpperCase() + ' ' + this.valueDoctor.data.data.lastNames.toUpperCase(),
                start: this.now + ' ' + this.time,
                end: this.now + ' ' + this.time2,
                color: drColor,
                doctor: this.valueDoctor.data.data.names + ' ' + this.valueDoctor.data.data.lastNames,
                phone: this.valuePatient.data.data.phone1,
                celPhone: this.valuePatient.data.data.phone2,
                obs: this.observaciones,
                pro: this.problema,
                id: res.path.pieces_[3],
                img: this.valuePatient.data.data.imagePatientUrl,
                idPatient: this.valuePatient.id,
              })
            })
          } else {
            events.push({
              name: this.valuePatient.data.data.names.toUpperCase() + ' ' + this.valuePatient.data.data.lastNames.toUpperCase(),
              start: this.now + ' ' + this.time,
              end: this.now + ' ' + this.time2,
              color: 'primary',
              phone: this.valuePatient.data.data.phone1,
              celPhone: this.valuePatient.data.data.phone2,
              obs: this.observaciones,
              pro: this.problema,
              id: res.path.pieces_[3],
              img: this.valuePatient.data.data.imagePatientUrl,
              idPatient: this.valuePatient.id,
            })
          }

          this.eventsComplete = events
          this.events = this.eventsComplete
          this.allAppoitment = true
        }).catch(() => {
          this.loadingAgendar = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar la cita',
          }
        })
        this.eventsComplete = events
        // }, 200)
      },
      getEvents () {
        this.events = []
        this.eventsComplete = []
        const arr = this.$store.state.agenda.lstAgenda
        for (let i = 0; i < arr.length; i++) {
          for (let j = 0; j < arr[i].data.length; j++) {
            if (arr[i].data[j].data.data.start.toString() !== 'NaN-NaN-NaN NaN:NaN' && arr[i].data[j].data.data.end.toString() !== 'NaN-NaN-NaN NaN:NaN') {
              arr[i].data[j].data.data.id = arr[i].data[j].id
              this.events.push(arr[i].data[j].data.data)
            }
          }
        }
        this.eventsComplete = this.events
        if (this.patientId) {
          for (let i = 0; i < this.itemsPatient.length; i++) {
            if (this.itemsPatient[i].id === this.patientId) {
              this.valuePatientFilter = this.itemsPatient[i]
              this.changePatientFilter()
            }
          }
        }
      },
      rnd (a, b) {
        return Math.floor((b - a + 1) * Math.random()) + a
      },
      finishCreate () {
        this.dialogPatient = false
      },
    },
    mounted() {
      enviarEvento('diary', { categoria: 'interaccion', etiqueta: 'inicio' })
    }
  }
</script>

<style>
  .v-calendar-daily__pane {
    width: 100%;
    overflow-y: hidden;
    -webkit-box-flex: 0;
    -ms-flex: none;
    flex: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    padding-top: 20px;
  }
</style>

<style scoped>
  .controls {
    position: relative;
  }
</style>
<style lang="css">
  .v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
    min-width: 60px !important;
  }
</style>
