import Vue from 'vue'
import Router from 'vue-router'
// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/auth'
import store from './store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      component: () => import('@/views/landingPages/LandingPage'),
    },
    // pagina landgin page comercial solo habilitar cuando se necesite una landing dentro del dominio de dentalprime
    // {
    //   path: '/commercial',
    //   name: 'landingComercial',
    //   component: () => import('@/views/landingPages/LandingComercial'),
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login/Login'),
    },
    {
      path: '/suscription',
      name: 'suscription',
      meta: { requiresAuth: true },
      component: () => import('@/views/suscription/suscription'),
    },
    {
      path: '/dashboard',
      meta: { requiresAuth: true },
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '/dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Odontograma',
          path: '/dashboard/pages/odontogram/:id?',
          component: () => import('@/views/dashboard/pages/Citas.vue'),
          props: true,
        },
        {
          name: 'Agenda',
          path: '/dashboard/pages/agenda/:id?',
          component: () => import('@/views/dashboard/pages/Agenda.vue'),
        },
        {
          name: 'Perfil de Usuario',
          path: '/dashboard/pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Configuracion de Precios',
          path: '/dashboard/pages/configPrice',
          component: () => import('@/views/dashboard/pages/ConfigPrecios'),
        },
        {
          name: 'Cuentas',
          path: '/dashboard/pages/cuentas',
          component: () => import('@/views/dashboard/pages/Cuentas'),
          props: true,
        },
        {
          name: 'Proformas',
          path: '/dashboard/pages/Proformas',
          component: () => import('@/views/dashboard/pages/Proformas'),
        },
        {
          name: 'Tutorial',
          path: '/dashboard/pages/tutorial',
          component: () => import('@/views/dashboard/pages/Tutorial'),
        },
        {
          name: 'AdminChat',
          path: '/dashboard/pages/adminChat',
          component: () => import('@/views/dashboard/pages/AdminChat'),
        },
        {
          name: 'Lista de Pacientes',
          path: '/dashboard/pages/patienttable',
          component: () => import('@/views/dashboard/pages/PatientTable'),
        },
        {
          name: 'Lista de Doctores',
          path: '/dashboard/pages/doctors',
          component: () => import('@/views/dashboard/pages/UserTable'),
        },
        {
          name: 'Anamnesis',
          path: '/dashboard/pages/antecedentes',
          component: () => import('@/views/dashboard/pages/Antecedentes'),
        },
        {
          name: 'Perfil de Empresa',
          path: '/dashboard/pages/perfilempresa',
          component: () => import('@/views/dashboard/pages/BusinessProfile'),
        },
        {
          name: 'Certificación',
          path: '/dashboard/pages/certification',
          component: () => import('@/views/dashboard/pages/ElectronicCertification'),
        },
        {
          name: 'Facturación',
          path: '/dashboard/pages/facturas',
          component: () => import('@/views/dashboard/pages/Billing'),
        },
        {
          name: 'Productos',
          path: '/dashboard/pages/productos',
          component: () => import('@/views/dashboard/pages/InventoryData'),
        },
        {
          name: 'Clientes',
          path: '/dashboard/pages/clientes',
          component: () => import('@/views/dashboard/pages/Clients'),
        },
        {
          name: 'Proveedores',
          path: '/dashboard/pages/proveedores',
          component: () => import('@/views/dashboard/pages/ProveedorData'),
        },
        {
          name: 'Estadístico',
          path: '/dashboard/pages/estadistico',
          component: () => import('@/views/dashboard/pages/IncomeExpenses'),
        },
        {
          name: 'IVA y Retenciones',
          path: '/pages/ivaretenciones',
          component: () => import('@/views/dashboard/pages/IvaRetenciones'),
        },
        {
          name: 'Signos Vitales',
          path: '/dashboard/pages/signosvitales',
          component: () => import('@/views/dashboard/pages/SignosVitales'),
        },
        {
          name: 'Examen Fisico',
          path: '/dashboard/pages/examenfisico',
          component: () => import('@/views/dashboard/pages/ExamenFisico'),
        },
        {
          name: 'Preguntas Frecuentes',
          path: '/dashboard/pages/preguntasfrecuentes',
          component: () => import('@/views/dashboard/pages/PreguntasFrecuentes'),
        },
        {
          name: 'Checklist',
          path: '/dashboard/pages/checklist',
          component: () => import('@/views/dashboard/pages/CheckList'),
        },
        // Upgrade
        {
          name: 'Dudas y Sugerencias',
          path: '/dashboard/upgrade',
          component: () => import('@/views/dashboard/Upgrade'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (to.name.toUpperCase().includes('ADMINCHAT')) {
    if (store.state.users.user.rol) {
      if (store.state.users.user.rol.toUpperCase() === 'ADMIN') {
        next()
      } else {
        next('/dashboard/pages/odontogram')
      }
    } else {
      next('/dashboard/pages/odontogram')
    }
  } else if (to.name.toUpperCase().includes('DOCTORES')) {
    if (store.state.users.user) {
      if (store.state.users.user.rol) {
        if (store.state.users.user.rol.toUpperCase() === 'CLINICA') {
          next()
        } else {
          next('/dashboard/pages/odontogram')
        }
      } else {
        next('/dashboard/pages/odontogram')
      }
    } else {
      next('/dashboard/pages/odontogram')
    }
  } else if (requiresAuth && currentUser) {
    next()
  } else if (to.name === 'login' && currentUser) {
    next('/dashboard/pages/odontogram')
  } else if (to.name === 'landing' && currentUser) {
    next('/dashboard/pages/odontogram')
  } else {
    next()
  }
})

export default router
