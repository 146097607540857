// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const agenda = {
  namespaced: true,
  state: {
    lstAgenda: [],
  },
  mutations: {
    SET_LST_AGENDA: (state, data) => {
      state.lstAgenda = data
    },
  },
  actions: {
    loadDataAgenda ({ commit, rootState }, currentUserId) {
      return new Promise((resolve, reject) => {
        let path = ''
        let banGet = true
        if (rootState.users.user.accounts.length === 1) {
          if (rootState.users.user.accounts[0].code === '002') {
            path = `${rootState.users.user.selectAccount}/agenda_citas/`
            banGet = false
          } else {
            path = `${rootState.users.user.selectAccount}/agenda_citas/${currentUserId}/`
            banGet = true
          }
        } else {
          path = `${rootState.users.user.selectAccount}/agenda_citas/${currentUserId}/`
          banGet = true
        }
        if (banGet) {
          firebase.database().ref(path).on('value', (snapshot) => {
            let arr = []
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                arr = Object.keys(res).map(cou => {
                  return { id: cou, data: Object.keys(res[cou]).map(item => { return { id: item, data: res[cou][item] } }) }
                })
                commit('SET_LST_AGENDA', arr)
              } else {
                commit('SET_LST_AGENDA', [])
              }
            } else {
              commit('SET_LST_AGENDA', [])
            }
          })
        } else {
          firebase.database().ref(path).on('value', (snapshot) => {
            let arrInit = []
            let arr = []
            const arrEnd = []
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                arrInit = Object.keys(res).map(cou => {
                  return res[cou]
                })
                for (let i = 0; i < arrInit.length; i++) {
                  arr = Object.keys(arrInit[i]).map(cou => {
                    return {
                      id: cou,
                      data: Object.keys(arrInit[i][cou]).map(item => {
                        return {
                          id: item,
                          data: arrInit[i][cou][item],
                        }
                      }),
                    }
                  })
                  for (let j = 0; j < arr.length; j++) {
                    arrEnd.push(arr[j])
                  }
                }
                commit('SET_LST_AGENDA', arrEnd)
              } else {
                commit('SET_LST_AGENDA', [])
              }
            } else {
              commit('SET_LST_AGENDA', [])
            }
          })
        }
      })
    },
    loadUserRol ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`/users/${rootState.users.user.uid}/rol`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              resolve(res)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        })
      })
    },
    loadDrColor ({ commit, rootState }, doctorAppId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`/clinica/${rootState.users.user.uid}/doctors/${doctorAppId}/data/agenda_color`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              resolve(res)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        })
      })
    },
    saveDrColor ({ state, rootState }, [doctorAppId, colorSelected]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`/clinica/${rootState.users.user.uid}/doctors/${doctorAppId}/data/agenda_color`).set(colorSelected).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  },
}
