// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const patients = {
  namespaced: true,
  state: {
    pacientes: [],
    observaciones: [],
    viewProgressBar: false,
    allAccounts: [],
  },
  mutations: {
    SET_PATIENTS_LIST (state, payload) {
      state.pacientes = payload
    },
    LOAD_ALL_ACCOUNTS (state, payload) {
      state.allAccounts = payload
    },
    SET_OBSERVACIONES_LIST (state, payload) {
      state.observaciones = payload
    },
    SET_VIEW_PROGRESSBAR (state, val) {
      state.viewProgressBar = val
    },
  },
  actions: {
    setViewProgressBar ({ commit }, val) {
      commit('SET_VIEW_PROGRESSBAR', val)
    },
    loadObservationLst ({ commit, rootState }, idPatient) {
      let path = ''
      if (rootState.users.user.selectAccount === 'personal') {
        path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${idPatient}/observation`
      } else {
        path = `/${rootState.users.user.selectAccount}/patients/${idPatient}/observation`
      }
      firebase.database().ref(path).once('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              return { id: cou, data: res[cou] }
            })
            commit('SET_OBSERVACIONES_LIST', arr)
          } else {
            commit('SET_OBSERVACIONES_LIST', [])
          }
        } else {
          commit('SET_OBSERVACIONES_LIST', [])
        }
      })
    },
    deleteObservation ({ rootState }, data) {
      return new Promise((resolve, reject) => {
        let path = ''
        if (rootState.users.user.selectAccount === 'personal') {
          path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${data.patientId}/observation/${data.idObservation}`
        } else {
          path = `/${rootState.users.user.selectAccount}/patients/${data.patientId}/observation/${data.idObservation}`
        }
        firebase.database().ref(path).remove().then(() => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    updateObservationPatient ({ commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        let path = ''
        if (rootState.users.user.selectAccount === 'personal') {
          path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${data.patientId}/observation/${data.idObservation}`
        } else {
          path = `/${rootState.users.user.selectAccount}/patients/${data.patientId}/observation/${data.idObservation}`
        }
        firebase.database().ref(path).set(
          {
            data: data.observation,
          },
        ).then(res => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    addObservationPatient ({ commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        let path = ''
        if (rootState.users.user.selectAccount === 'personal') {
          path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${data.patientId}/observation`
        } else {
          path = `/${rootState.users.user.selectAccount}/patients/${data.patientId}/observation`
        }
        firebase.database().ref(path).push(
          {
            data: data.observation,
          },
        ).then(res => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    loadDataPatients ({ commit, rootState }) {
      let path = ''
      if (rootState.users.user.selectAccount === 'personal') {
        path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}`
      } else {
        path = `/${rootState.users.user.selectAccount}/patients`
      }
      firebase.database().ref(path).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].data.uid = cou
              return { id: cou, data: res[cou] }
            })
            commit('SET_PATIENTS_LIST', arr)
          } else {
            commit('SET_PATIENTS_LIST', [])
          }
        } else {
          commit('SET_PATIENTS_LIST', [])
        }
      })
    },
    addPatient ({ rootState }, patient) {
      return new Promise((resolve, reject) => {
        let path = ''
        if (rootState.users.user.selectAccount === 'personal') {
          if (patient.uid) {
            path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${patient.uid}`
          } else {
            path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/`
          }
        } else {
          if (patient.uid) {
            path = `/${rootState.users.user.selectAccount}/patients/${patient.uid}`
          } else {
            path = `/${rootState.users.user.selectAccount}/patients/`
          }
        }
        if (patient.uid) {
          firebase.database().ref(path).set({ data: patient }).then(res => {
            resolve(res)
          }).catch((error) => {
            reject(error)
          })
        } else {
          firebase.database().ref(path).push({ data: patient }).then(res => {
            resolve(res)
          }).catch((error) => {
            reject(error)
          })
        }
      })
    },
    updatePatient ({ rootState }, [patient, idPatient]) {
      return new Promise((resolve, reject) => {
        let path = ''
        if (rootState.users.user.selectAccount === 'personal') {
          path = `/${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${idPatient}/data`
        } else {
          path = `/${rootState.users.user.selectAccount}/patients/${idPatient}/data`
        }
        firebase.database().ref(path).set(patient).then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    cleanData ({ commit }) {
      commit('SET_PATIENTS_LIST', [])
    },
    // metodo para guardar o editar las cuentas del paciente
    savePatientAccount ({ state, rootState }, [patientId, treatmentId, patientAccData, patientAccId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/patientAccount/${rootState.users.user.uid}/${patientId}/${patientAccId}`).set({ finanProcessRows: patientAccData, treatmentBelongId: treatmentId }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // metodo para guardar valor total, saldo, pagado, dcto y tipo de dcto
    // saveTotalValues ({ state, rootState }, [patientId, patientAccId, recidueToPay, actualPaid, totalWithDisc, actualDiscount, dsctoType, totalWithoutDisc]) {
    //   return new Promise((resolve, reject) => {
    //     firebase.database().ref(`${rootState.users.user.selectAccount}/patientAccount/${rootState.users.user.uid}/${patientId}/${patientAccId}/totalValues`).set({ recidueToPay: recidueToPay, actualPaid: actualPaid, totalWithDisc: totalWithDisc, actualDiscount: actualDiscount, dsctoType: dsctoType, totalWithoutDisc: totalWithoutDisc }).then((res) => {
    //       resolve(res)
    //     }).catch((err) => {
    //       reject(err)
    //     })
    //   })
    // },
    loadPatientAccountLst ({ commit, rootState }, patientId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/patientAccount/${rootState.users.user.uid}/${patientId}`).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              resolve(arr)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // solo los usuarios con la bandera podran ver la tablas de cuentas pendientes de todos los pacientes
    checkIfShowPending ({ commit, rootState }, drId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${drId}/showPending`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              resolve(res)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // se cargan todas las cuentas pendientes de todos los pacientes
    loadAllAccountLst ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/patientAccount/${rootState.users.user.uid}`).on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(patientId => {
                return {
                  id: patientId,
                  patientData: Object.keys(res[patientId]).map(finanId => {
                    return {
                      id: finanId,
                      finanData: res[patientId][finanId],
                    }
                  }),
                }
              })
              resolve(arr)
              commit('LOAD_ALL_ACCOUNTS', arr)
            } else {
              commit('LOAD_ALL_ACCOUNTS', [])
              resolve([])
            }
          } else {
            commit('LOAD_ALL_ACCOUNTS', [])
            resolve([])
          }
        })
      })
    },
    // se usa para obtener el descuento para la tabla de cuentas pendientes
    loadTreatmentData ({ commit, rootState }, [patientId, treatId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/treatments/${rootState.users.user.uid}/${patientId}/${treatId}`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              resolve(res)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    // para calculo de total en tabla de cuentas pendientes
    // loadLastReceipt ({ commit, rootState }, [patientId, lastReceiptId]) {
    //   return new Promise((resolve, reject) => {
    //     firebase.database().ref(`${rootState.users.user.selectAccount}/receipts/${rootState.users.user.uid}/${patientId}/${lastReceiptId}`).once('value', (snapshot) => {
    //       if (snapshot) {
    //         const res = snapshot.val()
    //         if (res) {
    //           resolve(res)
    //         } else {
    //           resolve([])
    //         }
    //       } else {
    //         resolve([])
    //       }
    //     }).catch((err) => {
    //       reject(err)
    //     })
    //   })
    // },
    // para llenar datos de tabla de cuentas pendientes
    loadPatientData ({ commit, rootState }, patientId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${patientId}`).once('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              resolve(res)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        }).catch((err) => {
          reject(err)
        })
      })
    },
    deletePatientAccount ({ rootState }, [patientId, patientAccountId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/patientAccount/${rootState.users.user.uid}/${patientId}/${patientAccountId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
  getters: {

  },
}
