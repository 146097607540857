// =========================================================
// * Vuetify Material Dashboard
// =========================================================
//
// * Product Page: https://dentalprimes.com/
// * Copyright 2020 DentalPrime(https://dentalprimes.com/)
//
// * Coded by J2RTECH S.A.S.
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import VueTour from 'vue-tour'
import Vue2Editor from 'vue2-editor'

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import jQuery from 'jquery'
import 'popper.js'

import Chat from 'vue-beautiful-chat'
import VueLoading from 'vuejs-loading-plugin'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueSmoothScroll from 'vue2-smooth-scroll'
import vWow from 'v-wow'
Vue.use(vWow)

Vue.use(VueSmoothScroll)

require('vue-tour/dist/vue-tour.css')

global.jQuery = jQuery
global.$ = jQuery

const config = {
  apiKey: 'AIzaSyBqEiIwh95Gafy0lAZblyXEjJW8ECGvTgY',
  authDomain: 'dental-produccion.firebaseapp.com',
  databaseURL: 'https://dental-produccion.firebaseio.com',
  projectId: 'dental-produccion',
  storageBucket: 'dental-produccion.appspot.com',
  messagingSenderId: '431957235275',
  appId: '1:431957235275:web:574d49051df93e47235fce',
  measurementId: 'G-2GNCLYRLL8',
}
firebase.initializeApp(config)
firebase.firestore().enablePersistence()
  .catch(function (err) {
    if (err.code === 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  })

Vue.config.productionTip = false
const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.'
Vue.config.warnHandler = function (msg, vm, trace) {
  if (msg === ignoreWarnMessage) {
    msg = null
    vm = null
    trace = null
  }
}
Vue.use(Chat)
Vue.use(VueTour)
Vue.use(VueLoading)
Vue.use(Vue2Editor)
firebase.auth().onAuthStateChanged(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
})
