<template lang="pug">
  v-app.split-bg
    v-dialog(v-model='dialog' scrollable='' max-width='400px')
    .text-center
      v-dialog(v-model='dialogTC' width='500')
        v-card
          v-card-text
            p(style='text-align: center;')
              span(style='color: rgb(71, 85, 119);')
                span(style='font-size: 24px;')
                  strong Condiciones de uso de DentalPrime
            p(style='text-align: justify; color:black;')
              | Los presentes Te&#x301;rminos y Condiciones Generales regulan el acceso, el uso y el pago de los servicios ofertados en el sitio web www.dentalprimeapp.com (en adelante, DentalPrime), del que es titular la empresa J2R TECH S.A.S. domiciliada en Cuenca, Ecuador. Los servicios van destinados a Especialistas de Salud (Doctores, Odonto&#x301;logos, Universidades con sus escuelas de pregrado y posgrado en a&#x301;reas me&#x301;dicas y Centros me&#x301;dicos o Cli&#x301;nicas). El acceso y la adquisicio&#x301;n de cualesquiera de los servicios conlleva la aceptacio&#x301;n plena y sin reservas de todas y cada una de las Condiciones Generales que se indican, junto con el Aviso Legal, sin perjuicio de la aceptacio&#x301;n de las Condiciones Particulares que pudieran ser de aplicacio&#x301;n al adquirir determinados servicios. Estas Condiciones Generales podra&#x301;n ser modificadas sin notificacio&#x301;n previa, por tanto, es recomendable leer atentamente su contenido antes de proceder a la adquisicio&#x301;n de cualquiera de los servicios ofertados. En caso de estar en desacuerdo con la totalidad o parte de las presentes Condiciones, ud. debe de abstenerse de continuar utilizando el sitio web y/o sus servicios.
            p(style='text-align: justify; color:black;')
              strong DEFINICIONES
              | :
            p(style='text-align: justify; color:black;')
              strong Paciente
              | : Es el sujeto que recibe los servicios de un me&#x301;dico u otro profesional de la salud y se somete a una cita, un examen, a un tratamiento o a una intervencio&#x301;n.
            p(style='text-align: justify; color:black;')
              strong Centros me&#x301;dicos o Cli&#x301;nicas
              | : Hospital o centro de salud donde el especialista diagnostica y trata a personas con problemas de salud. En general, cualquier lugar de realizacio&#x301;n de actividades en el a&#x301;mbito de los servicios de salud, me&#x301;dicos o parame&#x301;dicos.
            p(style='text-align: justify; color:black;')
              strong Especialista de Salud
              | : Un profesional en medicina, como odonto&#x301;logo, psico&#x301;logo, fisioterapeuta, enfermera, nutrio&#x301;logo, terapeuta u otra persona no mencionada anteriormente, que realice actividades en el campo de los servicios de salud y brinda servicios de salud, me&#x301;dicos o parame&#x301;dicos.
            p(style='text-align: justify; color:black;')
              strong Perfil
              | : Un conjunto de informacio&#x301;n sobre un profesional ubicado en el sitio web en forma de subpa&#x301;gina con una direccio&#x301;n URL u&#x301;nica, el perfil puede ser Ba&#x301;sico o Premium, en los cuales las u&#x301;nicas diferencias versan en que el primero no tiene ningu&#x301;n costo por registrase en el sitio web y el segundo si&#x301;.
            p(style='text-align: justify; color:black;')
              strong Cliente
              | : Persona que adquiere los Servicios de DentalPrime.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 1. Misio&#x301;n de DentalPrime.
            p(style='text-align: justify; color:black;')
              | La informacio&#x301;n proporcionada en www.dentalprimeapp.com ha sido planteada como una ayuda a los profesionales de la salud para mejorar la interaccio&#x301;n que tienen con sus pacientes y sus asistentes, al permitir almacenar informacio&#x301;n que los profesionales de la salud crean pertinente respecto a citas, historias odontolo&#x301;gicas, recetas, exa&#x301;menes y ma&#x301;s.
            p(style='text-align: justify; color:black;')
              | Consiste en la creaci&oacute;n y gesti&oacute;n de una plataforma para el almacenamiento de registro de pacientes, procedimientos dentales, citas, recetas, exa&#x301;menes, ima&#x301;genes y m&aacute;s informacio&#x301;n que los profesionales o cl&iacute;nicas consideren.
            p(style='text-align: justify; color:black;')
              | La informacio&#x301;n que haya sido validada por los usuarios depende exclusivamente de ellos. La que no ha sido validada proviene de fuentes pu&#x301;blicas como son listados y documentos publicitados por las diferentes compan&#x303;i&#x301;as. No es en ningu&#x301;n momento labor de DentalPrime el cumplimiento efectivo por ninguna de las partes de las obligaciones que se deriven de la utilizacio&#x301;n de este sitio web.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 2. Servicios ofrecidos por DentalPrime.
            p(style='text-align: justify; color:black;')
              | a) Perfil Ba&#x301;sico. Servicio por el que el profesional de la salud se registra o es registrado por el centro de salud. El perfil ba&#x301;sico es gratuito y el Especialista de Salud o Cl&iacute;nica tendr&aacute; la posibilidad de registrar un solo paciente con todas las funcionalidades que ofrece DentalPrime.
            p(style='text-align: justify; color:black;')
              | b) Perfil Premium. El sitio web le da la posibilidad al Especialista de Salud o Cl&iacute;nica de ver y acceder a todas las funcionalidades de la plataforma. Especialista de Salud o Cl&iacute;nica podr&aacute; registrar una cantidad limitada de pacientes, con todas las funciones que ofrece DentalPrime. La cantidad de pacientes permitida en la suscripci&oacute;n inicial es de 500 por usuario, para registrar una cantidad mayor a 500 pacientes, el valor a cobrar podr&iacute;a variar en funci&oacute;n del proveedor de infraestructura de red y base de datos.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reserva el derecho de decidir, en cada momento, los productos y/o servicios que se ofrecen. De este modo, DentalPrime podra&#x301;, en cualquier momento, an&#x303;adir nuevos productos y/o servicios a los ofertados actualmente. Asimismo DentalPrime se reserva el derecho a retirar o dejar de ofrecer, en cualquier momento, y sin previo aviso, cualesquiera de los servicios ofrecidos. Una vez dentro de www.dentalprimeapp.com, y para acceder a la contratacio&#x301;n de los distintos servicios, los Especialistas de Salud y/o Centros me&#x301;dicos o Cli&#x301;nicas debera&#x301;n seguir todas las instrucciones indicadas en el proceso de adquisicio&#x301;n de los servicios, lo cual supondra&#x301; la lectura y aceptacio&#x301;n de todas las condiciones generales y particulares fijadas en www.dentalprimeapp.com.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 3. Caracteri&#x301;sticas del Perfil Premium para Especialistas de Salud.
            p(style='text-align: justify; color:black;')
              | 1. Los servicios del Perfil Premium comienzan al momento que el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica, que ya esta&#x301; registrado en la pa&#x301;gina con el perfil ba&#x301;sico, es contactado por el personal de DentalPrime y realiza el pago de la membresi&#x301;a con su tarjeta de cre&#x301;dito o de&#x301;bito o en efectivo.
            p(style='text-align: justify; color:black;')
              | 2. El pago del Perfil Premium lo realiza el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica con la asistencia de un empleado de DentalPrime en caso de ser necesario.
            p(style='text-align: justify; color:black;')
              | 3. DentalPrime despue&#x301;s de recibir el pago por el perfil Premium, se compromete proporcionar al Cliente las siguientes funcionalidades:
            p(style='text-align: justify; color:black;')
              | a) Perfil de Usuario: el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; cargar una foto de perfil, editar sus datos personales o institucionales y cambiar su contrase&ntilde;a de acceso.
            p(style='text-align: justify; color:black;')
              | b) Agregar procedimientos: De los procedimientos disponibles, el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica seleccionar&aacute; los procedimientos que realiza con frecuencia y podr&aacute; configurar sus respectivos precios. Esta secci&oacute;n podr&aacute; ser administrada por la cl&iacute;nica dado sea el caso.
            p(style='text-align: justify; color:black;')
              | c) Pacientes: el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica, podr&aacute; crear nuevos registro de sus pacientes, adem&aacute;s de editarlos y consultarlos.
            p(style='text-align: justify; color:black;')
              | d) Odontograma: En esta secci&oacute;n el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica tambi&eacute;n podr&aacute; crear registros de los costos de los procedimientos registrados en cada tratamiento. Podr&aacute; crear prescripciones, estudios, certificados m&eacute;dicos y consentimientos informados. Esta secci&oacute;n podr&aacute; ser consultada por la cl&iacute;nica dado sea el caso.
            p(style='text-align: justify; color:black;')
              | e) Agenda: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; crear registro de futuras citas as&iacute; como consultar citas pasadas. La agenda podr&aacute; ser controlada por Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica.
            p(style='text-align: justify; color:black;')
              | f) Financiero: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; crear registros de sus ingresos y egresos, visualizar el saldo a pagar de cada tratamiento de cada paciente y crear registros de recibos.
            p(style='text-align: justify; color:black;')
              | g) Anamnesis: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; crear registros de antecedentes personales, personales y h&aacute;bitos de higiene bucal por cada paciente. Adem&aacute;s del Especialista, la cl&iacute;nica podr&aacute; acceder a estos datos en caso de requerirlo.
            p(style='text-align: justify; color:black;')
              | h) Signos vitales: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; crear registros de signos vitales, datos antropom&eacute;tricos y mediciones capilares de cada paciente.
            p(style='text-align: justify; color:black;')
              | i) Examen F&iacute;sico: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; crear registros de examen f&iacute;sico de cada paciente.
            p(style='text-align: justify; color:black;')
              | j) Preguntas frecuentes: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; personalizar preguntas que suela consultar a los pacientes en las primeras citas, adem&aacute;s de crear los registros de sus respuestas.
            p(style='text-align: justify; color:black;')
              | k) Chat de soporte: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podr&aacute; comunicarse remotamente con el personal de soporte de DentalPrime mediante el chat de soporte dentro del horario de atenci&oacute;n.
            p(style='text-align: justify; color:black;')
              | l) Dudas y sugerencias: El Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica tambi&eacute;n podr&aacute; comunicarse con el personal de soporte de DentalPrime mediante la secci&oacute;n de Dudas y Sugerencias creando registros con motivo y comentario respecto a su experiencia usando DentalPrime.
            p(style='text-align: justify; color:black;')
              | Para evitar dudas, la remuneracio&#x301;n debida a DentalPrime por proporcionar los Servicios de Perfil Premium se refiere a la disposicio&#x301;n de proporcionar Servicios para el Cliente. Esto significa, en particular, que la falta de uso de los Servicios del Perfil Premium o el uso de solo funcionalidades seleccionadas no da derecho a una reclamacio&#x301;n por parte del Cliente por una reduccio&#x301;n en la remuneracio&#x301;n o cancelacio&#x301;n del Servicio.
            p(style='text-align: justify; color:black;')
              strong
                | No todas las funcionalidades anteriores descritas en el Perfil Premium esta&#x301;n disponibles para los perfiles ba&#x301;sicos.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;')
                  | 4. Un especialista de salud puede tener solo un perfil que puede ser asignado a ma&#x301;s que un Centro me&#x301;dico o Cli&#x301;nica.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reserva el derecho de suspender el acceso a la plataforma de DentalPrime o cancelar la licencia con efecto inmediato debido al uso indebido de DentalPrime o algu&#x301;n uso que sea considerado en incumplimiento con los te&#x301;rminos y condiciones, en particular:
            p(style='text-align: justify; color:black;')
              | i. El uso de cualquier funcionalidad de un modo que afecte adversamente a otros usuarios de internet.
            p(style='text-align: justify; color:black;') ii. Alterar la informacio&#x301;n de otros usuarios del portal o de DentalPrime.&nbsp;
            p(style='text-align: justify; color:black;') iii. Alterar el co&#x301;digo fuente de cualquier funcionalidad de DentalPrime.&nbsp;
            p(style='text-align: justify; color:black;') iv. Violacio&#x301;n de los intereses personales de DentalPrime.&nbsp;
            p(style='text-align: justify; color:black;')
              | v. Participar en actos que compitan directamente con el objeto social de DentalPrime.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 5. Caracteri&#x301;sticas del Perfil Premium para Centros me&#x301;dicos o Cli&#x301;nicas.
            p(style='text-align: justify; color:black;')
              | 1. El servicio de Paquete Premium se proporcionara&#x301; con base a un elegido nu&#x301;mero de Perfiles de Especialistas de Salud que har&aacute;n uso de DentalPrime.
            p(style='text-align: justify; color:black;')
              | 2. El Centro me&#x301;dico o Cli&#x301;nica puede comprar los perfiles adicionales de los Especialistas de Salud durante la vigencia del contrato. La asignacio&#x301;n de los perfiles adicionales no requiere el modo provisto para cambiar el contrato.
            p(style='text-align: justify; color:black;')
              | 3. La asignacio&#x301;n de los Perfiles subsiguientes durante la vigencia del contrato esta&#x301; relacionada con la adquisicio&#x301;n por parte de DentalPrime el derecho a la remuneracio&#x301;n por la prestacio&#x301;n de los Servicios de Paquete Premium basados en estos Perfiles. Si el usuario del servicio asigna perfiles subsiguientes durante la vigencia del contrato, la permanencia del contrato es vinculante para 12 meses completos a partir del di&#x301;a de su asignacio&#x301;n.
            p(style='text-align: justify; color:black;')
              | 4. El Centro me&#x301;dico o Cli&#x301;nica puede desconectar los Perfiles, es decir, dejar de proporcionar los Servicios de Paquete Premium basados en los Perfiles seleccionados, durante la vigencia del contrato.
            p(style='text-align: justify; color:black;')
              | 5. La desconexio&#x301;n del Perfil puede tener lugar a ma&#x301;s tardar 7 di&#x301;as naturales antes del final del Peri&#x301;odo de Liquidacio&#x301;n.
            p(style='text-align: justify; color:black;')
              | 6. El Centro me&#x301;dico o Cli&#x301;nica puede asignar perfiles para los cuales la direccio&#x301;n de las actividades de desempen&#x303;o en el a&#x301;mbito de los servicios de salud, servicios me&#x301;dicos, me&#x301;dicos o parame&#x301;dicos sea ide&#x301;ntica a la direccio&#x301;n de la oficina del Receptor o la direccio&#x301;n de otro lugar de negocios documentado del Cliente.
            p(style='text-align: justify; color:black;')
              | 7. Al realizar cualquier operacio&#x301;n en la Cuenta, incluida la asignacio&#x301;n de Perfiles, el Cliente declara que estas operaciones se llevan a cabo con su conocimiento y consentimiento, asi&#x301; como que acepta estas operaciones como sus propias actividades.
            p(style='text-align: justify; color:black;')
              | El usuario registrado bajo el perfil de pago anual obtendra&#x301; una membresi&#x301;a mediante el pago de una cantidad determinada, cabe sen&#x303;alar que los Especialistas de Salud, Centros Me&#x301;dicos o Cli&#x301;nicas registrados bajo este perfil
              strong estara&#x301;n sujetos a permanecer en la plataforma por un plazo mi&#x301;nimo de 12 meses
              |  contados a partir del di&#x301;a en que el usuario haya realizado el pago del servicio.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 6. Validacio&#x301;n de los Perfiles.
            p(style='text-align: justify; color:black;')
              | En el caso de que DentalPrime adquiera informacio&#x301;n que pueda justificar la suposicio&#x301;n de que un profesional no tiene el derecho de ejercer servicios de salud, me&#x301;dicos o parame&#x301;dicos, DentalPrime puede solicitar la verificacio&#x301;n de dicho profesional y rescindir el contrato. DentalPrime no hace las devoluciones del servicio Perfil Premium por el periodo en el cual el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica eran Clientes.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 7. Derechos y obligaciones de DentalPrime y Clientes.
            p(style='text-align: justify; color:black;')
              | Las partes aceptan que cumplira&#x301;n todas las obligaciones que resulten con la diligencia impli&#x301;cita a la naturaleza profesional de sus actividades.
            p(style='text-align: justify; color:black;')
              strong El cliente se obliga a:&nbsp;
            p(style='text-align: justify; color:black;') a. Realizar los pagos a tiempo.&nbsp;
            p(style='text-align: justify; color:black;')
              | b. Cooperar con DentalPrime para poder cumplir con el paquete de servicios del perfil Premium.
            p(style='text-align: justify; color:black;')
              | Ahora bien, en tal situacio&#x301;n se considerara&#x301; que el paquete de servicios del perfil premium fue debidamente provei&#x301;do y no presentara&#x301; reclamo alguno en contra de DentalPrime al respecto.
            p(style='text-align: justify; color:black;')
              strong DentalPrime se obliga a:
            p(style='text-align: justify; color:black;')
              | a. Dar al Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica, despue&#x301;s de haberse generado el cobro de la primera factura de inscripcio&#x301;n a su membresi&#x301;a premium, acceso a todas las herramientas que ofrece DentalPrime.
            p(style='text-align: justify; color:black;')
              | b. Implementar funciones que permitan al Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica administrar los registros de sus pacientes, incluyendo el monitoreo y gestio&#x301;n de los mismos.
            p(style='text-align: justify; color:black;')
              | c. Dar asistencia al Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica para crear o editar los registros de sus pacientes en caso de que presente inconveniente con el manejo de las herramientas provistas por DentalPrime.
            p(style='text-align: justify; color:black;')
              | d. Proporcionar apoyo y soporte te&#x301;cnico al Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica en los horarios definidos en la pa&#x301;gina web.
            p(style='text-align: justify; color:black;')
              | DentalPrime podra&#x301; alterar el contenido y los materiales proporcionados por el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica y e&#x301;ste autoriza a DentalPrime a hacerlo. Las alteraciones se hara&#x301;n, especi&#x301;ficamente, para mejorar la calidad del contenido publicado y no sera&#x301;n cambios que puedan afectar la oferta del paquete de servicios del perfil premium.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reserva el derecho de suspender el acceso al Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica si el &uacute;ltimo incumple con los Te&#x301;rminos y Condiciones, asi&#x301; como, si, a juicio de DentalPrime el contenido o formato del paquete de servicios premium incumple con las leyes en relacio&#x301;n con las actividades de DentalPrime o es de cualquier modo inaceptable o inadmisible, el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica sera&#x301; notificado sobre la suspensio&#x301;n al acceso a su cuenta mediante correo electro&#x301;nico a la direccio&#x301;n establecida por Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 8. Modalidad de la membresi&#x301;a Premium.
            p(style='text-align: justify; color:black;')
              strong Membresi&#x301;a anual
              | : esta membresi&#x301;a se pagara&#x301; por anticipado, el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica
              strong estara&#x301; sujeto a permanecer obligatoriamente por el plazo mi&#x301;nimo de 12 meses
              | , este pago se realizara&#x301; con tarjeta de cre&#x301;dito o de&#x301;bito con cargos recurrentes anuales o en efectivo.
            p(style='text-align: justify; color:black;')
              strong Membres&iacute;a Semestral
              | : esta membresi&#x301;a se pagara&#x301; por anticipado, el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica
              strong estara&#x301; sujeto a permanecer obligatoriamente por el plazo mi&#x301;nimo de 6 meses
              | , este pago se realizara&#x301; con tarjeta de cre&#x301;dito o de&#x301;bito con cargos recurrentes semestrales o en efectivo.
            p(style='text-align: justify; color:black;')
              strong Membres&iacute;a mensual
              | : esta membresi&#x301;a se pagara&#x301; por anticipado, el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica
              strong estara&#x301; sujeto a permanecer obligatoriamente por el plazo mi&#x301;nimo de 1 mes
              | , este pago se realizara&#x301; con tarjeta de cre&#x301;dito o de&#x301;bito con cargos recurrentes mensuales o en efectivo.
            p(style='text-align: justify; color:black;')
              | Una vez transcurrido el plazo de suscripci&oacute;n, el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica podra&#x301; solicitar la cancelacio&#x301;n.
            p(style='text-align: justify; color:black;')
              | Por cada Servicio adquirido en DentalPrime, DentalPrime se compromete emitir y hacer disponible la factura.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 9. Me&#x301;todo de pago de la membresi&#x301;a del Perfil Premium.
            p(style='text-align: justify; color:black;')
              | Las partes aceptan y reconocen que la fecha de corte sera&#x301; considerada como el di&#x301;a en el que se realizo&#x301; el pago en la cuenta de banco de DentalPrime.
            p(style='text-align: justify; color:black;')
              | En caso de que DentalPrime no sea exitoso en sus intentos de cobro de algu&#x301;n pago, ya sea total o parcial, dicho incumplimiento resultara&#x301; en el bloqueo de acceso a todos los elementos que ofrece el paquete de servicios del perfil Premium.
            p(style='text-align: justify; color:black;')
              | En caso de que el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica no reciban la factura, no significa que quedan liberados de la obligacio&#x301;n de pagar el servicio de membresi&#x301;a Premium.
            p(style='text-align: justify; color:black;')
              | Los precios de cada plan ofrecidos por DentalPrime no incluyen I.V.A., por lo cual, el valor total de cada plan de suscripci&oacute;n se define como la suma de los precios publicados m&aacute;s el porcentaje de I.V.A. establecidos en ese momento. Los precios estara&#x301;n sujetos a cambios y actualizaciones. El precio pactado de las membresi&#x301;as no sera&#x301; modificado sino hasta que se hayan cumplido los plazos mi&#x301;nimos de permanencia (12 meses).
            p(style='text-align: justify; color:black;')
              | Los precios podra&#x301;n ser sujetos de descuento, dependiendo el caso concreto de cada usuario.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reservara&#x301; el derecho de cobro y actuara&#x301; conforme a lo establecido en la ley.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 10. Poli&#x301;ticas de cancelacio&#x301;n de las membresi&#x301;as del Perfil Premium.
            p(style='text-align: justify; color:black;')
              | a. El Especialista de salud, Centro me&#x301;dico o Cli&#x301;nica puede decidir darse de baja del servicio siempre que sea despue&#x301;s del tiempo de permanencia estipulado en el momento de contratar el servicio, y que puede variar en funcio&#x301;n del tipo de servicio y momento en que se contrato&#x301;.
            p(style='text-align: justify; color:black;')
              | b. El periodo de permanencia obligatorio por defecto son 12 meses en el caso de la membresi&#x301;a anual, 6 meses en el caso de la membresi&#x301;a semestral, 1 mes en el caso de la membresi&#x301;a mensual.
            p(style='text-align: justify; color:black;')
              | c. La aceptacio&#x301;n de las presentes condiciones en el momento de adquirir el servicio equivale a un contrato entre las partes por las que el cliente se compromete a pagar 12 meses mi&#x301;nimo en el caso de la membres&iacute;a anual, 6 meses mi&#x301;nimo en el caso de la membres&iacute;a semestral y 1 mes mi&#x301;nimo en el caso de la membres&iacute;a mensual. En el caso que el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica quisiera darse de baja antes de los 12 meses, debera&#x301; pagar el importe por concepto de pena convencional a DentalPrime.
            p(style='text-align: justify; color:black;')
              | d. Una vez que hayan transcurrido el tiempo de la suscripci&oacute;n, peri&#x301;odos de permanencia desde el inicio del Servicio de membres&iacute;a anual, semestral o mensual, el contrato y prestacio&#x301;n de servicios se prorroga automa&#x301;ticamente por un peri&#x301;odo indefinido, con pagos mensuales, semestrales o anuales recurrentes, dependiendo de la membres&iacute;a adquirida por el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica.
            p(style='text-align: justify; color:black;')
              | e. El servicio puede ser cancelado por distintas vi&#x301;as: mediante las opciones disponibles en el menu&#x301; privado de Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica, o mediante llamada al nu&#x301;mero +593 09 845 0998, +593 99 079 3760 o correo electro&#x301;nico: j2rtechsas@gmail.com. DentalPrime no devolvera&#x301; el importe del servicio una vez renovada la suscripcio&#x301;n - El servicio seguira&#x301; activo hasta que se llegue a la fecha hasta la cual la suscripcio&#x301;n este&#x301; contratada y por la que se habi&#x301;a ya pagado. En caso de querer cancelar el servicio, debe comunicar esta intencio&#x301;n con al menos 15 di&#x301;as naturales de anterioridad a la fecha de renovacio&#x301;n. Llegado el di&#x301;a de renovacio&#x301;n, se considera que el cliente mantiene su suscripcio&#x301;n por como mi&#x301;nimo un periodo de contratacio&#x301;n ma&#x301;s. En caso de retornar el recibo u otros me&#x301;todos para dejar de pagar servicios contratados, DentalPrime se reserva el derecho a utilizar todos los medios te&#x301;cnicos y legales para reclamar la deuda pendiente.
            p(style='text-align: justify; color:black;')
              | f. Existen las excepciones cuando el Cliente puede darse de baja del Servicio del Perfil Premium durante el periodo de permanencia:
            p(style='text-align: justify; color:black;') a) La muerte del Especialista de Salud comprobada con el Acta de Defuncio&#x301;n&nbsp;
            p(style='text-align: justify; color:black;') b) Los casos de extorsio&#x301;n comprobados con la Acta del Ministerio Pu&#x301;blico&nbsp;
            p(style='text-align: justify; color:black;')
              | c) La suspensio&#x301;n del derecho de ejercer la profesio&#x301;n comprobada con el Acta correspondiente.
            p(style='text-align: justify; color:black;')
              | En cualquier caso mencionado en el punto f. DentalPrime no hace las devoluciones de los periodos ya pagados.
            p(style='text-align: justify; color:black;')
              | Una vez cancelada la membresi&#x301;a del Perfil Premium, el usuario permanecera&#x301; en el perfil ba&#x301;sico o gratuito, para lo cual supone la restriccio&#x301;n al acceso a los archivos (fotos, vi&#x301;deos, textos y otras caracteri&#x301;sticas especiales) que superen el li&#x301;mite de cantidad propias de la cuenta ba&#x301;sica gratuita, asi&#x301; como el cese de actividades propias de los servicios de valor an&#x303;adido y se limitar&aacute; el n&uacute;mero de pacientes registrados por el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica.
            p(style='text-align: justify; color:black;')
              | El Proveedor de servicios se reserva el derecho de rescindir el contrato de adhesio&#x301;n al eliminar la Cuenta, si la accio&#x301;n del Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica no esta&#x301; de acuerdo con la ley, te&#x301;rminos y condiciones vigentes o la imagen del sitio web y/o la empresa. La cancelacio&#x301;n tiene efecto inmediato.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 11. Falta de pago y fallos en la renovacio&#x301;n.&nbsp;
            p(style='text-align: justify; color:black;')
              | En la fecha de la renovacio&#x301;n, el sistema de pagos autorizado por DentalPrime hara&#x301; los intentos del cobro. Durante los siguientes 10 di&#x301;as a partir de la fecha de error de pago, el sistema va a hacer los intentos del pago necesarios para realizar el cobro exitoso. Si en el plazo de 10 di&#x301;as el pago no se hace efectivo DentalPrime se reserva el derecho de cancelar el servicio. A efectos de suscripciones con permanencia, e&#x301;sta seguiri&#x301;a vigente y por tanto DentalPrime podri&#x301;a reclamar el pago de los meses pendientes de dicha permanencia.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 12. Modificacio&#x301;n de los presentes te&#x301;rminos y condiciones.
            p(style='text-align: justify; color:black;')
              | El acceso y la adquisicio&#x301;n de cualesquiera de los servicios conlleva la aceptacio&#x301;n plena y sin reservas de todas y cada uno de los te&#x301;rminos y condiciones que se indican, junto con el Aviso Legal, sin perjuicio de la aceptacio&#x301;n de las Condiciones Particulares que pudieran ser de aplicacio&#x301;n al adquirir determinados servicios.
            p(style='text-align: justify; color:black;')
              | Estos te&#x301;rminos y condiciones podra&#x301;n ser modificados sin notificacio&#x301;n previa, por tanto, es recomendable leer atentamente su contenido antes de proceder a la adquisicio&#x301;n de cualquiera de los servicios ofertados.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 13. Mantenimiento de la pa&#x301;gina.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reserva el derecho de realizar trabajos de mantenimiento e innovaci&oacute;n de sistema de gesti&oacute;n de pacientes, tratamientos y dem&aacute;s servicios de DentalPrime, lo que puede causar dificultades o impedir el uso del servicio del Paquete Premium. El conocido fi&#x301;sico hara&#x301; todos los esfuerzos para garantizar que el trabajo de mantenimiento se lleve a cabo durante las horas nocturnas y en los di&#x301;as que normalmente no tienen trabajo.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 14. Uso de cookies.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reserva el derecho de utilizar cookies de almacenamiento de datos para mantener activa la sesi&oacute;n del Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica en todos los dispositivos en los que inicie sesi&oacute;n.
            p(style='text-align: justify; color:black;')
              | DentalPrime no se responsabiliza por la integridad de las cookies en caso de que el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica elimine las cookies del navegador en el que se encontraba haciendo uso de los servicios ofertados por DentalPrime. En este caso el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica deber&aacute; iniciarsesi&oacute;n nuevamente y configurar nuevamente sus preferencias en la plataforma de DentalPrime.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 15. Gesti&oacute;n de datos.
            p(style='text-align: justify; color:black;')
              | DentalPrime no se hace responsable de la veracidad, legalidad o manejo de los datos y/o registros que el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica guarde en la base de datos de DentalPrime.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reserva el derecho de garantizar la integridad de los datos que el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica guarden en la aplicaci&oacute;n web debido a su dependencia de terceros para el almacenamiento de sus datos. En caso de producirse la ausencia de servicio por parte de un tercero DentalPrime tomar&aacute; las medidas t&eacute;cnicas y legales necesarias para poder reestablecer la normalidad del servicio.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 16. Uso de datos an&oacute;nimos.
            p(style='text-align: justify; color:black;')
              | DentalPrime se reserva el derecho de utilizar de forma an&oacute;nima los datos almacenados por el Especialista de Salud, Centro Me&#x301;dico o Cli&#x301;nica en la base de datos con fines investigativos para el desarrollo y mejorar de los servicios provistos actualmente y en el futuro.
            p(style='text-align: justify; color:black;')
              strong
                span(style='font-size: 18px;') 17. Gestión de sesiones.
            p(style='text-align: justify; color:black;')
              | DentalPrime al proporcionarle al profesional la posibilidad de utilizar su sesión de usuario en diferentes dispositivos de manera simultánea, no se responsabiliza por el uso que el Especialista de Salud, Centro Médico o Clínica le pueda dar a esta funcionalidad o los perjuicios que pueda ocasionar el descuido de dejar abierta una sesión en cualquier dispositivo.
          v-divider
          v-card-actions
            v-spacer
            v-btn(color='primary' @click='dialogTC = false')
              | LISTO
    //- v-dialog(v-model='confirmationDialogForGmail' persistent max-width='400')
    //-   v-card
    //-     v-card-title.headline(style='padding:10px;')
    //-       | Verificación
    //-     v-divider
    //-     v-card-text Te enviaremos un correo con un link, debes seleccionarlo para verificar tu cuenta. Si no lo encuentras, revisa en tu bandeja de correo no deseado.
    //-     v-card-actions
    //-       v-spacer
    //-         v-btn(color='blue darken-1' text='' @click='confirmationDialogForGmail = false')
    //-           | Aceptar
    //- v-dialog(v-model='confirmationDialogForHotmail' persistent max-width='400')
    //-   v-card
    //-     v-card-title.headline(style='padding:10px;')
    //-       | Verificación
    //-     v-divider
    //-     v-card-text Revisa tu bandeja de
    //-       strong  CORREO NO DESEADO
    //-       |  y selecciona el link que te hemos enviado en un correo para verificar tu cuenta.
    //-     v-card-actions
    //-       v-spacer
    //-         v-btn(color='blue darken-1' text='' @click='confirmationDialogForHotmail = false')
    //-           | Aceptar
    //- v-dialog(v-model='confirmationDialogVerify' persistent max-width='400')
    //-   v-card
    //-     v-card-title.headline(style='padding:10px;')
    //-       | Verificación
    //-     v-divider
    //-     v-card-text Aún no has verificado tu correo, dirígete al email que te hemos enviado y selecciona el link adjunto por favor, si no lo encuentras, revisa la bandeja de correo no deseado. En el caso de no poder verificar tu cuenta puedes recuperar tu contraseña para que se envíe un nuevo correo.
    //-     v-card-actions
    //-       v-spacer
    //-         v-btn(color='blue darken-1' text='' @click='finishInit')
    //-           | Aceptar
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' text='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-dialog(v-model='forgotPassDialog' max-width='400px')
      v-card
        v-card-title.headline(style='padding-top: 20px;')
          strong
            | Recuperar contraseña
        v-card-text(style='text-align:center')
          | Ingresa tu correo y te enviaremos un link para recuperar tu contraseña
          strong  NO OLVIDES REVISAR LA BANDEJA DE CORREO NO DESEADO
          v-text-field(label='Correo' v-model='emailToRecoverPass')
        v-card-actions(style='justify-content: flex-end;')
          v-btn(color='red' text='' @click='closeRecoverPass') Cerrar
          v-btn(color='primary' @click='forgotPass' :loading="loadingSendPass") Enviar
    //- v-flex(v-show='$vuetify.breakpoint.lg || $vuetify.breakpoint.xl' style='display:flex; flex-direction:column; justify-content:center; margin-top:2px; padding:0px;')
    //-   v-carousel.elevation-2(hide-delimiters='' hidden-md-and-down='' cycle)
    //-     v-carousel-item(v-for='(item,i) in carouselImages' :key='i' :src='item.src' reverse-transition='fade-transition' transition='fade-transition')
    //-   v-footer.elevation-2.div-footer(height='auto' color='primary lighten-1' style="padding-top: 0px !important;")
    //-     v-flex(light-blue='' darken-3='' white--text='' font-weight-black='')
    //-       v-row(style='align-items: center;')
    //-         v-col(cols='6' style='padding:25px 0px 40px 20px; margin: 0px; height: 68px;')
    //-           p(style='padding-left:20px; text-align:initial; color:white;')
    //-             | DENTALPRIME - 2022 &copy;
    //-         v-col(cols='6' style='text-align:end;')
    //-           v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
    //-             v-icon(color="white") mdi-facebook
    //-           v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/dentalprimeapp/')")
    //-             v-icon(color="white") mdi-instagram
    //-           v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
    //-             v-icon(color="white") mdi-whatsapp
    div(style='display:flex; justify-content:center; align-items:center; height:100%; flex-direction:column;')
      v-flex(xs12 sm12 md12 lg5 fill-height='' child-flex='')
        v-layout
          v-card.elevation-2.pa-3(style='background: rgb(217 224 234);')
            v-card-text
              .layout.column.align-center
                img(v-if='register' :src='imgLogo' alt='DentalPrime Logo' width='80' height='80')
                h1.flex.my-4.black--text.font-weight-black(v-show='register' style='text-align:center; font-size:32px;') DENTALPRIME
              v-form(v-if='register' ref='formLogin' v-model='validLogin' lazy-validation='')
                v-text-field.inputs(:rules='emailRules' append-icon='mdi-account' name='login' label='Correo de usuario' type='text' v-model='model.username' placeholder=' ' @keyup.enter='login(model.username, model.password)')
                v-text-field#logPassword(:rules='[rules.required, rules.min]' :append-icon="showPassLogin ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassLogin = !showPassLogin" name='password' label='Contraseña' placeholder=' ' :type="showPassLogin ? 'text' : 'password'" v-model='model.password' @keyup.enter='login(model.username, model.password)')
              v-form(v-if='!register' ref='formRegister' v-model='validRegister' lazy-validation='')
                v-select(@change="changeAccount" :rules='[rules.required]' return-object v-model='modelRegister.tipoCuenta' :items='accountType' item-text='name' label='Tipo de Cuenta' append-icon='mdi-clipboard-account')
                v-text-field(:rules='emailRules' append-icon='mdi-email' name='login' label='Correo' type='text' v-model='modelRegister.email')
                v-text-field(:rules='[rules.required]' :append-icon='banCode ? "mdi-account-plus" : "mdi-home"' name='login' :label='banCode ? "Nombres" : "Nombre de la Clínca"' type='text' v-model='modelRegister.firstName')
                v-text-field(:rules='[rules.required]' :append-icon='banCode ? "mdi-account-plus" : "mdi-account-box"' name='login' :label='banCode ? "Apellidos" : "Representante"' type='text' v-model='modelRegister.lastName')
                v-text-field(:rules='phoneRule' append-icon='mdi-cellphone' name='login' label='Teléfono' type='number' v-model='modelRegister.phoneNumber')
                v-text-field#regPassword(:rules='[rules.required, rules.minPassword]' :append-icon="showPassReg ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassReg = !showPassReg" name='password' label='Contraseña' :type="showPassReg ? 'text' : 'password'" v-model='modelRegister.password')
                v-text-field#confirmPassword(:rules='[rules.required, rules.minPassword]' :append-icon="showPassConfirm ? 'mdi-eye' : 'mdi-eye-off'" @click:append="showPassConfirm = !showPassConfirm" name='confirmPassword' label='Confirmar Contraseña' :type="showPassConfirm ? 'text' : 'password'" v-model='passwordConfirm')
            v-card-actions(v-show='register' style='padding-top:0px;')
              v-row
                v-col(cols='12' style="display:flex; justify-content:center; align-items:center;")
                  v-btn(:disabled="loading" color='light-blue darken-3' @click='login(model.username, model.password)' :loading='loading' dark='' style='font-weight:bold; font-size:12px;')
                    | INICIAR SESIÓN
                v-col(cols='12' style='text-align:center;')
                  a(@click='forgotPassDialog = true' style='color: rgb(80 89 99);') Olvidé la contraseña
                v-col(cols='7' style='text-align: end; padding-right:10px;')
                  p(style='color: #000000;')
                    | Aún no tienes una cuenta?
                v-col(cols='5' style='text-align: start; padding-left:10px')
                  a(@click='changeRegister(false)' style='color: rgb(80 89 99);') Regístrate
            v-card-actions(v-show='!register')
              v-row
                v-col(cols='12' style="display:flex; justify-content:center;")
                  v-btn(color='light-blue darken-3' @click='registrar' :loading='loading' dark='' style='font-weight:bold;') REGISTRARSE
                v-col(cols='12' style="display:flex; justify-content:center;")
                  a(style='color: blue; cursor: pointer;' @click="dialogTC = true")
                    | Términos y Condiciones
                v-col(cols='7' style='text-align: end;')
                  p(style='color: #000000;')
                    | Ya tienes una cuenta?
                v-col(cols='5' style='text-align: start;')
                  a(@click='changeRegister(true)' style='color: rgb(80 89 99);') Inicia sesión
            v-col(style="text-align: center;")
              v-btn(medium :loading='loading' style='background:transparent; border: 1px solid #1277bd; margin:0;' @click='goBackLanding()')
                v-icon(style='color:#1277bd;')
                  | mdi-keyboard-backspace
                span(style='padding-left:5px; font-size:12px;')
                  strong(style='color:#1277bd;')
                    | Regresar al inicio
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  import image from '@/assets/img/logo.png'
  var functions = require('../../functions/functions')

  export default {
    data: () => ({
      // variables para mostrar u ocultar las contraseñas
      dialogTC: false,
      loadingSendPass: false,
      banCode: true,
      showPassReg: false,
      showPassConfirm: false,
      showPassLogin: false,
      dialog: false,
      // confirmationDialogForGmail: false,
      // confirmationDialogForHotmail: false,
      // confirmationDialogVerify: false,
      imgLogo: image,
      emailToRecoverPass: '',
      forgotPassDialog: false,
      currentDay: '',
      loading: false,
      validLogin: true,
      validRegister: true,
      model: {
        grant_type: 'password',
        username: '',
        password: '',
      },
      modelRegister: {
        id: null,
        username: '',
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        persona: {
          id: null,
        },
        tipoCuenta: null,
      },
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      rules: {
        required: value => !!value || 'Campo requerido',
        min: v => v.length >= 8 || 'Mínimo 8 caracteres',
        minPassword: value => {
          // const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
          const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/
          return (
            pattern.test(value) ||
            'Min. 8 caracteres, 1 letra mayúscula, 1 minúscula y un número'
          )
        },
      },
      phoneRule: [
        v => !!v || 'Campo requerido',
        v => v.length === 10 || 'Debe tener 10 números',
      ],
      emailRules: [
        v => !!v || 'Correo requerido',
        v => /\S+@\S+\.\S+/.test(v) || 'Email inválido',
      ],
      passwordConfirm: '',
      register: true,
      accountType: [
        {
          name: 'personal',
          code: '001',
          plan: 'free',
          suscription: 'dynamic',
          suscriptionDate: new Date().toISOString().slice(0, 10),
        },
        {
          name: 'clinica',
          code: '002',
          plan: 'free',
          suscription: 'dynamic',
          suscriptionDate: new Date().toISOString().slice(0, 10),
        },
      ],
      carouselImages: [
        {
          src: require('@/assets/img/login-imgs/login8.png'),
        },
        {
          src: require('@/assets/img/login-imgs/login7.png'),
        },
        {
          src: require('@/assets/img/login-imgs/login13.png'),
        },
        {
          src: require('@/assets/img/login-imgs/login2.jpg'),
        },
      ],
    }),
    mounted () {
      this.$store.dispatch('getCurrentDay').then(res => {
        this.currentDay = res
      })
    },
    methods: {
      goBackLanding () {
        this.$router.replace('/')
      },
      changeAccount () {
        if (this.modelRegister.tipoCuenta.code === '001') {
          this.banCode = true
        } else {
          this.banCode = false
        }
      },
      // selectDialogToShow () {
      //   // proceso para mostrar los dialogos de verificacion para cuentas hotmail y gmail
      //   if (this.modelRegister.email.includes('gmail')) {
      //     this.confirmationDialogForGmail = true
      //   } else {
      //     this.confirmationDialogForHotmail = true
      //   }
      // },
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      // finishInit () {
      //   this.loading = false
      //   // this.confirmationDialogVerify = false
      //   firebase.auth().signOut()
      // },
      changeRegister (ban) {
        this.register = ban
      },
      async login (user, password) {
        if (user && password) {
          if (!this.$refs.formLogin.validate()) {
            return
          }
        }
        // se quitan espacios al final en caso de haber
        user = user.trim()
        password = password.trim()
        this.dialog = true
        this.loading = true
        this.$loading(true)

        try {
          const logged = await firebase.auth().signInWithEmailAndPassword(user, password)
          if (logged) {
            const currentUser = firebase.auth().currentUser
            this.$store.dispatch('users/getUserData', currentUser.uid).then(snapshotVal => {
              const randomCode = this.randomCode(30)
              this.$store.dispatch('users/setSesionUid', [randomCode, currentUser])
              localStorage.setItem('uidSesion', randomCode)
              const user = snapshotVal
              user.uid = currentUser.uid
              this.$store.dispatch('setLocalStorageData', { item: 'user', obj: user })
              this.$store.dispatch('users/setDataUser', user)
              const res = snapshotVal
              let name = null
              if (res) {
                name = {
                  firstName: res.firstName,
                  lastName: res.lastName,
                }
              } else {
                name = {
                  firstName: '',
                  lastName: '',
                }
              }
              localStorage.setItem('name', JSON.stringify(name))
              setTimeout(() => {
                location.reload(true)
              }, 1000)
            }).catch(() => {
              this.dialog = false
              this.loading = false
              this.$loading(false)
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al obtener los datos del usuario',
              }
            })
          }
        } catch (e) {
          this.dialog = false
          this.loading = false
          this.$loading(false)
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Revisa los datos ingresados o tu conexión de internet',
          }
        }
      },
      randomCode (length) {
        var result = ''
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        var charactersLength = characters.length
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
      },
      registrar () {
        if (this.$refs.formRegister.validate()) {
          if (this.passwordConfirm === this.modelRegister.password) {
            this.loading = true

            const passwordFac = functions.randomString(12, 16)
            this.modelRegister.email = this.modelRegister.email.trim()
            this.$store.dispatch('facturacion/adminLoginFac').then(res => {
              if (res) {
                let userSend = this.modelRegister.email
                if (userSend.length > 20) {
                  userSend = userSend.substr(0, 19).toLowerCase()
                }
                const dataUser = {
                  username: userSend,
                  password: passwordFac,
                  rol: 'ROLE_USER',
                  active: true,
                }
                const dataSend = {
                  body: dataUser,
                  token: res,
                }
                this.$store.dispatch('facturacion/createUser', dataSend).then(res => {
                  if (res) {
                    firebase.auth().createUserWithEmailAndPassword(this.modelRegister.email, this.modelRegister.password).then((user) => {
                      const accounts = []
                      if (!this.banCode) {
                        this.modelRegister.tipoCuenta.name = 'clinica/' + user.user.uid
                      }
                      accounts.push(this.modelRegister.tipoCuenta)
                      if (this.banCode) {
                        firebase.database().ref(`users/${user.user.uid}`).set({
                          email: this.modelRegister.email,
                          firstName: this.modelRegister.firstName,
                          lastName: this.modelRegister.lastName,
                          phoneNumber: this.modelRegister.phoneNumber,
                          create: this.currentDay,
                          status: true,
                          accounts: accounts,
                          selectAccount: accounts[0].name,
                          valuidfac: passwordFac,
                        })
                      } else {
                        firebase.database().ref(`users/${user.user.uid}`).set({
                          email: this.modelRegister.email,
                          firstName: this.modelRegister.firstName,
                          lastName: this.modelRegister.lastName,
                          phoneNumber: this.modelRegister.phoneNumber,
                          create: this.currentDay,
                          status: true,
                          accounts: accounts,
                          selectAccount: accounts[0].name,
                          rol: 'clinica',
                          valuidfac: passwordFac,
                        })
                      }
                    }, (error) => {
                      if (error.code === 'auth/email-already-in-use') {
                        this.snackbar = {
                          show: true,
                          color: 'red',
                          text: 'El correo que ingresó ya está registrado',
                        }
                      } else {
                        this.snackbar = {
                          show: true,
                          color: 'red',
                          text: 'Error al registrarse',
                        }
                      }
                      this.loading = false
                    }).then(() => {
                      this.login(this.modelRegister.email, this.modelRegister.password)
                    })
                  } else {
                    this.loading = false
                    this.$loading(false)
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de crear el usuario.',
                    }
                  }
                }, reject => {
                  if (reject) {
                    if (reject.response) {
                      if (reject.response.data) {
                        if (reject.response.data.result) {
                          if (reject.response.data.result.message) {
                            if (reject.response.data.result.message.includes('existe')) {
                              this.loading = false
                              this.$loading(false)
                              this.snackbar = {
                                show: true,
                                color: 'red',
                                text: 'Usuario ya registrado.',
                              }
                            } else {
                              this.loading = false
                              this.$loading(false)
                              this.snackbar = {
                                show: true,
                                color: 'red',
                                text: reject.response.data.result.message,
                              }
                            }
                          }
                        } else {
                          this.loading = false
                          this.$loading(false)
                          this.snackbar = {
                            show: true,
                            color: 'red',
                            text: 'Error al momento de crear el usuario.',
                          }
                        }
                      } else {
                        this.loading = false
                        this.$loading(false)
                        this.snackbar = {
                          show: true,
                          color: 'red',
                          text: 'Error al momento de crear el usuario.',
                        }
                      }
                    } else {
                      this.loading = false
                      this.$loading(false)
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Error al momento de crear el usuario.',
                      }
                    }
                  } else {
                    this.loading = false
                    this.$loading(false)
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de crear el usuario.',
                    }
                  }
                })
              } else {
                this.loading = false
                this.$loading(false)
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al momento de crear el usuario.',
                }
              }
            }, reject => {
              this.loading = false
              this.$loading(false)
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de crear el usuario.',
              }
            })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'La contraseña y su confirmación deben coincidir',
            }
          }
        }
      },
      forgotPass () {
        this.emailToRecoverPass = this.emailToRecoverPass.trim()
        this.loadingSendPass = true
        if (this.emailToRecoverPass !== '') {
          firebase.auth().sendPasswordResetEmail(this.emailToRecoverPass).then(() => {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Correo enviado con éxito',
            }
            firebase.auth().signOut()
            this.forgotPassDialog = false
            this.loadingSendPass = false
          }).catch(() => {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Su correo no está registrado',
            }
            this.loadingSendPass = false
          })
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'El campo correo no puede estar vacío',
          }
          this.loadingSendPass = false
        }
      },
      closeRecoverPass () {
        this.forgotPassDialog = false
        this.emailToRecoverPass = ''
      },
    },
  }
</script>

<style lang="css">
.v-btn.v-size--default{
  font-size: 1.2rem;;
}
  .v-snack__content{
    font-size: 1.4rem;
  }
  .inputs{
    font-family: 'ceraProRegular', sans-serif !important;
  }
  *{
    /* color: #ffff; */
    font-family: 'ceraProRegular', sans-serif !important;
    /* font-size:16px; */
  }
  p {
    color: #ffff;
    font-family: 'ceraProRegular', sans-serif !important;
    font-size:16px;
  }
  a {
    color: #ffff;
    font-family: 'ceraProRegular', sans-serif !important;
  }
  .v-select__selections {
    background: #d1d8e2;
  }
  .v-text-field > .v-input__control > .v-input__slot > .v-text-field__slot {
    background: #d1d8e2;
  }
  .div-login {
    max-width: 950px !important;
    padding-top: 30px !important;
  }
  .div-footer {
    background: none !important;
    padding: 17px 0px 0px 0px !important;
    background-color: transparent !important;
  }
  .v-application .primary.lighten-1 {
    background-color: transparent !important;
    border-color: transparent !important;
  }
  .split-bg {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
  }
  .v-card > *:first-child:not(.v-btn):not(.v-chip), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip) {
    padding-top:0px;
    padding-bottom:0px;
  }
</style>
