// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const configuraciones = {
  namespaced: true,
  state: {
    configs: {},
  },
  mutations: {
    SET_CONFIGS: (state, data) => {
      state.configs = data
    },
  },
  actions: {
    setConfig ({ commit, rootState }, data) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`configs/${rootState.users.user.uid}`).set(
          data,
        ).then(() => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    setTourConfig ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`configs/${rootState.users.user.uid}/showTour`).set(
          false,
        ).then(() => {
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    loadConfig ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`configs/${rootState.users.user.uid}`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              commit('SET_CONFIGS', res)
              resolve(res)
            } else {
              resolve(false)
            }
          }
        })
      })
    },
  },
}
