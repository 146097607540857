<template>
  <div>
    <v-dialog
      v-if="dialogCita"
      v-model="dialogCita"
      fullscreen=""
    >
      <v-card>
        <citas />
      </v-card>
    </v-dialog>
    <v-navigation-drawer
      id="core-navigation-drawer"
      v-model="drawer"
      :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
      :expand-on-hover="expandOnHover"
      :right="$vuetify.rtl"
      :src="barImage"
      mobile-breakpoint="960"
      app
      width="260"
      v-bind="$attrs"
    >
      <template v-slot:img="props">
        <v-img
          :gradient="`to bottom, ${barColor}`"
          v-bind="props"
        />
      </template>

      <!-- <v-divider class="mb-1" /> -->

      <v-list
        dense
        nav
      >
        <v-list-item>
          <v-list-item-avatar
            class="align-self-center"
            color="white"
            contain
          >
            <v-img
              src="@/assets/img/doc.jpg"
              max-height="auto"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title
              style="font-size:16px;"
              v-text="profile.title ? profile.title : ''"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider style="margin:0px;" />
      <v-progress-linear
        v-if="computedItems.length == 0"
        indeterminate=""
        color="primary"
      />

      <v-list
        expand
        nav
      >
        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />
        <template
          v-for="(item, i) in computedItems"
        >
          <base-item-group
            v-if="item.children"
            :key="`group-${i}`"
            :item="item"
          >
            <!--  -->
          </base-item-group>
          <base-item
            v-else
            :key="`item-${i}`"
            :item="item"
          />
        </template>

        <!-- Style cascading bug  -->
        <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
        <div />
      </v-list>
      <v-expansion-panels>
        <!-- <v-expansion-panel style="background: transparent;">
          <v-expansion-panel-header style="background-color: transparent;">
            <v-icon style="max-width: 50px;">
              mdi-file-document
            </v-icon>
            FACTURACIÓN
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list
              expand
              nav
            >
              <div />

              <template v-for="(item, i) in computedBillItems">
                <base-item-group
                  v-if="item.children"
                  :key="`group-${i}`"
                  :item="item"
                />

                <base-item
                  v-else
                  :key="`item-${i}`"
                  :item="item"
                />
              </template>

              <div />
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel> -->

        <v-expansion-panel
          v-if="banViewBilling"
          style="background: transparent;"
        >
          <v-expansion-panel-header style="background-color: transparent;">
            <v-icon style="max-width: 50px;">
              mdi-file-document
            </v-icon>
            FACTURACIÓN
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list
              expand
              nav
            >
              <div />

              <template v-for="(item, i) in computedBillingItems">
                <base-item-group
                  v-if="item.children"
                  :key="`group-${i}`"
                  :item="item"
                />

                <base-item
                  v-else
                  :key="`item-${i}`"
                  :item="item"
                />
              </template>

              <div />
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel style="background: transparent;">
          <v-expansion-panel-header style="background-color: transparent;">
            <v-icon style="max-width: 50px;">
              mdi-view-list
            </v-icon>
            GENERAL
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list
              expand
              nav
            >
              <div />

              <template v-for="(item, i) in computedGeneralItems">
                <base-item-group
                  v-if="item.children"
                  :key="`group-${i}`"
                  :item="item"
                />

                <base-item
                  v-else
                  :key="`item-${i}`"
                  :item="item"
                />
              </template>

              <div />
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <template v-slot:append>
        <base-item
          :item="{
            title: 'DUDAS Y SUGERENCIAS',
            icon: 'mdi-message-plus',
            to: '/dashboard/upgrade',
          }"
        />
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  // Utilities
  import {
    mapState,
  } from 'vuex'

  export default {
    name: 'DashboardCoreDrawer',
    components: {
      Citas: () => import('@/views/dashboard/pages/Citas'),
    },

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      banViewBilling: false,
      currentUserId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : '',
      admins: [
        'VgZkabGkHmYIKANZoWhjisX74VN2',
        'LdTIdTNZnOd26u6DamI0OvoBUhr1',
      ],
      dialogCita: false,
      expandMenuItems: [
        {
          icon: 'mdi-clipboard-check',
          title: 'Anamnesis',
          to: '/dashboard/pages/antecedentes',
        },
        {
          icon: 'mdi-medical-bag',
          title: 'Signos Vitales',
          to: '/dashboard/pages/signosvitales',
        },
        {
          icon: 'mdi-account-alert',
          title: 'Exámen Físico',
          to: '/dashboard/pages/examenfisico',
        },
        {
          icon: 'mdi-comment-question-outline',
          title: 'Preguntas Frecuentes',
          to: '/dashboard/pages/preguntasfrecuentes',
        },
        // {
        //   icon: 'mdi-file-document',
        //   title: 'Checklist',
        //   to: '/dashboard/pages/checklist',
        // },
      ],
      expandBillingMenuItems: [
        {
          icon: 'mdi-chart-line',
          title: 'Estadístico',
          to: '/dashboard/pages/estadistico',
        },
        {
          icon: 'mdi-folder',
          title: 'Perfil de Empresa',
          to: '/dashboard/pages/perfilempresa',
        },
        {
          icon: 'mdi-certificate',
          title: 'Certificado',
          to: '/dashboard/pages/certification',
        },
        {
          icon: 'mdi-account-multiple-plus',
          title: 'Clientes',
          to: '/dashboard/pages/clientes',
        },
        {
          icon: 'mdi-account-multiple',
          title: 'Proveedores',
          to: '/dashboard/pages/proveedores',
        },
        {
          title: 'IVA - Retenciones',
          icon: 'mdi-newspaper',
          to: '/pages/ivaretenciones',
        },
        {
          icon: 'mdi-view-list',
          title: 'Productos',
          to: '/dashboard/pages/productos',
        },
        {
          icon: 'mdi-file-document',
          title: 'Facturas',
          to: '/dashboard/pages/facturas',
        },
      ],
    }),

    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.$store.state.menus.lstMenus
      },
      computedGeneralItems () {
        return this.expandMenuItems.map(this.mapItem)
      },
      computedBillingItems () {
        return this.expandBillingMenuItems.map(this.mapItem)
      },
      profile () {
        const user = JSON.parse(localStorage.getItem('name'))
        const name = user.firstName + ' ' + user.lastName
        return {
          avatar: true,
          title: name,
        }
      },
      userData () {
        return this.$store.state.userData
      },
    },
    watch: {
      computedItems () {
        setTimeout(() => {
          // this.$tours.myTour.start()
        }, 500)
      },
      userData () {
        if (this.userData.billing) {
          this.banViewBilling = true
        } else {
          this.banViewBilling = false
        }
      },
    },
    mounted () {
      this.$store.dispatch('getLocalStorageData', 'user').then(res => {
        if (res.billing) {
          this.banViewBilling = true
        } else {
          this.banViewBilling = false
        }
      })
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: item.title,
        }
      },
    },
  }
</script>

<style lang="css">
@font-face {
  font-family: "ceraProBlack";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProRegular";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProMedium";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProBold";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Bold.otf');
  font-display: swap;
}
body {
  font-family: 'ceraProBlack', sans-serif !important;
}
.v-application--is-ltr .v-expansion-panel-header{
  font-size:1.4rem;
}
.v-navigation-drawer .v-list-item__content{
  font-family: 'ceraProRegular', sans-serif !important;
}
  .v-expansion-panel-content__wrap {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    max-width: 100%;
    padding: 0px 0px 0px 16px;
  }
  .v-navigation-drawer .v-list:not(.v-select-list) {
    background: inherit;
    /* padding: 0px; */
  }
  .v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child){
    text-decoration: none;
  }
</style>

<style lang="sass">
  @import '~vuetify/src/styles/tools/_rtl.sass'

  #core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
