<template lang="pug">
  v-app.split-bg.custom-font
    a.whatsappFloat(href='https://api.whatsapp.com/send/?phone=593988511884&text&app_absent=0' target='_blank')
      v-icon.my-whatsappFloat(large style='color: #ffffff;') mdi-whatsapp
    a.icon-bar-facebook.facebook(href='https://www.facebook.com/profile.php?id=100093184043434' target='_blank')
      v-icon.my-float(large style='color: #ffffff;') mdi-facebook
    a.icon-bar-instagram.instagram(href='https://www.instagram.com/dentalprimeapp/' target='_blank')
      v-icon.my-float(large style='color: #ffffff') mdi-instagram
    // =========================NAVIGATION LINKS==============================
    nav.navbar.custom-navbar.navbar-fixed-top(role='navigation' style='background-color: black; position: fixed; top: 0; width: 100%;')
      .container(style='padding:0; margin-right:5rem;')
        // navbar header
        .navbar-header
          button.navbar-toggle(data-toggle='collapse' data-target='.navbar-collapse')
            span.icon.icon-bar
            span.icon.icon-bar
            span.icon.icon-bar
          a.navbar-brand(href='#intro' v-smooth-scroll)
            img.imagen-logo-richi(src='@/assets/img-landing/logo.webp' width='70' height='70' alt='Logo' title='DentalPrime')
        .collapse.navbar-collapse
          ul.nav.navbar-nav.navbar-right
            li
              a(href='#intro' v-smooth-scroll) Inicio
            li
              a(href='#overview' v-smooth-scroll) &iquest;Qu&eacute; es?
            li
              a(href='#video' v-smooth-scroll) &iquest;C&oacute;mo funciona?
            li
              a(href='#precios' v-smooth-scroll) Precios
            li
              a(href='#register' v-smooth-scroll) Cont&aacute;ctos
            li.access-layout(style='display:flex;')
              a.font-weight-bold.cta-blue(@click='accessToApp()' style='font-size:16px; border-radius:20px; padding: 4px 20px 4px 20px; margin-top:9px;')
                | ACCEDER
    //- <!-- =========================INTRO SECTION============================== -->
    section#intro.parallax-section(style='margin-top:10px; background-position: 50% 0; min-height:100vh;')
      .container(style='margin-top:107px; margin-bottom:107px;')
        v-row
          v-col.intro-text(cols='12')
            h1.fadeInUp(v-wow='' data-wow-delay='0.1s' data-wow-duration='1s')
              | SOFTWARE ODONTOL&Oacute;GICO
            h2.fadeInUp.principal-name(v-wow='' data-wow-delay='0.1s' data-wow-duration='1s')
              | DENTALPRIME
            p.fadeInUp.principal-name(v-wow='' data-wow-delay='0.1s' data-wow-duration='1s')
              | Administra tu consultorio dental de forma r&aacute;pida y segura, agenda citas, registra pacientes con sus
              | tratamientos, crea proformas y mucho m&aacute;s.
            // <img src="images/logo.png" width="70" height="70">
            // <a href="#video" class="btn btn-lg btn-default smoothScroll wow fadeInUp hidden-xs" data-wow-delay="2.3s">VIDEO DEMO</a>
            a.btn.btn-lg.btn-danger.fadeInUp(v-wow='' v-smooth-scroll @click='accessToApp()' data-wow-delay='0.1s' data-wow-duration='1s')
              | DEMO GRATIS
    //- <!-- =========================OVERVIEW SECTION============================== -->
    section#overview.parallax-section
      .container
        .row.what-is
          .fadeInUp.col-md-6.col-sm-6(v-wow='' data-wow-delay='0.6s' data-wow-duration='1s')
            h2 &iquest;QU&Eacute; ES DENTALPRIME?
            p(style='text-align: justify;')
              | DentalPrime es un software en la nube que te permite administrar pacientes, doctores, agenda, odontograma,
              | tratamientos, procedimientos, ingresos y gastos. Adem&aacute;s puedes acceder desde cualquier dispositivo.
            p(style='text-align: justify;')
              | No hay una manera m&aacute;s f&aacute;cil y eficiente de administrar tu consultorio que con DentalPrime.
            //
              <p class="download-terms">
              <a href="others/TyC-dentalprime.pdf" download target="_blank">
              Descarga nuestros términos y condiciones
              </a>
              </p>
            a.btn.btn-lg.btn-danger.fadeInUp(v-wow='' v-smooth-scroll href='#video' data-wow-delay='2s' data-wow-duration='1s')
              | &iquest;C&Oacute;MO FUNCIONA?
          .fadeInUp.col-md-6.col-sm-6(v-wow='' data-wow-delay='0.9s' data-wow-duration='1s')
            img.img-responsive(src='@/assets/img-landing/login8.webp' alt='Imagen promocional')
    // =========================DETAIL SECTION==============================
    section#detail.parallax-section
      .container
        v-row
          v-col.icons-layout(cols='12' md='4')
            .fadeInLeft.dentists(v-wow='' data-wow-delay='0.1s' data-wow-duration='1s')
              img.img-responsive.mb-10(src='@/assets/img-landing/user-group-solid.png' alt='Imagen promocional' style='width:20%;')
              h3 Cientos de Odont&oacute;logos
              p(style='color: white;')
                | Ya disfrutan de los beneficios DentalPrime, y ayudan a la comunidad a crecer d&iacute;a a d&iacute;a para convertirse en
                | referente de toda Latinoam&eacute;rica.
          v-col.icons-layout(cols='12' md='4')
            .fadeInUp.support(v-wow='' data-wow-delay='0.1s' data-wow-duration='1s')
              img.img-responsive.mb-10(src='@/assets/img-landing/clock-solid.png' alt='Imagen promocional' style='width:15%;')
              h3 Est&aacute; activo 24/7
              p(style='color: white;')
                | Tu decides tu horario de consulta, por eso estamos activos cuando tu lo necesitas, un
                | verdadero asistente que nunca duerme.
          v-col.icons-layout(cols='12' md='4')
            .fadeInRight.microphone(v-wow='' data-wow-delay='0.1s' data-wow-duration='1s')
              img.img-responsive.mb-10(src='@/assets/img-landing/microphone-solid.png' alt='Imagen promocional' style='width:12%;')
              h3 Soporte Inmediato
              p(style='color: white;')
                | Nada de horarios de oficina para solventar tus problemas y dudas, con nuestra
                | asistencia online estamos junto a ti cuando mas lo requieras.
    // =========================VIDEO SECTION==============================
    section#video.parallax-section
      .container(style='display:flex; justify-content:center;')
        .row.video-section
          .fadeInUp.col-md-6.col-sm-10.video-section(v-wow='' data-wow-delay='1.3s' data-wow-duration='1s')
            h2 &iquest;C&Oacute;MO FUNCIONA DENTALPRIME?
            // <h3>DentalPrime es muy simple de utilizar, le invitamos a que observe el video demo de su funcionamiento.</h3>
            p(style='text-align: justify;')
              | Mira todas las novedades y beneficios que te ofrece DentalPrime, disfruta de un sistema de control de
              | pacientes totalmente automatizado, mant&eacute;n tu
              | d&iacute;a organizado con la agenda virtual, un listado completamente personalizado de todos tus procedimientos,
              | tratamientos y mucho m&aacute;s desde tu dispositivo favorito.
            a.btn.btn-lg.btn-danger.fadeInUp(v-wow='' v-smooth-scroll @click='accessToApp()' data-wow-delay='2.3s' data-wow-duration='1s')
              | REG&Iacute;STRATE GRATIS
          .fadeInUp.col-md-6.col-sm-12(v-wow='' data-wow-delay='1.6s' data-wow-duration='1s')
            .embed-responsive.embed-responsive-16by9
              iframe.embed-responsive-item.video-element(src='https://www.youtube.com/embed/JeFDKsSLoTs')
    // =========================FAQ SECTION==============================
    section#faq.parallax-section
      .container
        .row.faq-section
          .fadeInUp(v-wow='' data-wow-delay='0.9s' data-wow-duration='1s')
            h2.faq PREGUNTAS FRECUENTES
            h3
              strong &iquest;Qu&eacute; equipo o dispositivo necesito para ingresar a la app?
            p
              | Puedes usar cualquier equipo o dispositivo m&oacute;vil que tenga conexi&oacute;n a internet, DentalPrime se adapta a
              | cualquier dispositivo y tama&ntilde;o de pantalla.
            br
            h3
              strong
                | &iquest;Necesito instalar alguna aplicaci&oacute;n desde la tienda virtual o desde la web?
            p
              | No necesitas instalar nada, solo debes acceder a la p&aacute;gina https://dentalprimeapp.com desde tu navegador.
            br
            h3
              strong Si pierdo mi contrase&ntilde;a &iquest;C&oacute;mo podr&iacute;a recuperarla?
            p
              | Claro que s&iacute;, solo debe presionar el bot&oacute;n &ldquo;Olvide mi Contrase&ntilde;a&rdquo;, despu&eacute;s ingresar tu correo y te
              | enviaremos toda la informaci&oacute;n que necesitas y as&iacute; poder ingresar a tu plataforma DentalPrime.
            br
            h3
              strong Si cancelo mi suscripci&oacute;n, &iquest;Pierdo los datos de mis pacientes?
            p
              | Los datos de tus clientes permanecer&aacute;n en nuestros servidores y no se perder&aacute;n, cuando vuelvas a Dental
              | Prime encontrar&aacute;s todo como lo dejaste.
            //- // <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingOne">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
            //- // ¿Cómo puedo saber si dentalprime me ofrece lo que necesito??
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            //- // <div class="panel-body">
            //- // <p>En el siguiente enlace puedes acceder a un DEMO completamente gratuito para probar los servicios que ofrecemos: <a href="https://dentalprimeapp.com" target='_blank'>DentalPrime</a>
            //- // </p>
            //- // </div>
            //- // </div>
            //- // </div>
            //- //
            //-   <div class="panel panel-default">
            //-   <div class="panel-heading" role="tab" id="headingTwo">
            //-   <h4 class="panel-title">
            //-   <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            //-   Ya uso otro sistema para gestionar mis pacientes pero me gustaría innovar y automatizar el proceso ¿cómo DentalPrime me podría ayudarme a realizar la migración disminuyendo el proceso de errores o perdida de información?
            //-   </a>
            //-   </h4>
            //-   </div>
            //-   <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
            //-   <div class="panel-body">
            //-   <p>No, el sistema que usas actualmente posee un sistema de exportación de datos, podrías usarlo para exportar los datos que requieras ya DentalPrime posee un sistema de importación que puedas importar todos los datos de los pacientes de manera automática.Sin embargo en caso de que el sistema que usas actualmente no te permite exportar los datos DentalPrime posee un equipo dedicado a ayudarte a hacer la migración progresiva o inmediata el cual tiene un costo adicional en caso de ser requerido.</p>
            //-   </div>
            //-   </div>
            //-   </div>
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingOne">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
            //- // ¿Si existe una actualización de precios del plan que adquirí, yo tendría que también acoplarme a ese cambio de precio?
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapseThree" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            //- // <div class="panel-body">
            //- // <p>No, el precio por el que adquiriste la suscripción, siempre será el mismo a no ser que cambies de plan o que lo canceles.</p>
            //- // </div>
            //- // </div>
            //- // </div>
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingOne">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
            //- // ¿Qué equipo y de que características necesito para usar la app?
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapseFour" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            //- // <div class="panel-body">
            //- // <p>Para usar DentalPrime solo necesitas un ordenador con conexión a internet.</p>
            //- // </div>
            //- // </div>
            //- // </div>
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingOne">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse5" aria-expanded="false" aria-controls="collapse5">
            //- // ¿Necesito instalar programas para poder usar la DentalPrime?
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapse5" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            //- // <div class="panel-body">
            //- // <p> No, puedes usar DentalPrime desde tu navegador preferido, aunque recomendamos Google Chrome.</p>
            //- // </div>
            //- // </div>
            //- // </div>
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingOne">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse6" aria-expanded="false" aria-controls="collapse6">
            //- // ¿Solo puedo acceder desde mi ordenador a DentalPrime?
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapse6" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            //- // <div class="panel-body">
            //- // <p>No, puedes acceder también desde cualquier dispositivo móvil a través de su navegador.</p>
            //- // </div>
            //- // </div>
            //- // </div>
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingOne">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse7" aria-expanded="false" aria-controls="collapse7">
            //- // ¿Qué pasa si pierdo mi contraseña?
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapse7" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            //- // <div class="panel-body">
            //- // <p>Puedes presionar en “Olvidé mi contraseña”, ingresa tu correo y te
            //- // enviaremos la información necesaria a tu correo para que puedas acceder a tu cuenta.</p>
            //- // </div>
            //- // </div>
            //- // </div>
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingThree">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse8" aria-expanded="false" aria-controls="collapse8">
            //- // ¿Si pierdo o se me daña mi ordenador, pierdo toda la información de mis pacientes?
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapse8" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
            //- // <div class="panel-body">
            //- // <p>No, el sistema de almacenamiento de DentalPrime se respalda en la nube, por lo cual podrás acceder a tus datos actualizados desde cualquier lugar y si pierdes o se te daña tu ordenador, no perderás nada de información ya que todo se encuentra almacenado en al nube.</p>
            //- // </div>
            //- // </div>
            //- // </div>
            //- // <div class="panel panel-default">
            //- // <div class="panel-heading" role="tab" id="headingThree">
            //- // <h4 class="panel-title">
            //- // <a class="collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapse9" aria-expanded="false" aria-controls="collapse9">
            //- // ¿Qué pasará con todos los datos de mis pacientes si necesito cancelar mi suscripción?
            //- // </a>
            //- // </h4>
            //- // </div>
            //- // <div id="collapse9" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
            //- // <div class="panel-body">
            //- // <p>DentalPrime te permite exportar los datos de tus pacientes cuando lo requieras, por lo cual, previo a cancelar tu suscripción podrás exportar los datos que requieras para usarlos fuera de la app.</p>
            //- // </div>
            //- // </div>
            //- // </div>
    // =========================PRECIOS==============================
    section#precios
      .container
        h2(style='text-align: center; padding-bottom: 50px;')
          | ELIJE UN PLAN A TU MEDIDA
        v-row
          .snip1214
            v-col(cols='12' md='3').plan
              h3.plan-title Mensual
              .plan-cost
                span.plan-price $26.07
                span.plan-type / Mensual
              ul.plan-features
                li
                  i.ion-checkmark
                  | Base de datos en tiempo real
                li
                  i.ion-checkmark
                  | Respaldos diarios
                li
                  i.ion-checkmark
                  | Soporte t&eacute;cnico 24/7
                li
                  i.ion-checkmark
                  | Facturación electrónica
              a.font-weight-bold.btn.btn-lg.cta-prices(@click='accessToApp()')
                | PRUEBA GRATIS
              // <div class="plan-select"><a href="">Select Plan</a></div>
            v-col(cols='12' md='3').plan
              h3.plan-title Semestral
              .plan-cost
                span.plan-price $115.00
                span.plan-type / Semestral
              ul.plan-features
                li
                  i.ion-checkmark
                  | Base de datos en tiempo real
                li
                  i.ion-checkmark
                  | Respaldos diarios
                li
                  i.ion-checkmark
                  | Soporte t&eacute;cnico 24/7
                li
                  i.ion-checkmark
                  | Escalamiento autom&aacute;tico
                li
                  i.ion-checkmark
                  | Facturación electrónica
              a.font-weight-bold.btn.btn-lg.cta-prices(@click='accessToApp()')
                | PRUEBA GRATIS
            v-col(cols='12' md='3').plan.featured
              h3.plan-title Anual
              .plan-cost
                span.plan-price $172.50
                span.plan-type / Anual
              ul.plan-features
                li
                  i.ion-checkmark
                  | Base de datos en tiempo real
                li
                  i.ion-checkmark
                  | Respaldos diarios
                li
                  i.ion-checkmark
                  | Soporte t&eacute;cnico 24/7
                li
                  i.ion-checkmark
                  | Escalamiento autom&aacute;tico
                li
                  i.ion-checkmark
                  | Primeros en probar nuevas car&aacute;cteristicas y mejoras
                li
                  i.ion-checkmark
                  | Facturación electrónica
              a.font-weight-bold.btn.btn-lg.cta-prices(@click='accessToApp()')
                | PRUEBA GRATIS
            v-col(cols='12' md='3').plan
              h3.plan-title Personalizado
              .plan-cost
                span.plan-price Un plan especial para ti
              ul.plan-features
                li
                  i.ion-checkmark
                  | Base de datos en tiempo real
                li
                  i.ion-checkmark
                  | Respaldos diarios
                li
                  i.ion-checkmark
                  | Soporte t&eacute;cnico 24/7
                li
                  i.ion-checkmark
                  | Escalamiento autom&aacute;tico
                li
                  i.ion-checkmark
                  | Primeros en probar nuevas car&aacute;cteristicas y mejoras
                li
                  i.ion-checkmark
                  | Ajustamos el programa a sus necesidades espec&iacute;ficas
                li
                  i.ion-checkmark
                  | Facturación electrónica
              a.font-weight-bold.btn.btn-lg.cta-prices(href='https://api.whatsapp.com/send/?phone=593988511884&text&app_absent=0' target='_blank')
                | CONTÁCTANOS
    // =========================REGISTER SECTION==============================
    section#register.parallax-section
      .container
        .row
          .fadeInUp.col-md-7.col-sm-7.contact-info(v-wow='' data-wow-delay='0.6s' data-wow-duration='1s')
            h2 &iquest;NECESITAS M&Aacute;S INFORMACI&Oacute;N?
            br
            h4.cities-contact Cuenca &nbsp; &nbsp;
            a.fa.whatsapp-icon(v-wow='' href='https://api.whatsapp.com/send/?phone=593988511884&text&app_absent=0' target='_blank' data-wow-delay='1.6s' data-wow-duration='1s')
              v-icon(large style='color: #22cd62;') mdi-whatsapp
            h4 Yanahurco 4-91 y Rio Upano
            h4 Tel: 0988511884
            br
            h4.cities-contact Machala &nbsp; &nbsp;
            a.fadeInUp(v-wow='' href='https://api.whatsapp.com/send/?phone=%2B593984530998&text&app_absent=0' target='_blank' data-wow-delay='1.6s' data-wow-duration='1s')
              v-icon(large style='color: #22cd62;') mdi-whatsapp
            //- a.fa.fa-whatsapp.fadeInUp.whatsapp-icon(v-wow='' href='https://api.whatsapp.com/send/?phone=%2B593984530998&text&app_absent=0' target='_blank' data-wow-delay='1.6s' data-wow-duration='1s')
            h4 Tel: 0984530998
            br
            h4.cities-contact Quito &nbsp; &nbsp;
            a.fa.whatsapp-icon(v-wow='' href='https://api.whatsapp.com/send/?phone=593990793760&text&app_absent=0' target='_blank' data-wow-delay='1.6s' data-wow-duration='1s')
              v-icon(large style='color: #22cd62;') mdi-whatsapp
            h4 Tel: 0990793760
          .fadeInUp.col-md-5.col-sm-5(v-wow='' data-wow-delay='1s' data-wow-duration='1s')
            form
              input#name.form-control(name='name' type='text' placeholder='Nombre')
              input#email.form-control(name='email' type='email' placeholder='Email')
              input#telefono.form-control(name='telefono' type='telefono' placeholder='Teléfono')
              textarea#message.form-control(name='message' rows='5' placeholder='Mensaje')
              .col-md-6.col-sm-10.send-info
                button.form-control(@click='sendInfoToContact()' type='button') ENVIAR
                // <button onclick="writeData()">Enviar</button>
          .col-md-1
    // =========================FOOTER SECTION==============================
    footer
      //- .row
      //-   .col-md-12.col-sm-12
      v-row(style='justify-content:center; width:100%;')
        ul.social-icon
          li
            a.fadeInUp(v-wow='' href='https://www.facebook.com/j2rtech' target='_blank' data-wow-delay='1s' data-wow-duration='1s')
              v-icon(large) mdi-facebook
          li
            a.fadeInUp(v-wow='' href='https://www.instagram.com/dentalprimeapp/' target='_blank' data-wow-delay='1s' data-wow-duration='1s')
              v-icon(large) mdi-instagram
          li
            a.fadeInUp(v-wow='' href='https://api.whatsapp.com/send/?phone=%2B593984530998&text&app_absent=0' target='_blank' data-wow-delay='1s' data-wow-duration='1s')
              v-icon(large) mdi-whatsapp
      v-row(style='justify-content:center; width:100%;')
        p.fadeInUp(v-wow='' data-wow-delay='0.6s' data-wow-duration='1s' style='color:#757575')
          | Copyright &copy; 2022 Inventarix. Desarrollado por &nbsp
          a.productBy(href='https://j2rtech.com/' target='_blank') J2R TECH S.A.S.
</template>

<script>
  // import AosVue from 'aos-vue'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  // import moment from 'moment'
  // var functions = require('../../functions/functions')

  export default {
    // components: { AosVue },
    data: () => ({
      contactInfo: {
        contactName: '',
        contactEmail: '',
        contactMessage: '',
      },
    }),
    methods: {
      accessToApp () {
        this.$router.push('/login')
      },
      sendInfoToContact () {
        firebase.database().ref('landingContact/').push(this.contactInfo).then((res) => {
          alert('Sus datos fueron recibidos por el equipo de Inventarix, en breve nos comunicaremos con usted')
        }).catch((err) => {
          alert(err)
        })
      },
    },
  }
</script>

<style lang="scss">
@keyframes fadeInLeft {
   0% {
      opacity: 0;
      transform: translateX(-20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
}
@keyframes fadeInRight {
   0% {
      opacity: 0;
      transform: translateX(20px);
   }
   100% {
      opacity: 1;
      transform: translateX(0);
   }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 30%, 0);
    -ms-transform: translate3d(0, 30%, 0);
    transform: translate3d(0, 30%, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
  }
}

.fadeInUp {
  animation-name: fadeInUp;
  animation-timing-function: ease-out;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'ceraProRegular', sans-serif !important;
}

p {
  font-size: 1.6rem;
  font-family: "ceraProMedium", sans-serif !important;
}

// body {
//   background: #ffffff;
//   font-family: 'Poppins', sans-serif;
//   font-style: normal;
//   font-weight: 400;
// }

/*---------------------------------------
    Typorgraphy
-----------------------------------------*/
@font-face {
  font-family: "ceraProBlack";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProRegular";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProMedium";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProBold";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Bold.otf');
  font-display: swap;
}
body {
  font-family: 'ceraProBlack', sans-serif !important;
}
h1 {
  font-family: 'ceraProBlack', sans-serif !important;
}
h4 {
  font-family: 'ceraProBlack', sans-serif !important;
  // font-size: 24px;
}
h2 {
  font-family: 'ceraProBlack', sans-serif !important;
  // font-size: 32px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h3,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 4rem;
  letter-spacing: 2px;
}

h2 {
  font-size: 3rem;
  text-transform: uppercase;
}

h3 {
  font-size: 2rem;
  line-height: 36px;
}

h5 {
  letter-spacing: 1px;
  padding-top: 10px;
}

p {
  color: #707070;
  font-size: 1.6rem;
  line-height: 24px;
}

a {
  text-decoration: none !important;
  outline: none;
}

/*---------------------------------------
    General
-----------------------------------------*/

/* para el boton flotante */
.whatsappFloat {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-content: center;
}

.my-float {
  margin: 0;
}

.icon-bar-facebook {
  position: fixed;
  top: 90%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99999;
}

.icon-bar-facebook a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar-facebook a:hover {
  background-color: #000;
}

.icon-bar-instagram {
  position: fixed;
  top: 84.5%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 99999;
}

.icon-bar-instagram a {
  display: block;
  text-align: center;
  padding: 16px;
  transition: all 0.3s ease;
  color: white;
  font-size: 20px;
}

.icon-bar-instagram a:hover {
  background-color: #000;
}

.facebook {
  background: #3B5998;
  color: white;
}

.instagram {
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  color: white;
}

.cta-blue {
  background: #1491ff;
  color: #ffff !important;
  border: 2px solid #1491ff;
  width:120px;
}

.cta-prices{
  color: white !important;
  background:#1391ff;
  margin: 3rem 0;
  font-size: 1.5rem;
}

.access-layout{
  display:flex;
  justify-content: center;
  align-items:center;
}

.video-element {
  border-radius: 20px;
}

.video-section p {
  margin-bottom: 20px;
}

.img-responsive {
  padding: 0;
  border-radius: 20px;
}

.cities-contact {
  display: inline;
}

.whatsapp-icon {
  color: green;
  font-size: 30px;
}

.contact-info {
  text-align: left;
}

.section-title {
  margin-bottom: 32px;
}

.faq {
  text-align: center;
}

.productBy{
  color: #008cff !important;
}

#overview,
#detail,
#video,
#register {
  padding: 20rem 0;
}

#faq {
  padding: 10rem 0;
}

/* #detail, */
/* #video, */
#speakers,
#program,
#sponsors,
#contact,
footer {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#detail,
#speakers,
#sponsors,
footer {
  text-align: center;
}

#overview h3,
#detail h3,
#video h3,
#register h3,
#venue h3,
#contact h3 {
  padding-bottom: 12px;
}

#intro .btn,
#video .btn,
#overview .btn,
#contact .btn {
  /* background: transparent; */
  /* border: 2px solid #ffffff; */
  /* border-radius: 0px; */
  color: #ffffff;
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 1.4rem;
  padding: 14px 42px;
  /* margin-right: 12px; */
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.principal-name {
  color: #4bb1ff;
  font-size: 4rem;
}

#intro .intro-text {
  padding: 0;
}

.intro-text p {
  width: 70%;
  margin: 60px auto;
  color: #dfdcdc;
  font-size: 2.2rem;
  line-height: 24px;
}

// .btn-danger {
//   background: #0091ff;
//   border-color: #0091ff;
//   color: #0091ff;
// }

#overview .btn-danger,
#video .btn-danger {
  background: #0091ff;
  border-color: transparent;
  margin: 20px 0px 20px 0px;
}

#overview .btn-danger,
#intro .btn-danger,
#contact .btn-danger {
  background: #0091ff;
  border-color: transparent;
}

#contact .btn {
  border-radius: 100px;
}

#overview .btn-danger:hover,
#video .btn-danger:hover {
  background: transparent;
  border-color: #0091ff;
  color: #0091ff;
}

#intro .btn-danger:hover {
  background: transparent;
  border-color: #ffffff;
  color: #ffffff;
}

#contact .btn-danger:hover {
  background: #222;
}

#video .btn-default:hover,
#intro .btn-default:hover {
  background: #ffffff;
  color: #333333;
  border-color: transparent;
}

.parallax-section {
  background-attachment: fixed !important;
  background-size: cover !important;
}

.icons-layout{
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*---------------------------------------
    Preloader section
-----------------------------------------*/
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  background: none repeat scroll 0 0 #ffffff;
}

.sk-rotating-plane {
  width: 50px;
  height: 50px;
  background-color: #222;
  -webkit-animation: sk-rotatePlane 1.2s infinite ease-in-out;
  animation: sk-rotatePlane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes sk-rotatePlane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*---------------------------------------
    Navigation Links section
-----------------------------------------*/
.custom-navbar {
  margin-bottom: 0;
  background-color: #101010;
}

.custom-navbar .navbar-brand {
  color: #ffffff;
  font-weight: 600;
  font-size: 3rem;
  line-height: 40px;
}

.custom-navbar .nav li a {
  color: #ddd;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  line-height: 40px;
  transition: all 0.4s ease-in-out;
}

.custom-navbar .nav li a:hover {
  background: transparent;
  color: #66ccff;
}

.custom-navbar .navbar-nav > li > a:hover,
.custom-navbar .navbar-nav > li > a:focus {
  background-color: transparent;
}

.custom-navbar .nav li.active > a {
  background-color: transparent;
  color: #66ccff;
}

.custom-navbar .navbar-toggle {
  border: none;
  padding-top: 10px;
}

.custom-navbar .navbar-toggle {
  background-color: transparent;
}

.custom-navbar .navbar-toggle .icon-bar {
  background: #ffffff;
  border-color: transparent;
}

.what-is p {
  color: black;
}

.video-section p {
  color: black;
}

.faq-section p{
  color: black;
}

@media (min-width: 900px) {
  .what-is {
    display: flex;
    align-items: center;
  }
  .custom-navbar {
    border-bottom: 0;
    background: 0 0;
  }
  .custom-navbar.top-nav-collapse {
    background: #101010;
    padding: 0;
  }
}

/*---------------------------------------
    Intro section
-----------------------------------------*/
#intro {
  background: url('~@/assets/img-landing/intro-bg.jpg') 50% 0 repeat-y fixed;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  color: #ffffff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

#intro h3 {
  letter-spacing: 2px;
}

/*---------------------------------------
   Overview  section
-----------------------------------------*/
/* #overview img {
  position: relative;
} */

/*---------------------------------------
    Detail section
-----------------------------------------*/
#detail {
  background: #222;
  color: #ffffff;
}

.dentists{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.support{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.microphone{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#detail .fa {
  color: #f2545f;
  font-size: 4.8rem;
}

/*---------------------------------------
    Video section
-----------------------------------------*/
#video iframe {
  border: none;
}

/*---------------------------------------
   Speakers section
-----------------------------------------*/
#speakers {
  background: #f9f9f9;
}

#speakers h3 {
  font-size: 1.8rem;
  margin-bottom: 0px;
}

#speakers h6 {
  color: #666;
  margin-top: 4px;
}

#speakers img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

#speakers .col-md-3 {
  display: block;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 20px;
  margin-bottom: 22px;
}

#speakers .speakers-wrapper {
  background: #ffffff;
  padding-bottom: 22px;
}

/*---------------------------------------
   Program section
-----------------------------------------*/
#program h4 {
  color: #808080;
  font-size: 1.2rem;
}

#program h3 {
  margin-top: 16px;
}

#program .program-divider {
  border: 1px solid #f9f9f9;
  margin-top: 32px;
  margin-bottom: 42px;
}

#program span {
  padding-right: 12px;
}

#program img {
  border-radius: 50%;
}

.nav-tabs {
  margin-bottom: 20px;
}
.nav-tabs > li {
  float: left;
  margin-bottom: -1px;
}
.nav-tabs > li > a {
  color: #000000;
  font-weight: 600;
  margin-right: 2px;
  line-height: 1.42857143;
  border: none;
  border-radius: 0px;
}
.nav-tabs > li > a:hover {
  background-color: transparent;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #f2545f;
  cursor: default;
  background-color: transparent;
  border: 1px solid transparent;
  border-bottom-color: #999;
}
.tab-content {
  padding-top: 20px;
}

/*---------------------------------------
    Regsiter section
-----------------------------------------*/
#register {
  background: url('~@/assets/img-landing/register-bg.jpg') 50% 0 repeat-y fixed;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
  color: #ffffff;
}

#register .form-control {
  background: transparent;
  border: 2px solid #ffffff;
  border-radius: 0px;
  color: #ffffff;
  margin-bottom: 16px;
}

#register input {
  height: 45px;
}

#register input[type='submit'] {
  background: #f2545f;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  letter-spacing: 2px;
  height: 50px;
  margin-top: 12px;
  transition: all 0.4s ease-in-out;
}

#register input[type='submit']:hover {
  background: #ffffff;
  color: #222;
}

/*---------------------------------------
   Precios section
-----------------------------------------*/
#precios {
  background: #f9f9f9;
  padding-top: 8rem;
  padding-bottom: 8rem;
}

.container {
  text-align: center;
}

.download-terms {
  text-align: left;
}

#precios .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container h2 {
  margin-bottom: 20px;
}

@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);
#precios .snip1214 {
  // font-family: 'Poppins', sans-serif;
  color: #000000;
  text-align: center;
  font-size: 1.6rem;
  width: 100%;
  max-width: 1000px;
  margin: 40px 10px;
}
#precios .snip1214 .plan {
  margin: 0;
  width: 25%;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}
#precios .snip1214 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
#precios .snip1214 header {
  position: relative;
}
#precios .snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}
#precios .snip1214 .plan-cost {
  padding: 0px 10px 20px;
}
#precios .snip1214 .plan-price {
  // font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 2.4rem;
  color: #34495e;
}
#precios .snip1214 .plan-type {
  opacity: 0.6;
}
#precios .snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 1.6rem;
}
#precios .snip1214 .plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
#precios .snip1214 .plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
}
#precios .snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}
#precios .snip1214 .plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;
}
#precios .snip1214 .plan-select a {
  background-color: #222f3d;
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
}
#precios .snip1214 .plan-select a:hover {
  background-color: #46627f;
}
#precios .snip1214 .featured {
  margin-top: -10px;
  background-color: #34495e;
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
#precios .snip1214 .featured .plan-title,
#precios .snip1214 .featured .plan-price {
  color: #ffffff;
}
#precios .snip1214 .featured .plan-cost {
  padding: 10px 10px 20px;
}
#precios .snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
#precios .snip1214 .featured .plan-select {
  padding: 20px 10px 100px;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 100%;
  }
  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 20px 10px 20px;
  }
  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .snip1214 .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
  h1 {
    font-size: 2.7rem;
  }
  .principal-name {
    font-size: 2.5rem;
  }
}

/*---------------------------------------
   Venue section
-----------------------------------------*/
#venue {
  background: url('~@/assets/img-landing/venue-bg.jpg') 50% 0 repeat-y fixed;
  background-size: cover;
  background-position: center center;
  padding-top: 7rem;
  padding-bottom: 7rem;
}

#venue p {
  padding-top: 12px;
  padding-bottom: 18px;
}

/*---------------------------------------
   Sponsors section
-----------------------------------------*/
#sponsors img {
  margin-top: 18px;
}

/*---------------------------------------
   Contact section
-----------------------------------------*/
#contact {
  background: url('~@/assets/img-landing/contact-bg.jpg') 50% 0 repeat-y fixed;
  background-size: cover;
  background-position: center center;
}

#contact .contact_des {
  padding-top: 3rem;
  padding-right: 2rem;
}

#contact .contact_detail {
  background: #ffffff;
  padding: 5rem 5rem 12rem 5rem;
}

#contact .form-control {
  background: transparent;
  border: none;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 16px;
  transition: all 0.4s ease-in-out;
}

#contact .form-control:focus {
  border-bottom-color: #999;
}

.send-info {
  padding: 0;
}

#contact input {
  height: 45px;
}

#contact input[type='submit'] {
  background: #222;
  border-radius: 100px;
  border: none;
  color: #ffffff;
  letter-spacing: 2px;
  height: 50px;
  margin-top: 12px;
  transition: all 0.4s ease-in-out;
}

#contact input[type='submit']:hover {
  background: #5498f2;
}

/*---------------------------------------
   Footer section
-----------------------------------------*/
footer {
  background-attachment: fixed;
}

/* Back top */
.go-top {
  background-color: #222;
  bottom: 2em;
  right: 2em;
  color: #ffffff;
  font-size: 3.2rem;
  display: none;
  position: fixed;
  text-decoration: none;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  transition: all 0.4s ease-in-out;
}

.go-top:hover {
  background: #5498f2;
  color: #ffffff;
}

/*---------------------------------------
   Social icon
-----------------------------------------*/
.social-icon {
  // position: relative;
  padding: 0;
  margin: 0;
}

.social-icon li {
  display: inline-block;
  list-style: none;
}

.social-icon li a {
  color: #666;
  border-radius: 100px;
  font-size: 1.6rem;
  text-decoration: none;
  transition: all 0.4s ease-in-out;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  vertical-align: middle;
}

// .social-icon li a:hover {
//   background: #5498f2;
//   color: #ffffff;
// }

/*---------------------------------------
   Responsive styles
-----------------------------------------*/
@media (min-width: 990px) {
  .video-section p {
    margin-bottom: 30px;
  }
  #video .row {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 990px) {
  .video-section {
    width: 100%;
  }

  #video .btn-danger {
    margin-bottom: 50px;
  }

  /* .video-section p {
    margin-bottom: 50px;
  } */

  #intro {
    height: 100vh;
  }

  p {
    font-size: 1.6rem;
  }

  .custom-navbar .navbar-brand {
    font-size: 2rem;
  }

  .custom-navbar .nav li a {
    font-size: 1.5rem;
    line-height: 20px;
  }

  /* #video iframe {
    margin-top: 42px;
  } */

  #contact .contact_detail {
    background: #ffffff;
    padding: 10rem 3rem 10rem 5rem;
  }
}

.imagen-logo-richi {
  position: absolute;
  top: 0px;
  left: 50px;
  width: 70px;
  height: 70px;
}

@media (max-width: 900px) {
  .contact-info {
    text-align: center;
  }
  .custom-navbar .navbar-brand {
    line-height: 20px;
  }

  #overview img,
  #detail .col-md-4,
  #register form {
    margin-top: 32px;
    border-radius: 20px;
  }

  .nav-tabs > li > a {
    font-size: 1.3rem;
  }

  #program img {
    padding-bottom: 32px;
  }

  #sponsors img {
    padding-top: 22px;
  }

  #contact .contact_detail {
    margin-top: 82px;
  }

  #precios .snip1214 .plan {
    margin: 0;
    width: 100%;
    position: relative;
    float: left;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }

  #precios .snip1214 .featured {
    margin-top: -10px;
    background-color: #34495e;
    color: #ffffff;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    z-index: 1;
  }

  .go-top {
    bottom: 10px;
    right: 10px;
    z-index: 999;
  }

  .imagen-logo-richi {
    position: absolute;
    top: 0px;
    left: 30px;
    width: 50px;
    height: 50px;
  }
}
@media (min-width: 768px) and (max-width: 1000px) {
   .collapse {
       display: none !important;
   }
}

@media (min-width: 1110px) {
  .cta-blue{
    margin-left: 80px;;
  }
}

/* codigo para cambiar el breakpoint del navbar para bootstrap 3.x */
/* @media (max-width: 992px) {
  .navbar-header {
    float: none;
  }
  .navbar-left,
  .navbar-right {
    float: none !important;
  }
  .navbar-toggle {
    display: block;
  }
  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
    display: none !important;
  }
  .navbar-nav {
    float: none !important;
    margin-top: 7.5px;
  }
  .navbar-nav > li {
    float: none;
  }
  .navbar-nav > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .collapse.in {
    display: block !important;
  }
} */

</style>
