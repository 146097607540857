// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const transaction = {
  namespaced: true,
  state: {
    lstTransactions: [],
    lstTransactionsCombos: [],
  },
  mutations: {
    LOAD_TRANSACTIONS: (state, data) => {
      state.lstTransactions = data
    },
    LOAD_TRANSACTIONS_COMBOS: (state, data) => {
      state.lstTransactionsCombos = data
    },
  },
  actions: {
    saveTransaction ({ rootState }, transactionData) {
      return new Promise((resolve, reject) => {
        transactionData.documentId = 'Sin valor tributario'
        firebase.database().ref(`transactions/${rootState.users.user.uid}/${transactionData.productId}`).push(transactionData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadTransactions ({ commit, rootState }, productIdSelected) {
      firebase.database().ref(`transactions/${rootState.users.user.uid}/${productIdSelected}`).once('value', (snapshot) => {
        if (snapshot) {
          let arr = []
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('LOAD_TRANSACTIONS', arr)
          } else {
            commit('LOAD_TRANSACTIONS', [])
          }
        } else {
          commit('LOAD_TRANSACTIONS', [])
        }
      })
    },
    saveTransactionCombo ({ rootState }, transactionData) {
      return new Promise((resolve, reject) => {
        transactionData.documentId = 'Sin valor tributario'
        firebase.database().ref(`transactionscombos/${rootState.users.user.uid}/${transactionData.productId}`).push(transactionData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadTransactionsCombo ({ commit, rootState }, productIdSelected) {
      firebase.database().ref(`transactionscombos/${rootState.users.user.uid}/${productIdSelected}`).once('value', (snapshot) => {
        if (snapshot) {
          let arr = []
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              res[cou].id = cou
              return res[cou]
            })
            commit('LOAD_TRANSACTIONS_COMBOS', arr)
          } else {
            commit('LOAD_TRANSACTIONS_COMBOS', [])
          }
        } else {
          commit('LOAD_TRANSACTIONS_COMBOS', [])
        }
      })
    },
  },
}
