// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const logs = {
  namespaced: true,
  actions: {
    setLog ({ state, rootState }, [logData, userId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`lastLog/${userId}/`).set(logData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
  },
}
