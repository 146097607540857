// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const reminders = {
  namespaced: true,
  state: {
    drName: '',
    birthSendedArr: [],
    reminSendedArr: [],
  },
  mutations: {
    LOAD_DR_NAME (state, payload) {
      state.drName = payload
    },
    LOAD_BIRTH_SENDED_ARR (state, payload) {
      state.birthSendedArr = payload
    },
    LOAD_REMIN_SENDED_ARR (state, payload) {
      state.reminSendedArr = payload
    },
  },
  actions: {
    setReminMailSended ({ state, rootState }, mailArr) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/remindMailSended`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/remindMailSended`).push({ email: mailArr.email, sendDate: mailArr.currentDate }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    pushReminMailSended ({ state, rootState }, mailArr) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/remindMailSended`).push({ email: mailArr.email, sendDate: mailArr.currentDate }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadRemindSendedEmails ({ commit, rootState }) {
      firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/remindMailSended`).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              return { uid: cou, data: res[cou] }
            })
            commit('LOAD_REMIN_SENDED_ARR', arr)
          } else {
            commit('LOAD_REMIN_SENDED_ARR', [])
          }
        } else {
          commit('LOAD_REMIN_SENDED_ARR', [])
        }
      })
    },
    setBirthMailSended ({ state, rootState }, mailArr) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/birthMailSended`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/birthMailSended`).push({ email: mailArr.email, sendDate: mailArr.currentDate }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    pushBirthMailSended ({ state, rootState }, mailArr) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/birthMailSended`).push({ email: mailArr.email, sendDate: mailArr.currentDate }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadBirthSendedEmails ({ commit, rootState }) {
      firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/birthMailSended`).on('value', (snapshot) => {
        let arr = []
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            arr = Object.keys(res).map(cou => {
              return { uid: cou, data: res[cou] }
            })
            commit('LOAD_BIRTH_SENDED_ARR', arr)
          } else {
            commit('LOAD_BIRTH_SENDED_ARR', [])
          }
        } else {
          commit('LOAD_BIRTH_SENDED_ARR', [])
        }
      })
    },
    setBirthMessag ({ state, rootState }, birthMessage) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/birthMessage`).set({ message: birthMessage }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadBirthMessag ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/birthMessage`).once('value', (snapshot) => {
          if (snapshot.val()) {
            const birthMessage = snapshot.val()
            resolve(birthMessage)
          } else {
            resolve('')
          }
        })
      })
    },
    setRemindMessag ({ state, rootState }, remindMessage) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/remindMessage`).set({ message: remindMessage }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadRemindMessag ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/reminders/${rootState.users.user.uid}/remindMessage`).once('value', (snapshot) => {
          if (snapshot.val()) {
            const remindMessage = snapshot.val()
            resolve(remindMessage)
          } else {
            resolve('')
          }
        })
      })
    },
    loadAppointArr ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/citas/${rootState.users.user.uid}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            const patients = snapshot.val()
            if (patients) {
              const appointmentArray = Object.keys(patients).map(patientId => {
                return {
                  id: patientId,
                  patientAppoint: Object.keys(patients[patientId]).map(appointment => {
                    return {
                      id: appointment,
                      appointmentData: patients[patientId][appointment],
                    }
                  }),
                }
              })
              resolve(appointmentArray)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        })
      })
    },
    loadPatientData ({ commit, rootState }, appointID) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/patients/${rootState.users.user.uid}/${appointID}`).once('value', (snapshot) => {
          if (snapshot.val()) {
            const patientToRemind = snapshot.val()
            resolve(patientToRemind)
          } else {
            resolve({})
          }
        })
      })
    },
    loadDrName ({ commit, rootState }) {
      firebase.database().ref(`users/${rootState.users.user.uid}`).on('value', (snapshot) => {
        let drName = ''
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            drName = res.firstName + ' ' + res.lastName
            commit('LOAD_DR_NAME', drName)
          } else {
            commit('LOAD_DR_NAME', '')
          }
        } else {
          commit('LOAD_DR_NAME', '')
        }
      })
    },
  },
}
