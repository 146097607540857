// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const paises = {
  namespaced: true,
  state: {
    paises: null,
  },
  mutations: {
    SET_COUNTRIES: (state, data) => {
      state.paises = data
    },
  },
  actions: {
    async setPaisesData ({ dispatch, commit }) {
      await firebase.database().ref('Paises').on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            const arr = Object.keys(res).map(cou => {
              return {
                id: cou,
                provincia: Object.keys(res[cou]).map(prov => {
                  return {
                    id: res[cou][prov].provincia,
                    cantones: Object.keys(res[cou][prov].cantones).map(cant => {
                      return {
                        id: res[cou][prov].cantones[cant].canton,
                        parroquias: Object.keys(res[cou][prov].cantones[cant].parroquias).map(parr => {
                          return {
                            id: res[cou][prov].cantones[cant].parroquias[parr],
                            codigoPostal: parr,
                          }
                        }),
                      }
                    }),
                  }
                }),
              }
            })
            commit('SET_COUNTRIES', arr)
          } else {
            commit('SET_COUNTRIES', [])
          }
        } else {
          commit('SET_COUNTRIES', [])
        }
      })
    },
  },
  getters: {

  },
}
