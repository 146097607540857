<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-if="dialogImport" v-model='dialogImport' max-width="600px")
      v-card
        v-toolbar(text='' color='primary' dark='')
          v-toolbar-title
            h4 Importar Clientes
            v-btn(icon="" dark="" @click="dialogImport = false" style="position: absolute; top: 0px; right: 10px;")
              v-icon.primary.rounded mdi-close
        v-card-text(style='padding-bottom: 0;')
          div(style="widht: 100% text-align: center;")
            a(class="download-excel" href="https://firebasestorage.googleapis.com/v0/b/j2rinventor.appspot.com/o/formatos%2Fformato-importar-clientes.xlsx?alt=media&token=1eb6bf71-a0b4-476d-90f7-16cc7f2c5815") Descargar Plantilla para Carga de Clientes
            p
              strong(style="font-weight: bold;") CÓDIGOS DE LOS TIPOS DE IDENTIFIACIÓN:
            p(style="margin-bottom: 0px;") RUC -> 04
            p(style="margin-bottom: 0px;") CEDULA -> 05
            p(style="margin-bottom: 0px;") PASAPORTE -> 06
            p(style="margin-bottom: 0px;") VENTA A CONSUMIDOR FINAL* -> 07
            p(style="margin-bottom: 0px;") IDENTIFICACION DEL EXTERIOR* -> 08
            input#inputExcel(type='file' style="padding-top: 10px;")
          v-row(style='align-items: center;')
            v-col(cols='12')
              p(style="margin-bottom: 0px;") Para asistencia contáctanos:
                v-btn.ml-2(min-width='0' text='' style="" @click="openLink('https://wa.me/+593984530998')")
                  v-icon(color="black") mdi-whatsapp
            //v-col(cols='6' style='text-align:end;')
              v-btn.ml-2(min-width='0' text='' style='' @click="openLink('https://www.facebook.com/dental.prime.391/')")
                v-icon(color="black") mdi-facebook
              v-btn.ml-2(min-width='0' text=''  style="" @click="openLink('https://www.instagram.com/dentalprimeapp/')")
                v-icon(color="black") mdi-instagram
        v-card-actions
          v-spacer
          v-btn(color='primary' @click='dialogImport = false' :loading='loadingSaveUser')
            | FINALIZAR
    v-snackbar(v-model='snackbar.show' :timeout='5000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
    //-     v-icon mdi-close
    v-dialog(fullscreen="" v-model='dialogCLient')
      v-card
        registro-cliente-modal(@closeModal="closeModal" :clientToEdit="clientToEdit")
    base-material-card.px-5.py-3(icon='mdi-account-multiple' title='Lista de Clientes' style='min-height: 100vh;')
      v-container#clients-table(fluid='' tag='section')
        v-row
          v-col(cols="12" md="6")
            v-divider.mx-4(inset="" vertical="")
            //- *************************** Buscador ***************************
            v-text-field(v-model="search" append-icon="mdi-search" label= "Buscar" single-line="" hide-details="" clearable)
          v-col(cols="12" md="2")
            v-btn.mr-0(color='primary' rounded='' @click='addNewClient' style="width: 100%;")
              strong
                | NUEVO
                br
                | CLIENTE
          v-col(cols="12" md="2")
            v-btn.mr-0(color='green' rounded='' @click='importClients' style="width: 100%;")
              strong
                | IMPORTAR
                br
                | CLIENTES
          v-col(cols="12" md="2")
            v-btn.mr-0(color='secondary' rounded='' @click='downLoadExcelFile' style="width: 100%;")
              strong
                | DESCARGAR
                br
                | LISTA
      v-skeleton-loader(:loading="cargando" type="table" v-if="firstLoad")
      v-data-table.mx-2#tablaDatos(:headers="headers" v-show="!firstLoad" :items="clientLst" item-key="uid" :search="search" dense fixed-header :loading="cargando" loading-text="Cargando" :sort-by="ordenarPor" calculate-widths :footer-props="{\'items-per-page-text':'Clientes por página'\}")
        //template(v-slot:[`item.full_name`]='{ item }') {{ item.name }} {{ item.lastName }} {{item.businessName}}
        template(v-slot:item.edit="{ item }")
          v-tooltip(bottom="")
            template(v-slot:activator="{ on }")
              v-btn(:disabled="item.ide.toString() === '9999999999999'" text="" icon="" color='blue' v-on="on" @click="editClient(item)")
                v-icon(small) mdi-lead-pencil
            span.tooltips Editar Cliente
        //template(v-slot:item.delete="{ item }")
          v-tooltip(bottom="")
            template(v-slot:activator="{ on }")
              v-btn(text="" icon="" color='red' v-on="on" @click="deleteClient(item)")
                v-icon(small) mdi-trash-can-outline
            span.tooltips Borrar Proveedor
        v-alert.pa-0.ma-0(slot='no-results', :value='true', color='error', icon='mdi-alert-octagon-outline')
          | Sin resultados
        v-alert.pa-0.ma-0(slot='no-data', :value='true', color='info', icon='mdi-information-outline')
          | Sin datos
        v-flex(xs12='', sm10='', offset-sm1='')
</template>

<script>
  import readXlsxFile from 'read-excel-file'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import * as functions from '../../../functions'
  export default {
    components: {
      RegistroClienteModal: () => import('@/views/dashboard/component/application/RegistroClienteModal'),
    },
    data: () => ({
      loadingSaveUser: false,
      dialogImport: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      date: new Date().toISOString().substr(0, 10),
      menu1: false,
      menu2: false,
      headers: [
        { text: 'Razón Social / Nombre', value: 'viewName', align: 'left', filterable: true },
        // { text: 'Apellido', value: 'lastName', align: 'left', filterable: true },
        // { text: 'Razón Social', value: 'businessName', align: 'left', filterable: true },
        { text: 'Correo', value: 'mail', align: 'left', filterable: true },
        { text: 'Celular', value: 'cellphone', align: 'left', filterable: true },
        { text: 'IDENTIFICACIÓN', value: 'ide', align: 'left', filterable: true },
        { text: 'Editar', align: 'center', value: 'edit', sortable: false }, // No se cambia
        // { text: 'Borrar', align: 'center', value: 'delete', sortable: false }, // No se cambia
      ],
      clientToEdit: {
        idType: {
          codigo: '05',
          texto: 'CEDULA',
        },
        businessName: '',
        name: '',
        lastName: '',
        mail: '',
        address: '',
        ide: '',
        phone: '',
        cellphone: '',
        clientKey: '',
      },
      ordenarPor: 'name',
      search: '',
      cargando: false,
      firstLoad: false,
      dialogCLient: false,
    }),
    computed: {
      clientLst () {
        return this.$store.state.client.lstClients
      },
    },
    methods: {
      openLink (link) {
        window.open(`${link}`, '_blank')
      },
      importClients () {
        this.dialogImport = true
        setTimeout(() => {
          const inputExcel = document.getElementById('inputExcel')
          inputExcel.addEventListener('change', () => {
            readXlsxFile(inputExcel.files[0]).then(async (rows) => {
              this.loadingSaveUser = true
              const clientLst = []
              let globalBan = true
              for (let i = 1; i < rows.length; i++) {
                let ban = true
                let uidVal = ''
                let msg = 'El campo {CAMPO} de la fila {FILA} es incorrecto. Por favor ingrese el campo indicado para continuar.'
                if (!rows[i][0] || rows[i][0].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Tipo de Identificación')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }
                if (!rows[i][1] || rows[i][1].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Identificación')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }
                if (ban) {
                  if (rows[i][1].toString() === '04') {
                    if (!rows[i][4] || rows[i][4].toString().trim() === '') {
                      msg = msg.toString().replace('{CAMPO}', 'Razón Social')
                      msg = msg.toString().replace('{FILA}', (i + 1))
                      ban = false
                    }
                  } else {
                    if (!rows[i][2] || rows[i][2].toString().trim() === '') {
                      msg = msg.toString().replace('{CAMPO}', 'Nombre')
                      msg = msg.toString().replace('{FILA}', (i + 1))
                      ban = false
                    }

                    if (!rows[i][3] || rows[i][3].toString().trim() === '') {
                      msg = msg.toString().replace('{CAMPO}', 'Apellido')
                      msg = msg.toString().replace('{FILA}', (i + 1))
                      ban = false
                    }
                  }
                }

                if (!rows[i][5] || rows[i][5].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Teléfono Celular')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][7] || rows[i][7].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Dirección')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                if (!rows[i][8] || rows[i][8].toString().trim() === '') {
                  msg = msg.toString().replace('{CAMPO}', 'Correo')
                  msg = msg.toString().replace('{FILA}', (i + 1))
                  ban = false
                }

                const clientValid = this.clientLst.filter(
                  item => {
                    const idFilter = rows[i][1] ? rows[i][1] : ''
                    return (item.ide === idFilter)
                  })
                if (clientValid.length > 0) {
                  uidVal = clientValid[0].id
                }

                let txtSend = ''
                let result = 0
                switch (rows[i][0].toString()) {
                  case '04':
                    txtSend = 'RUC'
                    result = functions.validIdentNumber(false, rows[i][1])
                    if (result.toString() === '0') {
                      msg = msg.toString().replace('{CAMPO}', 'RUC')
                      msg = msg.toString().replace('{FILA}', (i + 1))
                      ban = false
                    }
                    break
                  case '05':
                    txtSend = 'CEDULA'
                    result = functions.validIdentNumber(true, rows[i][1])
                    if (result.toString() === '0') {
                      msg = msg.toString().replace('{CAMPO}', 'CEDULA')
                      msg = msg.toString().replace('{FILA}', (i + 1))
                      ban = false
                    }
                    break
                  case '06':
                    txtSend = 'PASAPORTE'
                    break
                  case '07':
                    txtSend = 'VENTA A CONSUMIDOR FINAL*'
                    break
                  default:
                    txtSend = 'IDENTIFICACION DEL EXTERIOR*'
                }

                if (ban) {
                  const clientSend = {
                    idType: {
                      codigo: rows[i][0] ? rows[i][0] : '',
                      texto: txtSend,
                    },
                    businessName: rows[i][4] ? rows[i][4] : '',
                    name: rows[i][2] ? rows[i][2] : '',
                    lastName: rows[i][3] ? rows[i][3] : '',
                    mail: rows[i][8] ? rows[i][8] : '',
                    address: rows[i][7] ? rows[i][7] : '',
                    ide: rows[i][1] ? rows[i][1] : '',
                    phone: rows[i][6] ? rows[i][6] : '',
                    cellphone: rows[i][5] ? rows[i][5] : '',
                    clientKey: uidVal,
                  }
                  if (uidVal !== '') {
                    clientSend.uid = uidVal
                    clientLst.push(clientSend)
                  } else {
                    clientLst.push(clientSend)
                  }
                } else {
                  globalBan = false
                  this.dialogImport = false
                  this.loadingSaveUser = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: msg,
                  }
                  return
                }
              }
              if (globalBan) {
                let banEnd = true
                let correctRegister = 0
                let errorRegister = 0
                if (clientLst.length > 0) {
                  for (let i = 0; i < clientLst.length; i++) {
                    if (clientLst[i].idType.codigo.toString() === '04') {
                      clientLst[i].completeData = clientLst[i].businessName + ' | ' + clientLst[i].ide + ' | ' + clientLst[i].mail
                      clientLst[i].viewName = clientLst[i].businessName
                    } else {
                      clientLst[i].completeData = clientLst[i].name + ' ' + clientLst[i].lastName + ' | ' + clientLst[i].ide + ' | ' + clientLst[i].mail
                      clientLst[i].viewName = clientLst[i].name + ' ' + clientLst[i].lastName
                    }
                    if (!clientLst[i].clientKey || clientLst[i].clientKey === '') {
                      clientLst[i].clientKey = firebase.database().ref(`clients/${this.currentUserId}`).push().getKey()
                    }
                    await this.$store.dispatch('client/setClient', [clientLst[i].clientKey, clientLst[i]]).then(resolve => {
                      correctRegister++
                    }, reject => {
                      banEnd = false
                      errorRegister++
                    })
                  }
                  if (banEnd) {
                    this.dialogImport = false
                    this.loadingSaveUser = false
                    this.snackbar = {
                      show: true,
                      color: 'green',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  } else {
                    this.dialogImport = false
                    this.loadingSaveUser = false
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Proceso finalizado con: ' + correctRegister + ' registros correctos y ' + errorRegister + ' registros incorrectos.',
                    }
                  }
                } else {
                  this.dialogImport = false
                  this.loadingSaveUser = false
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'No se tiene registros para agregar.',
                  }
                }
              }
            }).catch((e) => {
              this.dialogImport = false
              this.loadingSaveUser = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al momento de cargar el archivo. Verifique el archivo.',
              }
            })
          })
        }, 500)
      },
      editClient (itemToEdit) {
        this.dialogCLient = true
        this.clientToEdit = itemToEdit
      },
      async deleteClient (clientToDelete) {
        await this.$store.dispatch('client/deleteClient', clientToDelete.clientKey).then(() => {
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Cliente eliminado correctamente',
          }
        }).catch(() => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al eliminar el proveedor',
          }
        })
      },
      addNewClient () {
        this.dialogCLient = true
        this.clientToEdit = {
          idType: {
            codigo: '05',
            texto: 'CEDULA',
          },
          businessName: '',
          name: '',
          lastName: '',
          mail: '',
          address: '',
          ide: '',
          phone: '',
          cellphone: '',
          clientKey: '',
        }
      },
      closeModal () {
        this.dialogCLient = false
      },
      downLoadExcelFile () {
        const data = []
        if (this.clientLst) {
          for (let i = 0; i < this.clientLst.length; i++) {
            // se modifica la cedula para que sea legible en excel
            var clientId = this.clientLst[i].ide
            var separator = '-'
            var position = this.clientLst[i].ide.length - 1
            var clientIdFormat = [clientId.slice(0, position), separator, clientId.slice(position)].join('')
            data.push({
              'Tipo de Identificación': this.clientLst[i].idType.codigo,
              Identificación: clientIdFormat,
              Nombre: this.clientLst[i].name,
              Apellido: this.clientLst[i].lastName,
              'Razón Social': this.clientLst[i].businessName,
              'Teléfono Celular': this.clientLst[i].cellphone,
              'Teléfono Convencional': this.clientLst[i].phone.toString(),
              Dirección: this.clientLst[i].address,
              Correo: this.clientLst[i].mail,
            })
          }
          functions.JSONToCSVConvertor(data, 'Clientes', true)
        }
      },
    },
  }
</script>
<style lang="css">
  .download-excel {
    color: #0591a3 !important;
  }
  .download-excel:hover {
    color: #1f6c75 !important;
    text-decoration: underline;
  }
  .note-download {
    font-size: 12px;
    text-align: justify;
    color: red;
  }
</style>
