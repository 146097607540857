<template>
  <v-app-bar
    id="app-bar"
    absolute
    app
    color="transparent"
    flat
    height="75"
  >
    <!--    <v-dialog-->
    <!--      v-model="dialogProfile"-->
    <!--      persistent-->
    <!--      max-width="600px"-->
    <!--    >-->
    <!--      <v-card>-->
    <!--        <v-card-title>-->
    <!--          <span class="text-h5">Para mejorar nuestro servicio necesitamos que completes tus datos por favor</span>-->
    <!--        </v-card-title>-->
    <!--        <v-card-text>-->
    <!--          <v-row>-->
    <!--            <v-col-->
    <!--              cols="12"-->
    <!--              md="12"-->
    <!--            >-->
    <!--              <v-text-field-->
    <!--                v-model="userInit.email"-->
    <!--                label="Correo"-->
    <!--                required-->
    <!--                disabled-->
    <!--              />-->
    <!--            </v-col>-->
    <!--            <v-col-->
    <!--              cols="12"-->
    <!--              md="6"-->
    <!--            >-->
    <!--              <v-text-field-->
    <!--                v-model="userInit.firstName"-->
    <!--                :label="banCode ? &quot;Nombres&quot; : &quot;Nombre de la Clínca&quot;"-->
    <!--                hint="example of helper text only on focus"-->
    <!--              />-->
    <!--            </v-col>-->
    <!--            <v-col-->
    <!--              cols="12"-->
    <!--              md="6"-->
    <!--            >-->
    <!--              <v-text-field-->
    <!--                v-model="userInit.lastName"-->
    <!--                :label="banCode ? &quot;Apellidos&quot; : &quot;Representante&quot;"-->
    <!--                persistent-hint-->
    <!--                required-->
    <!--              />-->
    <!--            </v-col>-->
    <!--            <v-col-->
    <!--              cols="12"-->
    <!--              md="4"-->
    <!--            >-->
    <!--              <v-text-field-->
    <!--                v-model="userInit.phone"-->
    <!--                label="Teléfono"-->
    <!--                type="number"-->
    <!--                required-->
    <!--              />-->
    <!--            </v-col>-->
    <!--            <v-col-->
    <!--              cols="12"-->
    <!--              md="8"-->
    <!--            >-->
    <!--              <v-text-field-->
    <!--                v-model="userInit.address"-->
    <!--                label="Dirección de Consultorio o Clínica"-->
    <!--                type="text"-->
    <!--                required-->
    <!--              />-->
    <!--            </v-col>-->
    <!--          </v-row>-->
    <!--        </v-card-text>-->
    <!--        <v-card-actions>-->
    <!--          <v-spacer />-->
    <!--          <v-btn-->
    <!--            color="red"-->
    <!--            text-->
    <!--            :loading="loadingSaveUser"-->
    <!--            @click="dialogProfile = false"-->
    <!--          >-->
    <!--            Cancelar-->
    <!--          </v-btn>-->
    <!--          <v-btn-->
    <!--            color="primary"-->
    <!--            :loading="loadingSaveUser"-->
    <!--            @click="saveDataUser()"-->
    <!--          >-->
    <!--            Guardar-->
    <!--          </v-btn>-->
    <!--        </v-card-actions>-->
    <!--      </v-card>-->
    <!--    </v-dialog>-->

    <div id="precios-procedimientos-view" />
    <div id="patients-view" />
    <div class="text-center">
      <v-dialog
        v-model="dialogCookies"
        width="50%"
        style="margin: 0px; bottom: 0px; position: fixed;"
        persistent
      >
        <v-card>
          <v-card-title
            class="text-h5 grey lighten-2"
          >
            Esta página web usa cookies
          </v-card-title>

          <v-card-text style="text-align: center;">
            Este aplicativo web utiliza una cookie de almacenamiento de datos para mantener activa su sesión.
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red"
              text
              @click="handleLogut"
            >
              No Acepto
            </v-btn>
            <v-btn
              color="primary"
              @click="acceptCookies"
            >
              Acepto
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-dialog
      v-model="suscripExpirDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          Suscripción próxima a caducar
        </v-card-title>
        <v-card-text>Su suscripción expira en {{ daysLeft }} día(s)</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="suscripExpirDialog = false"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :multi-line="multiLine"
      :color="snackbar.color"
      top=""
      right=""
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          dark=""
          icon=""
          @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <!-- <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      top=""
      right=""
    >
      {{ snackbar.text }}
      <v-btn
        dark=""
        text=""
        icon=""
        @click="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar> -->
    <v-dialog
      v-model="dialog"
      scrollable=""
      max-width="400px"
    />
    <!-- <v-container
    style="margin:0;"
    > -->
    <v-row
      class="row-toolbar"
    >
      <v-btn
        class="mr-3"
        elevation="1"
        fab
        small
        @click="setDrawer(!drawer)"
      >
        <v-icon
          v-if="value"
          color="primary"
        >
          mdi-view-quilt
        </v-icon>

        <v-icon
          v-else
          color="primary"
        >
          mdi-dots-vertical
        </v-icon>
      </v-btn>

      <v-toolbar-title
        style="font-size:18px; display:flex; align-items:center;"
        class="hidden-sm-and-down font-weight-light"
        v-text="$route.name"
      />

      <v-spacer />

      <!--<v-text-field-->
      <!--:label="$t('search')"-->
      <!--color="secondary"-->
      <!--hide-details-->
      <!--style="max-width: 300px;"-->
      <!--&gt;-->
      <!--<template-->
      <!--v-if="$vuetify.breakpoint.mdAndUp"-->
      <!--v-slot:append-outer-->
      <!--&gt;-->
      <!--<v-btn-->
      <!--class="mt-n2"-->
      <!--elevation="1"-->
      <!--fab-->
      <!--small-->
      <!--&gt;-->
      <!--<v-icon>mdi-magnify</v-icon>-->
      <!--</v-btn>-->
      <!--</template>-->
      <!--</v-text-field>-->

      <div class="mx-3" />

      <!--<v-btn-->
      <!--class="ml-2"-->
      <!--min-width="0"-->
      <!--text-->
      <!--to="/dashboard"-->
      <!--&gt;-->
      <!--<v-icon>mdi-view-dashboard</v-icon>-->
      <!--</v-btn>-->

      <!--        <v-autocomplete v-if="patientSelected == null" v-model="valuePatient" :items="patientLstFilter" return-object="return-object" item-text="data.data.completeData" dense="" filled="" label="Pacientes" @change="changePatient" style="padding: 0px 10px;"></v-autocomplete>-->

      <v-menu
        v-if="user"
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            style="padding: 0px;"
            v-on="on"
          >
            <h5
              class="diplay-accounts"
              style=" font-size:18px;"
            >
              <strong style="color: darkgreen;">
                CUENTA:
              </strong>
              {{ user.selectAccount ? user.selectAccount.toString().split('/')[0].toUpperCase() + ' ' : '' }}
            </h5>
            <!-- <v-icon color="primary">
              mdi-format-list-checks
            </v-icon> -->
          </v-btn>
        </template>

        <v-list
          :tile="false"
          nav
        >
          <div v-if="user">
            <app-bar-item
              v-for="(n, i) in user.accounts"
              :key="`item-${i}`"
            >
              <v-list-item-title
                style="padding-right: 0px;"
                @click="changueAccount(n.name)"
                v-text="n.name ? n.name.toString().split('/')[0].toUpperCase() : ''"
              />
            </app-bar-item>
          </div>
        </v-list>
      </v-menu>

      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            style="color: white; top: 3px;"
            v-on="on"
          >
            <v-badge
              color="red"
              overlap
              bordered
            >
              <template v-slot:badge>
                <span>{{ notifications.length }}</span>
              </template>

              <v-icon color="primary">
                mdi-bell
              </v-icon>
            </v-badge>
          </v-btn>
        </template>

        <v-list
          v-if="notifications.length === 0"
          :tile="false"
          nav
          style="padding:0px;"
        >
          <div
            style="background:lightgreen;"
          >
            <p style="padding:10px;">
              No tienes notificaciones
            </p>
          </div>
        </v-list>

        <v-list
          v-if="notifications.length > 0"
          :tile="false"
          nav
        >
          <div>
            <app-bar-item
              v-for="(n, i) in notifications"
              :key="`item-${i}`"
            >
              <v-row>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <p>{{ n.descripcion }}</p>
                </v-col>
                <v-col
                  class="text-center"
                  cols="12"
                >
                  <v-btn
                    class="ml-2"
                    min-width="0"
                    text=""
                    @click="openLink('https://www.facebook.com/dental.prime.391/')"
                  >
                    <v-icon>
                      mdi-facebook
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    min-width="0"
                    text=""
                    @click="openLink('https://www.instagram.com/dentalprimeapp/')"
                  >
                    <v-icon>
                      mdi-instagram
                    </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-2"
                    min-width="0"
                    text=""
                    @click="openLink('https://wa.me/+593984530998')"
                  >
                    <v-icon>
                      mdi-whatsapp
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </app-bar-item>
          </div>
        </v-list>
      </v-menu>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            id="userprofile"
            class="ml-2 diplay-accounts"
            min-width="0"
            text
            to="/dashboard/pages/user"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon
              color="primary"
              size="x-large"
            >
              mdi-account
            </v-icon>
          </v-btn>
        </template>
        <span>Mi Cuenta</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            v-bind="attrs"
            color="green lighten-4"
            href="https://api.whatsapp.com/send/?phone=593984530998&text&app_absent=0"
            target="_blank"
            style="text-decoration:none; margin:0;"
            min-width="0"
            v-on="on"
          >
            <v-icon color="primary">
              mdi-whatsapp
            </v-icon>
          </v-btn>
        </template>
        <span>Soporte técnico</span>
      </v-tooltip>

      <v-cols>
        <v-btn
          class="ml-2 diplay-accounts-exit"
          min-width="0"
          text
          color="red"
          @click="handleLogut"
        >
          <v-icon color="red">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
        <v-btn
          class="ml-2 diplay-accounts"
          color="red"
          style="width: 100%; height: 38px; font-weight: bold; font-size:12px;"
          @click="handleLogut"
        >
          CERRAR SESIÓN
        </v-btn>
      </v-cols>
    </v-row>
    <!-- </v-container> -->
  </v-app-bar>
</template>

<script>
  // Components
  import { VHover, VListItem } from 'vuetify/lib'
  import firebase from 'firebase'

  // Utilities
  import { mapState, mapMutations } from 'vuex'
  var functions = require('../../../../functions/functions')

  export default {
    name: 'DashboardCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(VListItem, {
                  attrs: this.$attrs,
                  class: {
                    'black--text': !hover,
                    'white--text secondary elevation-12': hover,
                  },
                  props: {
                    activeClass: '',
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                }, this.$slots.default)
              },
            },
          })
        },
      },
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data: () => ({
      loadingSaveUser: false,
      userInit: {
        email: '',
        firstName: '',
        lastName: '',
        phone: '',
        address: '',
        provincia: '',
        canton: '',
        parroquia: '',
        indexPais: '',
        indexProvincia: '',
        indexCanton: '',
        indexParroquia: '',
        pais: '',
        postalCode: '',
        information: '',
        create: '',
        update: '',
        urlImg: '',
        status: '',
      },
      banCode: true,
      dialogProfile: false,
      logData: {
        currentDate: '',
        currentTime: '',
      },
      daysLeft: null,
      suscripExpirDialog: false,
      dialogCookies: false,
      currentDrId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : '',
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      dialog: false,
    }),

    computed: {
      suscriptionData () {
        return this.$store.state.suscriptionPlan.suscription
      },
      patientLst () {
        return this.$store.state.patients.pacientes
      },
      routeComputed () {
        return this.$route.path
      },
      ...mapState(['drawer']),
      colorSelect () {
        return this.$store.state.configuraciones.configs.color
      },
      getCookies () {
        return this.$store.state.users.cookies
      },
      user () {
        return this.$store.state.users.user
      },
      accounts () {
        return this.$store.state.users.accounts
      },
      notifications () {
        return this.$store.state.notifications.notifications
      },
    },

    watch: {
      suscriptionData () {
        const dateInit = new Date(this.suscriptionData.created + 'T00:00:00')
        const currentDate = new Date()

        const differenceTime = currentDate.getTime() - dateInit.getTime()
        const differenceDays = differenceTime / (1000 * 3600 * 24)
        this.daysLeft = parseInt(this.suscriptionData.days) - parseInt(differenceDays)
        if (this.daysLeft <= 5) {
          this.suscripExpirDialog = true
        }
      },
      accounts () {
        // this.user.selectAccount = this.accounts[0].name
        this.user.accounts = this.accounts
        this.$store.dispatch('users/setAccountUser', this.user).then(res => {
          if (res) {
            this.loadInitData()
          }
        })
      },
      colorSelect () {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = this.colorSelect
      },
      getCookies () {
        if (this.getCookies) {
          this.dialogCookies = false
        } else {
          this.dialogCookies = true
        }
      },
    },
    created () {
      this.loadInitData().then(res => {
        this.$store.dispatch('users/loadAccounts')
      })
    },
    methods: {
      saveDataUser () {
        this.loadingSaveUser = true
        const currentUser = firebase.auth().currentUser
        if (this.userInit.accounts) {
          firebase.database().ref(`users/${currentUser.uid}`).set(
            this.userInit,
          ).then(() => {
            this.loadingSaveUser = false
            this.$store.dispatch('users/setDataUser', this.userInit)
            this.$store.dispatch('setLocalStorageData', { item: 'user', obj: this.userInit })
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Usuario actualizado correctamente',
            }
            this.dialogProfile = false
          }, () => {
            this.loadingSaveUser = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al actualizar el usuario',
            }
          })
        } else {
          this.loadingSaveUser = false
          this.dialogProfile = false
        }
      },
      openLink (link) {
        window.open(`${link}`, '_')
      },
      loadDataPatient (path) {
        switch (path) {
          case 'valor1':
            break
          case 'valor2':
            break
          case 'valorN':
            break
          case 'valorP':
            break
        }
      },
      loadInitData () {
        return new Promise(resolve => {
          // this.checkToSendEmail()
          this.$store.dispatch('getLocalStorageData', 'user').then(res => {
            this.$store.dispatch('users/setDataUser', res)
            this.userInit = res
            if (!res.email || res.email === '' || !res.firstName || res.firstName === '' || !res.lastName || res.lastName === '' || !res.phone || res.phone === '' || !res.address || res.address === '') {
              this.dialogProfile = true
              if (res.selectAccount === 'personal') {
                this.banCode = true
              } else {
                this.banCode = false
              }
            }
            const currentDrId = firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''
            const currentDate = new Date().toString().substr(4, 12)
            this.$store.dispatch('chat/loadDrData', [currentDrId])
            this.$store.dispatch('chat/loadChatDrMessageLst', [currentDate, currentDrId])
            this.$store.dispatch('suscriptionPlan/loadPlanSuscription')
            this.$store.dispatch('users/getUserStatus')
            this.$store.dispatch('countries/setCountriesData')
            this.$store.dispatch('paises/setPaisesData')
            this.$store.dispatch('general/getDataPersonalHistory')
            this.$store.dispatch('general/getDataFamilyHistory')
            this.$store.dispatch('general/getDataAntecedentes')
            this.$store.dispatch('financial/loadEntryArr')
            this.$store.dispatch('financial/loadEgressArr')
            this.$store.dispatch('treatment/loadTreatmentsPrices')
            this.$store.dispatch('users/loadDoubts')
            this.$store.dispatch('patients/setViewProgressBar', true)
            this.$store.dispatch('doctors/setViewProgressBar', true)
            this.$store.dispatch('menus/loadMenus', true)
            this.$store.dispatch('agenda/loadDataAgenda', currentDrId)
            this.$store.dispatch('reminders/loadDrName')
            this.$store.dispatch('reminders/loadBirthSendedEmails')
            this.$store.dispatch('reminders/loadRemindSendedEmails')
            // seccion ver si se debe cargar o no las cuentas pendientes de todos los pacientes
            this.$store.dispatch('patients/checkIfShowPending', currentDrId).then(showDoubts => {
              if (showDoubts === true) {
                this.$store.dispatch('patients/loadAllAccountLst')
              }
            })
            // this.$store.dispatch('patients/loadAllAccountLst')
            this.$store.dispatch('configuraciones/loadConfig').then(res => {
              if (res) {
                this.$store.state.barImage = res.image
                if (res.showTour) {
                  setTimeout(() => {
                    this.$tours.myTour.start()
                    this.$store.dispatch('configuraciones/setTourConfig')
                  }, 500)
                }
              } else {
                this.$store.state.barImage = 'https://firebasestorage.googleapis.com/v0/b/app-dental-ece93.appspot.com/o/sidebar%2Fconsultorio-dentistas.jpeg?alt=media&token=5d98fc25-71f4-46ff-b14d-629ca50495a4'
                setTimeout(() => {
                  this.$tours.myTour.start()
                  this.$store.dispatch('configuraciones/setTourConfig')
                }, 500)
              }
            })
            this.$store.dispatch('patients/loadDataPatients').then(res => {
              this.$store.dispatch('patients/setViewProgressBar', false)
            })
            this.$store.dispatch('doctors/loadDataDoctors').then(res => {
              this.$store.dispatch('doctors/setViewProgressBar', false)
            })
            setTimeout(() => {
              this.logData.currentDate = new Date().toString().substr(4, 12)
              this.logData.currentTime = new Date().toString().substr(16, 9)
              this.$store.dispatch('logs/setLog', [this.logData, currentDrId])
            }, 500)
            this.$store.dispatch('users/getCookies')
            this.$store.dispatch('users/getSesionUid')
            this.$store.dispatch('profile/loadProfile').then((res, reject) => {
              if (res) {
                if (res.valuidfac) {
                  let userSend = res.email
                  if (userSend.length > 20) {
                    userSend = userSend.substr(0, 19)
                  }
                  this.$store.dispatch('facturacion/userLoginFac', [userSend, res.valuidfac]).then((res, reject) => {
                  })
                } else {
                  const passwordFac = functions.randomString(12, 16)
                  this.$store.dispatch('facturacion/adminLoginFac').then(resToken => {
                    if (resToken) {
                      let userSend = res.email
                      if (userSend.length > 20) {
                        userSend = userSend.substr(0, 19).toLowerCase()
                      }
                      const dataUser = {
                        username: userSend,
                        password: passwordFac,
                        rol: 'ROLE_USER',
                        active: true,
                      }
                      const dataSend = {
                        body: dataUser,
                        token: resToken,
                      }
                      this.$store.dispatch('facturacion/createUser', dataSend).then(res => {
                        if (res) {
                          this.$store.dispatch('profile/setProfileValuidfac', passwordFac)
                        } else {
                          this.loading = false
                          this.$loading(false)
                          this.snackbar = {
                            show: true,
                            color: 'red',
                            text: 'Error al momento de crear el usuario.',
                          }
                        }
                      }, reject => {
                        if (reject) {
                          if (reject.response) {
                            if (reject.response.data) {
                              if (reject.response.data.result) {
                                if (reject.response.data.result.message) {
                                  if (reject.response.data.result.message.includes('existe')) {
                                    this.loading = false
                                    this.$loading(false)
                                    this.snackbar = {
                                      show: true,
                                      color: 'red',
                                      text: 'Usuario ya registrado.',
                                    }
                                  } else {
                                    this.loading = false
                                    this.$loading(false)
                                    this.snackbar = {
                                      show: true,
                                      color: 'red',
                                      text: reject.response.data.result.message,
                                    }
                                  }
                                }
                              } else {
                                this.loading = false
                                this.$loading(false)
                                this.snackbar = {
                                  show: true,
                                  color: 'red',
                                  text: 'Error al momento de crear el usuario.',
                                }
                              }
                            } else {
                              this.loading = false
                              this.$loading(false)
                              this.snackbar = {
                                show: true,
                                color: 'red',
                                text: 'Error al momento de crear el usuario.',
                              }
                            }
                          } else {
                            this.loading = false
                            this.$loading(false)
                            this.snackbar = {
                              show: true,
                              color: 'red',
                              text: 'Error al momento de crear el usuario.',
                            }
                          }
                        } else {
                          this.loading = false
                          this.$loading(false)
                          this.snackbar = {
                            show: true,
                            color: 'red',
                            text: 'Error al momento de crear el usuario.',
                          }
                        }
                      })
                    } else {
                      this.loading = false
                      this.$loading(false)
                      this.snackbar = {
                        show: true,
                        color: 'red',
                        text: 'Error al momento de crear el usuario.',
                      }
                    }
                  }, reject => {
                    this.loading = false
                    this.$loading(false)
                    this.snackbar = {
                      show: true,
                      color: 'red',
                      text: 'Error al momento de crear el usuario.',
                    }
                  })
                }
              }
            })
            this.$store.dispatch('ivaretenciones/cargarValorIva')
            this.$store.dispatch('ivaretenciones/cargarListaRetenciones')
            this.$store.dispatch('ivaretenciones/cargarRetencionesIr')
            this.$store.dispatch('ivaretenciones/cargarRetencionesIva')
            this.$store.dispatch('product/loadProducts')
            this.$store.dispatch('client/loadClients')
            this.$store.dispatch('provider/loadProviders')
            this.$store.dispatch('facturacion/loadCompanyData')
            this.$store.dispatch('facturacion/loadelEctronicBillingTables')
            this.$store.dispatch('factura/loadFacturaSale')
            this.$store.dispatch('factura/loadFacturaPurchse')
            const currentUser = firebase.auth().currentUser
            if (currentUser) {
              this.$store.dispatch('financial/loadEntryArr', currentUser.uid)
              this.$store.dispatch('financial/loadEgressArr', currentUser.uid)
            }
            resolve(true)
          })
        })
      },
      changueAccount (name) {
        this.user.selectAccount = name
        this.$store.dispatch('users/setAccountUser', this.user).then(res => {
          if (res) {
            this.loadInitData()
          }
        })
      },
      acceptCookies () {
        this.dialogCookies = false
        this.$store.dispatch('users/setCookies', true)
      },
      handleLogut () {
        this.dialog = false
        firebase.auth().signOut().then(() => {
          localStorage.clear()
          this.$store.dispatch('users/cleanStates')
          this.$store.dispatch('patients/cleanData')
          this.$store.dispatch('general/clearData')
          this.$store.dispatch('financial/clearData')
          this.$store.dispatch('financialFac/clearData')
          this.$router.push('/')
        })
      },
      ...mapMutations({
        setDrawer: 'SET_DRAWER',
      }),
    },
  }
</script>
<style lang="css">
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>th, .v-data-table>.v-data-table__wrapper>table>thead>tr>th{
  font-size: 1.5rem !important;
}
.v-data-table>.v-data-table__wrapper>table>tbody>tr>td, .v-data-table>.v-data-table__wrapper>table>tfoot>tr>td, .v-data-table>.v-data-table__wrapper>table>thead>tr>td{
  font-size: 1.2rem !important;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined):hover{
  background: transparent;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
  background: transparent;
}
v-btn v-btn--icon v-btn--round theme--light v-size--default red--text:hover{
  background: transparent;
  background-color: transparent;
}
.row {
  margin: 0px;
}
.v-snack__content{
  font-size: 1.4rem;
}
.v-snack {
  font-size: 1.4rem;
}
.v-application .display-1 {
  font-family:'ceraProBlack' !important;
  font-size: 1.6rem !important;
}
@font-face {
  font-family: "ceraProBlack";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Black.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProRegular";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Regular.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProMedium";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Medium.otf');
  font-display: swap;
}
@font-face {
  font-family: "ceraProBold";
  src: url('~@/fonts/Cera_Pro/Cera-Pro-Bold.otf');
  font-display: swap;
}
body {
  font-family: 'ceraProRegular', sans-serif !important;
}
h3{
  font-family: 'ceraProBold', sans-serif !important;
}
label {
  font-family: 'ceraProRegular', sans-serif !important;
}
v-btn {
  font-size:1.1rem;
}
.row-toolbar{
  padding-right: 3rem;
}
.v-data-footer{
  font-size:1.1rem;
}
.v-dialog > .v-card > .v-card__text {
  /* text-align: center; */
  font-family: "ceraProRegular", sans-serif !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td{
  font-size: 1.2rem;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  font-size: 1.5rem;
}
.v-application .headline{
  font-family: 'ceraProRegular', sans-serif !important;
}
.success-btn {
  color:white !important;
}
.tooltips{
  font-family: "ceraProRegular", sans-serif !important;
}
.v-list-item__content {
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-application p, .v-application a {
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-stepper__step {
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-alert__content {
  font-family: 'ceraProRegular', sans-serif !important;
}
.theme--light.v-input{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-data-footer__select{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-application .font-weight-light{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-snack__content{
  font-family: 'ceraProMedium', sans-serif !important;
}
.v-btn__content {
  font-family: 'ceraProMedium', sans-serif !important;
}
.v-data-table > .v-data-table__wrapper > table{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-tabs--icons-and-text > .v-tabs-bar .v-tab{
  font-family: 'ceraProBlack', sans-serif !important;
}
.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab){
  font-size:12px;
}
.v-content__wrap .container--fluid{
  margin:0;
  width:100%;
}
.v-data-table td {
    font-size: 1.3rem;
}
.v-card > *:last-child:not(.v-btn):not(.v-chip){
  margin:0;
  width:100%;
}
.v-list-item--dense .v-list-item__title, .v-list-item--dense .v-list-item__subtitle, .v-list--dense .v-list-item .v-list-item__title, .v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 1.3rem;
}
a#userprofile{
  text-decoration: none;
}
.no-gutters > .col, .no-gutters > [class*=col-]{
  font-size:1.3rem;
}
.v-settings .v-card__text strong{
  font-family: 'ceraProBlack', sans-serif !important;
}
.col-auto{
  font-family: 'ceraProRegular', sans-serif !important;
}
.v-application--is-ltr .v-expansion-panel-header{
  padding: 5px 10px 0px 5px !important;
}
.v-toolbar__title{
  font-size: 1.8rem;
  font-family: 'ceraProMedium';
}
.v-tabs:not(.v-tabs--vertical) .v-tab{
  font-family: 'ceraProMedium';
}
.v-tab {
  font-size: 1.4rem;
}
/* .v-card > *:last-child:not(.v-btn):not(.v-chip){
  display:flex;
  justify-content: initial;
} */
  .v-menu__content .v-list--nav .v-list-item {
    padding: 0px;
    margin: 0 0.3125rem;
    margin-bottom: 0px !important;
    min-height: 40px;
    border-radius: 2px;
  }
  .diplay-accounts {
    display: block;
  }
  .diplay-accounts-exit {
    display: none;
  }
  @media (max-width: 768px) {
    .diplay-accounts {
      display: none;
    }
    .diplay-accounts-exit {
      display: block;
    }
    .row-toolbar{
      padding-right: 0;
    }
  }
</style>
