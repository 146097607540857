<template lang="pug">
  v-container
    v-card
      v-toolbar(text='' color='red' dark='')
        v-toolbar-title(color='red') Anamnesis
          v-btn(icon="" @click="$emit('exitAntecedente')" style="position: absolute; top: 0px; right: 10px;")
            v-icon.rounded mdi-close
      v-card-text
        v-row
          v-col(cols='12' md='6')
            v-combobox(disabled v-if="historyObject" v-model='historyObject.modelPersonalLst' :items='historyObject.modelPersonalLst' item-text="data" hide-selected='' label='Antecedentes Personales - Seleccione' multiple='' small-chips='')
              //- <!--template(v-slot:no-data='')-->
              v-list-item
                v-list-item-content
                  v-list-item-title
                    | No hemos encontrado resultados similares
          v-col(cols='12' md='6')
            v-combobox(disabled v-if="historyObject" v-model='historyObject.modelfamiliarLst' :items='historyObject.modelfamiliarLst' item-text="data" hide-selected='' label='Antecedentes Familiares - Seleccione' multiple='' small-chips='')
              //- <!--template(v-slot:no-data='')-->
              v-list-item
                v-list-item-content
                  v-list-item-title
                    | No hemos encontrado resultados similares
        //- <!--v-checkbox(v-model='checkbox1' :label='`Hábitos de higiene bucal`')-->
        v-row
          v-col(cols="12" md="8")
            v-textarea(disabled label='Observaciones' height="32px" v-model="historyObject.obs")
          v-col(cols="12" md="4")
            v-text-field(label='Fecha de Registro' v-model='historyObject.currentDate' disabled)
          //- <!--v-col(cols="12")-->
          //-   <!--v-checkbox(v-model='historyObject.generateAlert' label='Generar Alerta de Anamnesis' style='align-items:center;')-->
        h3(style="color: primary;") Hábitos de higiene bucal
        v-row(style="padding: 20px;")
          v-col(cols="12" style="padding: 0px; margin: 0px;")
            v-row
              v-col(cols="10" style="padding: 0px; margin: 0px;")
                p Cepillado dental (3 veces al día)
              v-col(cols="2" style="padding: 0px; margin: 0px;")
                v-switch(disabled style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.cepilladoDental' :label='`${historyObject.oralHygieneHabits.cepilladoDental === true ? "SI" : "NO"}`')
          v-col(cols="12" style="padding: 0px; margin: 0px;")
            v-row
              v-col(cols="10" style="padding: 0px; margin: 0px;")
                p Usa hilo dental
              v-col(cols="2" style="padding: 0px; margin: 0px;")
                v-switch(disabled style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.hiloDental' :label='`${historyObject.oralHygieneHabits.hiloDental === true ? "SI" : "NO"}`')
          v-col(cols="12" style="padding: 0px; margin: 0px;")
            v-row
              v-col(cols="10" style="padding: 0px; margin: 0px;")
                p Usa enjuague bucal
              v-col(cols="2" style="padding: 0px; margin: 0px;")
                v-switch(disabled style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.enjuagueBucal' :label='`${historyObject.oralHygieneHabits.enjuagueBucal === true ? "SI" : "NO"}`')
          v-col(cols="12" style="padding: 0px; margin: 0px;")
            v-row
              v-col(cols="10" style="padding: 0px; margin: 0px;")
                p Si tiene aparatos intraorales ¿los higieniza? (prótesis parciales, totales, placas ortopédicas, brackets)
              v-col(cols="2" style="padding: 0px; margin: 0px;")
                v-switch(disabled style="margin-top: 0px;" v-model='historyObject.oralHygieneHabits.aparatosIntrahorales' :label='`${historyObject.oralHygieneHabits.aparatosIntrahorales === true ? "SI" : "NO"}`')
</template>
<script>
  export default {
    props: {
      historyObject: null,
    },
    data: () => ({
    }),
  }
</script>
