<template lang="pug">
  v-container.col-md-12.col-12
    v-dialog(v-if="dialogPatient" fullscreen="" v-model='dialogPatient' width='800')
      v-card
        patient(:seeData="false" @finishCreate="finishCreate" :selectedPatient="selectedPatient" :idPatient="idPatient" @exitPatient="dialogPatient = false")
    v-dialog(v-model='confirmationDialog' persistent='' max-width='290')
      v-card
        v-card-title.headline
          | Confirmar
        v-card-text &iquest;Estas seguro de eliminar este tratamiento?
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='confirmationDialog = false')
              | Cancelar
            v-btn(color='blue darken-1' @click='acceptConfirmation')
              | Aceptar
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(v-model='snackbar.show' :timeout='30000' top='' right='' :color='snackbar.color')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-row
      v-col(cols="12" md="5")
        base-material-card.px-5.py-3(icon='mdi-clipboard-text' title='Lista de signos vitales')
          v-container#patient-table(fluid='' tag='section')
            v-row
              v-col(cols="1" md="1" style="right: 50px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='secondary' @click="downLoadExcelFile")
                      v-icon(style="font-size: 30px;") mdi-arrow-down-bold-box
                  span Descargar Lista de Signos Vitales
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 90px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='primary' @click="newPatient")
                      v-icon(style="font-size: 30px;") mdi-account-plus
                  span Agregar Nuevo Paciente
              v-col(cols="1" md="1" v-if="patientSelected == null" style="right: 130px; position: absolute; top: 20px;")
                v-tooltip(bottom='')
                  template(v-slot:activator='{ attrs, on }')
                    v-btn(v-on="on" icon='' color='orange' @click="viewPatient")
                      v-icon(style="font-size: 30px;") mdi-eye
                  span Ver datos del paciente
              v-col(cols="12" md="12" style="padding: 0px; margin: 0px;" v-if="patientSelected == null" )
                v-autocomplete(@change="changePatient" v-model='valuePatient' :items='patientLstFilter' return-object item-text="data.data.completeData"  label='Pacientes')
                  template(v-slot:no-data='')
                    v-list-item
                      v-list-item-title
                        | Aún no hay pacientes, regístralos desde el menú
                        strong  Pacientes
              v-col(cols="12" md="12")
                v-progress-linear(indeterminate='' color='primary' v-if="progressBar")
                v-simple-table
                  thead
                    tr
                      th.primary--text
                        | Paciente
                      th.primary--text
                        | Fecha
                      th.text-center.primary--text
                        | Eliminar
                  tbody
                    tr(v-for="(signovital, index) in arrSignosVitalesLst" :key="index")
                      td(@click="selectSignoVital(signovital)" style="cursor: pointer;") {{signovital.data.data.patientCompletData.toString().split(' | ')[0]}}
                      td(@click="selectSignoVital(signovital)" style="cursor: pointer;") {{signovital.data.data.currentDate}}
                      td(style="cursor: pointer;").text-center
                        v-btn(icon='' color='red' @click="deleteSignoVital(signovital)")
                          v-icon mdi-delete
        v-row(style="padding-top: 20px;")
          v-col(cols="6" md="6").text-center
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='secondary' @click="cleanData")
                  v-icon(style="font-size: 40px;") mdi-clipboard-plus
              span Nuevo Registro
          v-col(cols="6" md="6")
            v-tooltip(bottom='')
              template(v-slot:activator='{ attrs, on }')
                v-btn(v-on="on" icon='' color='primary' @click="saveData" :loading="loadingSaveData")
                  v-icon(style="font-size: 40px;") mdi-content-save
              span Guardar Datos
      v-col(cols="12" md="7")
        v-card.v-card--material.pa-3.v-card.v-sheet.theme--light.v-card--material--has-heading
          v-row.text-start.v-card--material__heading.mb-n6.v-sheet.theme--dark.elevation-6.primary.pa-7(justify='center' no-gutters='')
            | Signos Vitales
          h4(style="color: primary; padding:0 1.5rem;") Signos Vitales
          v-row
            v-col(cols='12' sm='6' md='3')
              v-text-field(label='Presión arterial sistólica' placeholder='mmHg' outlined='' type="number" v-model="signosVitales.presionArterialSistolica")
            v-col(cols='6' sm='6' md='3')
              v-text-field(label='Presión arterial distólica' placeholder='mmHg' outlined='' type="number" v-model="signosVitales.presionArterialDiastolica")
            v-col(cols='6' sm='6' md='3')
              v-text-field(label='Presión arterial media' placeholder='mmHg' outlined='' type="number" v-model="signosVitales.presionArterialMedia")
            v-col(cols='6' sm='6' md='3')
              v-text-field(label='Temperatura' placeholder='C°' outlined='' type="number" v-model="signosVitales.temperatura")
            v-col(cols='6' sm='6' md='4')
              v-text-field(label='Frecuencia respiratoria' placeholder='resp/min' outlined='' type="number" v-model="signosVitales.frecuenciaRespiratoria")
            v-col(cols='6' sm='6' md='4')
              v-text-field(label='Frecuencia cardíaca' placeholder='lat/min' outlined='' type="number" v-model="signosVitales.frecuenciaCardiaca")
            v-col(cols='6' sm='6' md='4')
              v-text-field(label='Saturación de oxígeno' placeholder='%' outlined='' type="number" v-model="signosVitales.saturacionOxigeno")
          h4(style="color: primary; padding:0 1.5rem;") Datos Antropométricos
          v-row
            v-col(cols='6' sm='6' md='3')
              v-text-field(label='Peso' placeholder='kg' outlined='' type="number" v-model="signosVitales.peso")
            v-col(cols='6' sm='6' md='3')
              v-text-field(label='Talla' placeholder='cm' outlined='' type="number" v-model="signosVitales.tallaEstatura")
            v-col(cols='6' sm='6' md='3')
              v-text-field(label='I.M.C\n' placeholder='0.0' outlined='' type="number" v-model="signosVitales.imc")
            v-col(cols='6' sm='6' md='3')
              v-text-field(label='Perímetro abdominal' placeholder='cm' outlined='' type="number" v-model="signosVitales.perimetroAbdominal")
          h4(style="color: primary; padding:0 1.5rem;") Mediciones Capilares
          v-row
            v-col(cols='12' sm='6' md='4')
              v-text-field(label='Glucosa capilar' placeholder='mg/dl' outlined='' type="number" v-model="signosVitales.glucosaCapilar")
            v-col(cols='6' sm='6' md='4')
              v-text-field(label='Valor de hemoglobina' placeholder='g/dl' outlined='' type="number" v-model="signosVitales.valorHemoglobina")
            v-col(cols='6' sm='6' md='4')
              v-text-field(label='Valor de hemoglobina corregido' placeholder='g/dl' outlined='' type="number" v-model="signosVitales.valorHemoglobinaCorregido")
          v-row(style="padding-top: 40px;")
            v-col(cols="12" md="8")
              v-textarea(label='Observaciones' height="32px" v-model="signosVitales.obs")
            v-col(cols="12" md="4")
              v-text-field(label='Fecha de Registro' disabled v-model="signosVitales.currentDate")
</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import 'firebase/database'
  import * as functions from '../../../functions.js'
  export default {
    components: {
      Patient: () => import('@/views/dashboard/component/patient/PatientProfile'),
    },
    props: {
      patientSelected: null,
      idPatient: null,
    },
    data: () => ({
      loadingSaveData: false,
      selectedPatient: {},
      dialogPatient: false,
      confirmationDialog: false,
      signoVitalSelected: {},
      progressBar: false,
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      patientLstFilter: [],
      searchTable: '',
      patientID: '',
      signosID: '',
      valuePatient: null,
      arrSignosVitalesLst: [],
      arrSignosVitalesLstComplete: [],
      signosVitales: {
        presionArterialSistolica: '',
        presionArterialDiastolica: '',
        presionArterialMedia: '',
        temperatura: '',
        frecuenciaRespiratoria: '',
        frecuenciaCardiaca: '',
        saturacionOxigeno: '',
        peso: '',
        tallaEstatura: '',
        imc: '',
        perimetroAbdominal: '',
        glucosaCapilar: '',
        valorHemoglobina: '',
        valorHemoglobinaCorregido: '',
        patientCompletData: '',
        currentDate: '',
        obs: '',
      },
    }),
    computed: {
      patientLst () {
        return this.$store.state.patients.pacientes
      },
    },
    watch: {
      patientLst () {
        this.patientLstFilter = this.patientLst
        if (this.patientSelected != null) {
          this.valuePatient = {
            data: {
              data: this.patientSelected,
            },
            id: this.idPatient,
          }
        } else {
          this.valuePatient = this.patientLstFilter[0]
        }
        this.getDataSignos()
      },
    },
    created () {
      // if (this.patientLst === null) {
      //   this.newPatient()
      // }
      this.$store.dispatch('getCurrentDay').then(res => {
        this.signosVitales.currentDate = res
      })
      this.patientLstFilter = this.$store.state.patients.pacientes
      if (this.patientSelected != null) {
        this.valuePatient = {
          data: {
            data: this.patientSelected,
          },
          id: this.idPatient,
        }
      } else {
        this.valuePatient = this.patientLstFilter[0]
      }
      this.getDataSignos()
    },
    methods: {
      downLoadExcelFile () {
        const data = []
        if (this.arrSignosVitalesLstComplete) {
          for (let i = 0; i < this.arrSignosVitalesLstComplete.length; i++) {
            data.push({
              Fecha: this.arrSignosVitalesLstComplete[i].data.data.currentDate,
              'Frecuencia cardiaca': this.arrSignosVitalesLstComplete[i].data.data.frecuenciaCardiaca,
              'Frecuencia respiratoria': this.arrSignosVitalesLstComplete[i].data.data.frecuenciaRespiratoria,
              'Glucosa Capilar': this.arrSignosVitalesLstComplete[i].data.data.glucosaCapilar,
              Imc: this.arrSignosVitalesLstComplete[i].data.data.imc,
              Observaciones: this.arrSignosVitalesLstComplete[i].data.data.obs,
              'Datos del paciente': this.arrSignosVitalesLstComplete[i].data.data.patientCompletData,
              'Perímetro abdominal': this.arrSignosVitalesLstComplete[i].data.data.perimetroAbdominal,
              Peso: this.arrSignosVitalesLstComplete[i].data.data.peso,
              'Presión arterial distolica': this.arrSignosVitalesLstComplete[i].data.data.presionArterialDiastolica,
              'Presión arterial media': this.arrSignosVitalesLstComplete[i].data.data.presionArterialMedia,
              'Presión arterial sistólica': this.arrSignosVitalesLstComplete[i].data.data.presionArterialSistolica,
              'Presión arterial Saturación de oxígeno': this.arrSignosVitalesLstComplete[i].data.data.saturacionOxigeno,
              estatura: this.arrSignosVitalesLstComplete[i].data.data.tallaEstatura,
              temperatura: this.arrSignosVitalesLstComplete[i].data.data.temperatura,
              Hemoglobina: this.arrSignosVitalesLstComplete[i].data.data.valorHemoglobina,
              'Hemoglobina Corregido': this.arrSignosVitalesLstComplete[i].data.data.valorHemoglobinaCorregido,
            })
          }
          functions.JSONToCSVConvertor(data, 'Signos_Vitales', true)
        }
      },
      newPatient () {
        this.selectedPatient = null
        this.dialogPatient = true
      },
      viewPatient () {
        if (this.valuePatient) {
          this.idPatient = this.valuePatient.id
          this.selectedPatient = this.valuePatient.data.data
          this.dialogPatient = true
        }
      },
      saveData () {
        this.loadingSaveData = true
        const currentUser = firebase.auth().currentUser
        this.$store.dispatch('getCurrentDay').then(res => {
          this.signosVitales.currentDate = res
          if (this.patientID === '' && this.signosID === '') {
            if (this.valuePatient) {
              this.signosVitales.patientCompletData = this.valuePatient.data.data.completeData
              const user = this.$store.state.users.user
              firebase.database().ref(`/${user.selectAccount}/signos-vitales/${currentUser.uid}/${this.valuePatient.id}/`).push(
                {
                  data: this.signosVitales,
                }).then((res) => {
                this.getDataSignos()
                this.loadingSaveData = false
                this.snackbar = {
                  show: true,
                  color: 'green',
                  text: 'Signos Vitales guardados correctamente',
                }
                this.cleanData()
              }).catch(() => {
                this.loadingSaveData = false
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al guardar los signos vitales',
                }
              })
            } else {
              this.loadingSaveData = false
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Debes seleccionar el paciente para continuar',
              }
            }
          } else {
            const user = this.$store.state.users.user
            firebase.database().ref(`/${user.selectAccount}/signos-vitales/${currentUser.uid}/${this.patientID}/${this.signosID}/data/`).set(
              this.signosVitales).then((res) => {
              this.getDataSignos()
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Signo Vital guardado correctamente',
              }
              this.loadingSaveData = false
              this.cleanData()
            })
          }
        })
      },
      cleanData () {
        this.signosVitales = {
          presionArterialSistolica: '',
          presionArterialDiastolica: '',
          presionArterialMedia: '',
          temperatura: '',
          frecuenciaRespiratoria: '',
          frecuenciaCardiaca: '',
          saturacionOxigeno: '',
          peso: '',
          tallaEstatura: '',
          imc: '',
          perimetroAbdominal: '',
          glucosaCapilar: '',
          valorHemoglobina: '',
          valorHemoglobinaCorregido: '',
          patientCompletData: '',
          currentDate: '',
        }
        this.patientID = ''
        this.signosID = ''
      },
      getDataSignos () {
        this.progressBar = true
        this.arrSignosVitalesLst = []
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/signos-vitales/${currentUser.uid}/`).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                return { id: cou, data: Object.keys(res[cou]).map(item => { return { id: item, data: res[cou][item] } }) }
              })
              for (let i = 0; i < arr.length; i++) {
                for (let j = 0; j < arr[i].data.length; j++) {
                  arr[i].data[j].idPatient = arr[i].id
                  this.arrSignosVitalesLst.push(arr[i].data[j])
                }
              }
              this.arrSignosVitalesLstComplete = this.arrSignosVitalesLst
              this.changePatient()
            }
            this.progressBar = false
          }
        })
      },
      selectSignoVital (signo) {
        this.signosVitales = signo.data.data
        this.patientID = signo.idPatient
        this.signosID = signo.id
      },
      changePatient () {
        this.arrSignosVitalesLst = this.arrSignosVitalesLstComplete.filter(item => {
          return (item.data.data.patientCompletData === this.valuePatient.data.data.completeData)
        })
      },
      searchPatient () {
        this.patientLstFilter = this.patientLst.filter(
          patient => {
            return ((patient.data.data.id.toUpperCase().includes(this.searchTable.toUpperCase())) ||
              (patient.data.data.names.toUpperCase().includes(this.searchTable.toUpperCase())) ||
              (patient.data.data.lastNames.toUpperCase().includes(this.searchTable.toUpperCase())) ||
              (patient.data.data.phone1.toUpperCase().includes(this.searchTable.toUpperCase())) ||
              (patient.data.data.phone2.toUpperCase().includes(this.searchTable.toUpperCase())))
          })
      },
      deleteSignoVital (signovital) {
        this.confirmationDialog = true
        this.signoVitalSelected = signovital
      },
      acceptConfirmation () {
        const currentUser = firebase.auth().currentUser
        const user = this.$store.state.users.user
        firebase.database().ref(`/${user.selectAccount}/signos-vitales/${currentUser.uid}/${this.signoVitalSelected.idPatient}/${this.signoVitalSelected.id}/`).remove().then(() => {
          this.getDataSignos()
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Signo Vital eliminado correctamente',
          }
          this.cleanData()
          this.confirmationDialog = false
        })
      },
      finishCreate () {
        this.dialogPatient = false
      },
    },
  }
</script>
<style>
  .v-card > *:first-child:not(.v-btn):not(.v-chip):not(.v-avatar), .v-card > .v-card__progress + *:not(.v-btn):not(.v-chip):not(.v-avatar){
    font-family: 'ceraProMedium';
  }
  .parrafo-division {
    color: green;
    text-align: center;
    padding-top: 15px;
    font-size: 20px;
    font-weight: bold;
  }
</style>
