<template lang="pug">
  v-container#upgrade(fluid='' tag='section' style='padding:0px;')
    v-dialog(v-model='confirmationDialog' persistent max-width='290')
      v-card
        v-card-title.headline
          | Confirmar
        v-card-text Estás seguro de eliminar este registro?
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='confirmationDialog = false')
              | Cancelar
            v-btn(color='blue darken-1' @click='deleteRegister')
              | Aceptar
    v-snackbar(v-model='snackbar.show' :timeout='30000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='30000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='')
    //-     v-icon mdi-close
    v-dialog(v-model='newRegisterDialog' persistent max-width='400')
      v-card
        v-card-title.headline
          | Nota
        v-card-text
          v-form(ref='formObservation' v-model='validObservation' lazy-validation='')
            v-row
              v-col(cols="12" md="12")
                //- v-textarea(:rules='[rules.required]' label='Comentario:' v-model='observation.observation' style='background: ghostwhite;')
                vue-editor(class='inside-box' :rules='[rules.required]' label='Comentario:' v-model='observation.observation')
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='newRegisterDialog = false')
              | CANCELAR
            v-btn(color='primary' @click="addObservationPatient" :loding="loadingNew")
              | GUARDAR
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title NOTAS DEL PACIENTE
          v-btn(icon="" dark="" @click="$emit('exitObservation')" style="position: absolute; top: 0px; right: 10px;")
            v-icon.primary.rounded mdi-close
      v-card-text
        div.text-center(v-if="observationsLst.length == 0")
          h3(style="color: #75756E;") Este paciente aún no tiene notas
        div.text-left
          div(v-if="observationsLst.length > 0" v-for='(observation, index) in observationsLst' :key='index' style="padding: 10px;")
            v-card(:outlined="false")
              vue-editor(class='out-box' :rules='[rules.required]' label='Comentario:' v-model='observation.data.data.observation')
              //- v-card-text
              //-   | {{observation.data.data.observation.toUpperCase()}}
              v-card-actions.text-right
                v-spacer
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(v-bind='attrs' v-on='on' icon='' color='blue' small='' @click="editObservation(observation)")
                        v-icon mdi-tooltip-edit
                    span Editar
                  v-tooltip(bottom)
                    template(v-slot:activator="{ on, attrs }")
                      v-btn(v-bind='attrs' v-on='on' icon='' color='red' small='' @click='confimationDelete(observation.id)' :loading="loadingDelete")
                        v-icon mdi-delete
                    span Eliminar
        div.text-right
          v-btn.mr-0(color='primary' @click="addNewRegister")
            | AGREGAR NOTA
</template>
<script>
  export default {
    props: {
      selectedPatient: null,
    },
    data: () => ({
      rules: {
        required: value => !!value || 'Requerido',
      },
      validObservation: true,
      idSelectObservation: null,
      confirmationDialog: false,
      patientId: '',
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      loadingNew: false,
      loadingDelete: false,
      newRegisterDialog: false,
      observation: {
        observation: '',
      },
      idObservation: '',
    }),
    computed: {
      observationsLst () {
        return this.$store.state.patients.observaciones
      },
    },
    mounted () {
      if (this.selectedPatient) {
        this.patientId = this.selectedPatient
        this.$store.dispatch('patients/loadObservationLst', this.patientId)
      }
    },
    methods: {
      editObservation (observation) {
        this.newRegisterDialog = true
        this.observation = observation.data.data
        this.idSelectObservation = observation.id
      },
      confimationDelete (idObservation) {
        this.confirmationDialog = true
        this.idObservation = idObservation
      },
      deleteRegister () {
        this.loadingDelete = true
        this.confirmationDialog = false
        const data = {
          patientId: this.patientId,
          idObservation: this.idObservation,
        }
        this.$store.dispatch('patients/deleteObservation', data).then(res => {
          this.loadingDelete = false
          if (res) {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Registro eliminado correctamente',
            }
            if (this.selectedPatient) {
              this.patientId = this.selectedPatient
              this.$store.dispatch('patients/loadObservationLst', this.patientId)
            }
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al eliminar el registro',
            }
          }
        }, () => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al eliminar el registro',
          }
        })
      },
      addObservationPatient () {
        if (this.$refs.formObservation.validate()) {
          if (this.observation.observation !== '' && this.observation.observation !== null) {
            this.loadingNew = true
            if (this.idSelectObservation) {
              const data = {
                patientId: this.patientId,
                observation: this.observation,
                idObservation: this.idSelectObservation,
              }
              this.$store.dispatch('patients/updateObservationPatient', data).then(res => {
                this.loadingNew = false
                this.newRegisterDialog = false
                this.idSelectObservation = null
                if (res) {
                  this.snackbar = {
                    show: true,
                    color: 'green',
                    text: 'Registro guardado correctamente',
                  }
                  if (this.selectedPatient) {
                    this.patientId = this.selectedPatient
                    this.$store.dispatch('patients/loadObservationLst', this.patientId)
                  }
                } else {
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al guardar el registro',
                  }
                }
              }, () => {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al guardar el registro',
                }
              })
            } else {
              const data = {
                patientId: this.patientId,
                observation: this.observation,
              }
              this.$store.dispatch('patients/addObservationPatient', data).then(res => {
                this.loadingNew = false
                this.newRegisterDialog = false
                this.idSelectObservation = null
                if (res) {
                  this.snackbar = {
                    show: true,
                    color: 'green',
                    text: 'Registro guardado correctamente',
                  }
                  if (this.selectedPatient) {
                    this.patientId = this.selectedPatient
                    this.$store.dispatch('patients/loadObservationLst', this.patientId)
                  }
                } else {
                  this.snackbar = {
                    show: true,
                    color: 'red',
                    text: 'Error al guardar el registro',
                  }
                }
              }, () => {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Error al guardar el registro',
                }
              })
            }
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Debes agregar el comentario para continuar',
            }
          }
        }
      },
      addNewRegister () {
        this.newRegisterDialog = true
        this.idSelectObservation = null
        this.observation = {
          observation: '',
        }
      },
    },
  }
</script>
<style>
.out-box .ql-toolbar.ql-snow {
  display: none;
}
.out-box .ql-editor {
  background: white;
  min-height: 50px;
}
.inside-box .ql-editor {
  min-height: 150px;
  font-size: 16px;
}
.out-box .ql-toolbar.ql-snow {
  border: none;
}
.out-box .ql-container.ql-snow{
  border:none;
}
</style>
