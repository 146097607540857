<template>
  <div id="settings-wrapper">
    <v-snackbar
      v-model="snackbar.show"
      :multi-line="multiLine"
      :color="snackbar.color"
      :timeout="3000"
      top=""
      right=""
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          @click="snackbar.show = false"
          dark=''
          icon=''
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <v-snackbar
      v-model="snackbar.show"
      :timeout="3000"
      top=""
      right=""
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn
        dark=""
        icon=""
        @click.native="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar> -->
    <v-card
      id="settings"
      class="py-2 px-4"
      color="rgba(0, 0, 0, .3)"
      dark
      flat
      link
      min-width="100"
      style="position: fixed; top: 115px; right: -35px; border-radius: 8px;"
    >
      <v-icon large>
        mdi-animation
      </v-icon>
    </v-card>

    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      activator="#settings"
      bottom
      content-class="v-settings"
      left
      nudge-left="8"
      offset-x
      origin="top right"
      transition="scale-transition"
    >
      <v-card
        class="text-center mb-0"
        width="300"
      >
        <v-card-text>
          <strong class="mb-3 d-inline-block">ELIGE TU COLOR FAVORITO</strong>

          <v-item-group v-model="color">
            <v-item
              v-for="color in colors"
              :key="color"
              :value="color"
            >
              <template v-slot="{ active, toggle }">
                <v-avatar
                  :class="active && 'v-settings__item--active'"
                  :color="color"
                  class="v-settings__item"
                  size="25"
                  @click="toggle"
                />
              </template>
            </v-item>
          </v-item-group>

          <v-divider class="my-4 secondary" />

          <!-- <v-row
            align="center"
            no-gutters
          >
            <v-col cols="auto">
              Modo Oscuro
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="$vuetify.theme.dark"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" /> -->

          <v-row>
            <v-col cols="auto">
              Imagen Lateral
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="showImg"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <v-row>
            <v-col cols="auto">
              Visualizar Tour
            </v-col>

            <v-spacer />

            <v-col cols="auto">
              <v-switch
                v-model="showTour"
                class="ma-0 pa-0"
                color="secondary"
                hide-details
              />
            </v-col>
          </v-row>

          <v-divider class="my-4 secondary" />

          <strong
            class="mb-3 d-inline-block"
          >ELIGE TU IMAGEN FAVORITA</strong>

          <v-item-group
            v-model="image"
            class="d-flex justify-space-between mb-3"
          >
            <v-item
              v-for="image in images"
              :key="image"
              :value="image"
              class="mx-1"
            >
              <template v-slot="{ active, toggle }">
                <v-sheet
                  :class="active && 'v-settings__item--active'"
                  class="d-inline-block v-settings__item"
                  @click="toggle"
                >
                  <v-img
                    :src="image"
                    height="100"
                    width="50"
                  />
                </v-sheet>
              </template>
            </v-item>
          </v-item-group>

          <!--<v-btn-->
          <!--block-->
          <!--class="mb-3"-->
          <!--color="primary"-->
          <!--href="https://www.creative-tim.com/product/vuetify-material-dashboard"-->
          <!--default-->
          <!--rel="noopener"-->
          <!--target="_blank"-->
          <!--&gt;-->
          <!--Free Download-->
          <!--</v-btn>-->

          <!--<v-btn-->
          <!--block-->
          <!--class="mb-3"-->
          <!--color="grey darken-1"-->
          <!--dark-->
          <!--href="https://vuetifyjs.com/components/api-explorer"-->
          <!--default-->
          <!--rel="noopener"-->
          <!--target="_blank"-->
          <!--&gt;-->
          <!--Documentation-->
          <!--</v-btn>-->

          <div class="my-12" />

          <!--<div>-->
          <!--<strong class="mb-3 d-inline-block">THANK YOU FOR SHARING!</strong>-->
          <!--</div>-->

          <!--<v-btn-->
          <!--class="ma-1"-->
          <!--color="#55acee"-->
          <!--dark-->
          <!--default-->
          <!--rounded-->
          <!--&gt;-->
          <!--<v-icon>mdi-twitter</v-icon>-->
          <!-- - 45-->
          <!--</v-btn>-->

          <!--<v-btn-->
          <!--class="ma-1"-->
          <!--color="#3b5998"-->
          <!--dark-->
          <!--default-->
          <!--rounded-->
          <!--&gt;-->
          <!--<v-icon>mdi-facebook</v-icon>-->
          <!-- - 50-->
          <!--</v-btn>-->
          <v-btn
            class="mr-0"
            color="primary"
            rounded=""
            @click="saveData"
          >
            GUARDAR
          </v-btn>
        </v-card-text>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
  // Mixins
  import Proxyable from 'vuetify/lib/mixins/proxyable'
  import { mapMutations, mapState } from 'vuex'

  export default {
    name: 'DashboardCoreSettings',

    mixins: [Proxyable],

    data: () => ({
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
      color: '0591a3',
      colors: [
        '#9C27b0',
        '#0591a3',
        '#4CAF50',
        '#ff9800',
        '#E91E63',
        '#FF5252',
        '#E22E2E',
      ],
      image: 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/sidebar%2Fconsultorio-dentistas.jpg?alt=media&token=62082fe0-04f4-4604-8bc3-7dc2a1c883c5',
      images: [
        'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/sidebar%2Fconsultorio-dentistas.jpg?alt=media&token=62082fe0-04f4-4604-8bc3-7dc2a1c883c5',
        'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/sidebar%2Fbanner-img1.jpg?alt=media&token=51e78633-fd7f-4a37-bebb-ad796b9cca67',
        'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/sidebar%2Fdentalrays.jpg?alt=media&token=d94b30c7-b922-4ac1-9b44-f2afa6a4a422',
        'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/sidebar%2Famalgama_DES.jpg?alt=media&token=e2eab0f8-0fbb-4fcc-a5d9-78d92478fa5d',
      ],
      menu: false,
      saveImage: '',
      showImg: true,
      showTour: false,
    }),

    computed: {
      ...mapState(['barImage']),
      colorSelect () {
        return this.$store.state.configuraciones.configs.color
      },
      imageSelect () {
        return this.$store.state.configuraciones.configs.image
      },
      viewTour () {
        return this.$store.state.configuraciones.configs.showTour
      },
    },

    watch: {
      viewTour () {
        this.showTour = this.viewTour
      },
      showTour () {
        if (this.showTour) {
          this.$tours.myTour.start()
        } else {
          this.$tours.myTour.finish()
        }
      },
      colorSelect () {
        this.color = this.colorSelect
      },
      imageSelect () {
        this.image = this.imageSelect
      },
      color (val) {
        this.$vuetify.theme.themes[this.isDark ? 'dark' : 'light'].primary = val
      },
      showImg (val) {
        if (!val) {
          this.saveImage = this.barImage
          this.setBarImage('')
        } else if (this.saveImage) {
          this.setBarImage(this.saveImage)
          this.saveImage = ''
        } else {
          this.setBarImage(val)
        }
      },
      image (val) {
        this.setBarImage(val)
      },
    },

    methods: {
      ...mapMutations({
        setBarImage: 'SET_BAR_IMAGE',
      }),
      saveData () {
        const data = {
          color: this.color,
          image: this.image,
          showTour: this.showTour,
        }
        this.$store.dispatch('configuraciones/setConfig', data).then(res => {
          if (res) {
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Configuración guardada correctamente',
            }
          }
        }, reject => {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al guardar la configuración',
          }
        })
      },
    },
  }
</script>

<style lang="scss">
  .v-card > *:last-child:not(.v-btn):not(.v-chip) {
    justify-content: initial;
  }
</style>
<style lang="sass">
  .v-settings
    .v-item-group > *
      cursor: pointer

    &__item
      border-width: 3px
      border-style: solid
      border-color: transparent !important

      &--active
        border-color: #00cae3 !important
</style>
