import * as ws from '../../../WebService'
// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'
import axios from 'axios'
import * as vars from '../../../vars.json'
// var functions = require('../../../functions/functions')

const httpAxios = axios.create({
  params: {},
})

export const facturacion = {
  namespaced: true,
  state: {
    tokenAdmin: null,
    tokenUser: null,
    companydata: null,
    electronicbillingtables: null,
    lstInecCodes: [],
  },
  mutations: {
    SET_TOKEN_ADMIN (state, payload) {
      state.tokenAdmin = payload
    },
    SET_TOKEN_USER (state, payload) {
      state.tokenUser = payload
    },
    SET_COMPANY_DATA (state, payload) {
      state.companydata = payload
    },
    SET_ELECTRONIC_BILLING_TABLES (state, payload) {
      state.electronicbillingtables = payload
    },
    SET_LST_INEC_CODES (state, payload) {
      state.lstInecCodes = payload
    },
  },
  actions: {
    addCompanyData ({ commit, rootState }, companydata) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`companydata/${rootState.users.user.uid}`).set(companydata).then((res) => {
          resolve(res)
        }).catch((error) => {
          reject(error)
        })
      })
    },
    loadCompanyData ({ commit, rootState }) {
      firebase.database().ref(`companydata/${rootState.users.user.uid}`).on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            commit('SET_COMPANY_DATA', res)
          } else {
            commit('SET_COMPANY_DATA', null)
          }
        } else {
          commit('SET_COMPANY_DATA', null)
        }
      })
    },
    loadelEctronicBillingTables ({ commit, rootState }) {
      firebase.database().ref('electronicbillingtables').on('value', (snapshot) => {
        if (snapshot) {
          const res = snapshot.val()
          if (res) {
            commit('SET_ELECTRONIC_BILLING_TABLES', res)
          } else {
            commit('SET_ELECTRONIC_BILLING_TABLES', null)
          }
        } else {
          commit('SET_ELECTRONIC_BILLING_TABLES', null)
        }
      })
    },
    adminLoginFac ({ commit }) {
      return new Promise((resolve, reject) => {
         const dataSend = {
           url: ws.init.oauth,
         }
        httpAxios.post(vars.url + '/httpLoginAdminFac', dataSend).then(res => {
          const adminToken = res.data
          commit('SET_TOKEN_ADMIN', adminToken)
          resolve(adminToken)
        }, error => {
          reject(error)
        })
      })
    },
    userLoginFac ({ commit }, [user, password]) {
      return new Promise((resolve, reject) => {
        const dataSend = {
          url: ws.init.oauth,
          user: user.toLowerCase(),
          password: password,
        }
        httpAxios.post(vars.url + '/httpLoginUserFac', dataSend).then(res => {
          const userToken = res.data
          commit('SET_TOKEN_USER', userToken)
          resolve(userToken)
        }, error => {
          reject(error)
        })
      })
    },
    userLoginFacInTo ({ commit }, [user, password]) {
      return new Promise((resolve, reject) => {
        const dataSend = {
          url: ws.init.oauth,
          user: user.toLowerCase(),
          password: password,
        }
        httpAxios.post(vars.url + '/httpLoginUserFac', dataSend).then(res => {
          const userToken = res.data
          commit('SET_TOKEN_USER', userToken)
          resolve(userToken)
        }, error => {
          resolve(error)
        })
      })
    },
    createCompany ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const urlPost = ws.company.create
        const dataSend = {
          url: urlPost,
          body: data.body,
          token: data.token,
        }
        httpAxios.post(vars.url + '/httpPostBack', dataSend).then(res => {
          resolve(res.data)
        }, error => {
          reject(error)
        })
      })
    },
    createCertificado ({ commit }, data) {
      return new Promise((resolve, reject) => {
        let urlPost = ws.certificado.create
        urlPost = urlPost.toString().replace('{numeroRuc}', data.ruc)
        const body = {
          certificado: data.certificado,
          password: data.password,
        }
        const dataSend = {
          url: urlPost,
          body: body,
          token: data.token,
        }
        httpAxios.post(vars.url + '/httpPostBack', dataSend).then(res => {
          resolve(res)
        }, error => {
          reject(error)
        })
      })
    },
    getPdfFacValid ({ commit }, data) {
      return new Promise((resolve, reject) => {
        // let urlGet = ws.billing.getPdf
        // urlGet = urlGet.replace('{clave-acceso}', data.accesKey)
        axios.defaults.headers.common = { Authorization: `bearer ${data.token}` }
        axios({
          url: vars.url + '/httpGetPdf/' + data.accesKey + '/' + data.token + '/' + data.aditionalField,
          method: 'get',
          responseType: 'blob', // important
        }).then((response) => {
          const blobData = new Blob([response.data])
          const url = window.URL.createObjectURL(blobData)
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', data.accesKey + '.pdf')
          document.body.appendChild(link)
          link.click()
          resolve(true)
        }, error => {
          reject(error)
        })
      })
    },
    createUser ({ commit }, data) {
      return new Promise((resolve, reject) => {
        const urlPost = ws.init.createUser
        const dataSend = {
          url: urlPost,
          body: data.body,
          token: data.token,
        }
        httpAxios.post(vars.url + '/httpPostBack', dataSend).then(res => {
          resolve(res.data)
        }, error => {
          reject(error)
        })
      })
    },
    getComanyImg ({ commit }, url) {
      return new Promise((resolve, reject) => {
        const dataSend = {
          url: url,
        }
        httpAxios.post(vars.url + '/getImgCompany', dataSend).then(res => {
          resolve(res.data)
        }, error => {
          reject(error)
        })
      })
    },
    getInecCodes ({ commit }, data) {
      return new Promise((resolve, reject) => {
        if (data.filterData === '' || !data.filterData) {
          data.filterData = 'EMPTY'
        }
        httpAxios.get(vars.url + '/httpGetBack/' + data.filterData + '/' + data.token).then(res => {
          commit('SET_LST_INEC_CODES', res.data)
          resolve(res.data)
        }, error => {
          commit('SET_LST_INEC_CODES', [])
          reject(error)
        })
      })
    },
  },
  getters: {
    name: (state) => {
      return state.electronicbillingtables
    },
  },
}
