// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const profile = {
  namespaced: true,
  state: {
    profileLoaded: null,
    lstProfiles: [],
  },
  mutations: {
    LOAD_PROFILE: (state, data) => {
      state.profileLoaded = data
    },
    SET_DATA_RPOFILE: (state) => {
      state.profileLoaded.rucEstablecimieto = ''
      state.profileLoaded.razonSocial = ''
    },
    LOAD_PROFILES_BASE: (state, data) => {
      state.lstProfiles = data
    },
  },
  actions: {
    getLstProfiles ({ commit }) {
      return new Promise((resolve, reject) => {
        firebase.database().ref('users').orderByChild('rucEstablecimieto').on('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              const arrSend = []
              for (let i = 0; i < arr.length; i++) {
                if (arr[i].rucEstablecimieto) {
                  arrSend.push(arr[i])
                }
              }
              commit('LOAD_PROFILES_BASE', arrSend)
            } else {
              commit('LOAD_PROFILES_BASE', [])
            }
          } else {
            commit('LOAD_PROFILES_BASE', [])
          }
        })
      })
    },
    setDataProfile ({ commit }) {
      commit('SET_DATA_RPOFILE')
    },
    setProfile ({ state, rootState }, profileData) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${rootState.users.user.uid}`).set(profileData).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    setProfileValuidfac ({ state, rootState }, valuidfac) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${rootState.users.user.uid}/valuidfac`).set(valuidfac).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadProfile ({ commit, rootState }) {
      return new Promise((resolve, reject) => {
        if (rootState.users.user) {
          firebase.database().ref(`users/${rootState.users.user.uid}`).on('value', (snapshot) => {
            if (snapshot) {
              const res = snapshot.val()
              if (res) {
                commit('LOAD_PROFILE', res)
                resolve(res)
              } else {
                resolve(null)
              }
            }
          })
        }
      })
    },
    loadProfileRegister ({ commit }, uidUser) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`users/${uidUser}`).on('value', (snapshot) => {
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              resolve(res)
            } else {
              resolve(null)
            }
          }
        })
      })
    },
  },
}
