// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const odontogram = {
  namespaced: true,
  state: {
    patientSelectedId: '',
  },
  mutations: {
    SET_PATIENTSELECTED_ID: (state, data) => {
      state.patientSelectedId = data
    },
  },
  actions: {
    saveProcess ({ state, rootState }, [currentDrId, currentPatientId, processKey, processData]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/processes/${currentDrId}/${currentPatientId}/${processKey}`).set({ data: JSON.stringify(processData) }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    editProcess ({ state, rootState }, [currentDrId, currentPatientId, processKey, processData]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/processes/${currentDrId}/${currentPatientId}/${processKey}`).set({ data: JSON.stringify(processData) }).then((res) => {
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    getProcessData ({ state, rootState }, [patientId, processId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/processes/${rootState.users.user.uid}/${patientId}/${processId}/`).once('value', (snapshot) => {
          if (snapshot.val()) {
            const res = snapshot.val()
            let arr = []
            if (res) {
              arr = Object.keys(res).map(cou => {
                return { id: cou, data: res[cou] }
              })
            }
            resolve(arr)
          } else {
            resolve([])
          }
        })
      })
    },
    deleteProcess ({ state, rootState }, [patientId, processId]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`${rootState.users.user.selectAccount}/processes/${rootState.users.user.uid}/${patientId}/${processId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
    loadPatientSelectedId ({ commit, rootState, state }) {
      commit('SET_PATIENTSELECTED_ID', state.patientSelectedId)
    },
    setPatientSelectedId ({ commit, state, rootState }, patientId) {
      commit('SET_PATIENTSELECTED_ID', patientId)
    },
  },
  getters: {
  },
}
