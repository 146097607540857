<template lang="pug">
  v-container#inv(fluid='' tag='section' style="padding: 0px;")
    v-dialog(v-model='dialogEntry' max-width='400')
      v-card
        v-card-title.text-h5
          h4 Nuevo Ingreso
        v-card-text(style='padding-right:0; padding-bottom:0;')
          //- <!--| Agregue los datos del registro del nuevo ingreso. Verificar el monto agregado.-->
          v-row(style="width: 100%;")
            v-col(cols="8" md="8")
              v-menu(ref='menu1' v-model='menu1' :close-on-content-click='false' :return-value.sync='date1' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='date1' label='Fecha' prepend-icon='mdi-calendar' v-on='on' :disabled="disablesEntry")
                v-date-picker(v-model='date1' no-title='' scrollable='' :disabled="disablesEntry" style='margin:0px;' color="green lighten-1")
                  v-spacer
                  v-btn(text='' color='primary' @click='menu1 = false') Cancelar
                  v-btn(color='primary' @click='$refs.menu1.save(date1)') Listo
            v-col(cols="4" md="4")
              v-text-field(label='Mes' disabled v-model="monthEntry")
            v-col(cols="6" md="4")
              v-text-field(label='Monto' v-model="amountEntry" type="text" @keypress="isNumber($event)" :disabled="disablesEntry")
            v-col(cols="6" md="8")
              v-text-field(label='Motivo' v-model="motivoEntry" :disabled="disablesEntry")
            v-col(cols="12")
              v-textarea(solo='' label='Descripción' height='70' v-model="descriptionEntry" :disabled="disablesEntry")
            v-col(cols="12" v-if="disablesEntry")
              v-alert(type='warning')
                | Ingreso generado a partir de un recibo. Para modificar el mismo debe realizarlo desde el recibo creado.
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='dialogEntry = false')
            | CANCELAR
          v-btn(color='primary' @click='saveEntry' :disabled="disablesEntry" :loading="loadingSaveEntry")
            | GUARDAR
    v-dialog(v-model='dialogEgress' max-width='400')
      v-card
        v-card-title.text-h5
          h4 Nuevo Egreso
        v-card-text
          //- <!--| Agregue los datos del registro del nuevo egreso. Verificar el monto agregado.-->
          v-row(style="width: 100%;")
            v-col(cols="8" md="8")
              v-menu(ref='menu' v-model='menu' :close-on-content-click='false' :return-value.sync='date' transition='scale-transition' offset-y='' min-width='290px')
                template(v-slot:activator='{ on }')
                  v-text-field(readonly v-model='date' label='Fecha' prepend-icon='mdi-calendar' v-on='on')
                v-date-picker(v-model='date' no-title='' scrollable='' style='margin:0px;' color="green lighten-1")
                  v-spacer
                  v-btn(text='' color='primary' @click='menu = false') Cancelar
                  v-btn(color='primary' @click='$refs.menu.save(date)') Listo
            v-col(cols="4" md="4")
              v-text-field(label='Mes' disabled v-model="monthEgress")
            v-col(cols="6" md="4")
              v-text-field(label='Monto' v-model="amountEgress" type="text" @keypress="isNumber($event)")
            v-col(cols="6" md="8")
              v-text-field(label='Motivo' v-model="motivoEgress")
            v-col(cols="12")
              v-textarea(solo='' label='Descripción' height='70' v-model="descriptionEgress")
        v-card-actions
          v-spacer
          v-btn(color='red darken-1' text='' @click='dialogEgress = false')
            | CANCELAR
          v-btn(color='primary' @click='saveEgress' :loading="loadingSaveEgress")
            | GUARDAR
    v-dialog(v-model='confirmationDialogEntry' persistent max-width='290')
      v-card
        v-card-title.text-h5
          | Confirmar
        v-card-text Estás seguro de eliminar este registro?
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='confirmationDialogEntry = false')
              | Cancelar
            v-btn(color='primary' @click='acceptConfirmationEntry')
              | Aceptar
    v-dialog(v-model='confirmationDialogEgress' persistent max-width='290')
      v-card
        v-card-title.text-h5
          | Confirmar
        v-card-text Estás seguro de eliminar este registro?
        v-card-actions
          v-spacer
            v-btn(color='red darken-1' text='' @click='confirmationDialogEgress = false')
              | Cancelar
            v-btn(color='primary' @click='acceptConfirmationEgress')
              | Aceptar
    v-snackbar(v-model='snackbar.show' :timeout='5000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
    //-     v-icon mdi-close
    v-card
      v-row(style='margin:0px;')
        v-tabs(v-model="tabsStatistics" grow="" icons-and-text="" height="35px")
          v-tab(ripple href='#tab-1')   Ingresos y Egresos
          v-tab(ripple href='#tab-2')   Reportería
          v-tab-item(value='tab-1')
            v-row(style="max-width: 100%; margin-top: 30px;")
              v-col(cols='12' md='12' style="padding: 0px;")
                v-card-text(style='padding-top:0;')
                  v-row(style="max-width: 100%;")
                    v-col(cols='12' sm='6')
                      h2(style="padding-left: 30px; padding-top: 10px;")
                        | UTILIDAD: ${{valorNeto.toFixed(2)}}
                    v-col(cols='12' sm='6' style='display:flex; justify-content: center;')
                      v-btn(color='secondary' @click='downloadCSVFileTotal' :loading="loadingTotalDownload")
                        strong
                          | DESCARGAR TODOS LOS
                          br
                          | INGRESOS Y EGRESOS
                  v-container#process-table(fluid='' tag='section' style='padding: 0px 0px 10px 22px;')
                    v-row(style='display:flex; justify-content:center; max-width: 100%;')
                      v-col(cols="12" md="4" style="padding: 0px 0px 10px 22px;")
                        v-menu(ref='menuFrom' v-model='menuFrom' :close-on-content-click='false' :return-value.sync='date' transition='scale-transition' offset-y='' min-width='290px')
                          template(v-slot:activator='{ on }')
                            v-text-field(readonly v-model='dateFrom' label='Buscar Desde' prepend-icon='mdi-calendar' v-on='on')
                          v-date-picker(@change='$refs.menuFrom.save(dateFrom)' v-model='dateFrom' no-title='' scrollable='' :max="dateUntil" style='margin:0px;' color="green lighten-1")
                      v-col(cols="10" md="4" style="padding: 0px 0px 10px 22px;")
                        v-menu(ref='menuUntil' v-model='menuUntil' :close-on-content-click='false' :return-value.sync='date' transition='scale-transition' offset-y='' min-width='290px')
                          template(v-slot:activator='{ on }')
                            v-text-field(readonly v-model='dateUntil' label='Buscar Hasta' prepend-icon='mdi-calendar' v-on='on')
                          v-date-picker(@change='$refs.menuUntil.save(dateUntil)' v-model='dateUntil' no-title='' scrollable='' :min="dateFrom" style='margin:0px;' color="green lighten-1")
                      v-col(cols="2" style="padding: 0px 0px 10px 22px;")
                        v-tooltip(bottom='')
                          template(v-slot:activator='{ attrs, on }')
                            v-btn(v-on="on" icon='' color='gray' style="background-color: RGB(1, 125, 71);" @click="filterByDate")
                              v-icon(style="font-size: 30px; color: white;") mdi-magnify
                          span.tooltips Buscar
            v-row(style="max-width: 100%;")
              v-col(cols='12' md='6' style="padding: 0px 0px 10px 22px; display:flex; justify-content:center;")
                v-card(style="margin-top: 0px; margin-left:15px;" elevation='4')
                  v-card-text
                    v-row(style="max-width: 100%;")
                      v-col(cols='7' style='display:flex; justify-content:flex-end; padding:0px;')
                        .text-h3.font-weight-light
                          h3 INGRESOS
                      v-col(cols='5' style='display:flex; justify-content:flex-end; padding:0px;')
                        v-tooltip(bottom='')
                          template(v-slot:activator='{ attrs, on }')
                            v-btn(v-on="on" color='primary' icon='' @click='newEntry' style='font-size:20px; background: transparent;')
                              v-icon(style='font-size:45px;')
                                | mdi-plus-circle
                          span.tooltips Crear Ingreso
                        v-tooltip(bottom='')
                          template(v-slot:activator='{ attrs, on }')
                            v-btn(v-on="on" icon='' style='background: transparent;' color='secondary' @click='downloadCSVFileIngresos' :loading="loadingDownloadEntry")
                              v-icon(style="font-size:45px;") mdi-arrow-down-bold-box
                          span.tooltips Descargar Lista de Ingresos
                      v-container#process-table(fluid='' tag='section' style='padding: 0px 0px 10px 22px; margin-top: 20px;')
                        v-row(style='height: 30vh; max-width: 100%;')
                          v-col(cols='12' md='12' style='padding:0px; margin:0px; overflow: auto')
                            v-simple-table(style='height: 100px;' scrollable='')
                              thead
                                tr
                                  th.primary--text
                                    | Fecha
                                  th.primary--text
                                    | Mes
                                  th.primary--text
                                    | Descripción
                                  th.primary--text
                                    | Monto
                                  th.primary--text
                                    | Borrar
                              tbody
                                tr(v-for='(entry, index) in entryLst' :key='index')
                                  td(style="cursor: pointer;" @click="updateEntry(entry)")
                                    | {{ entry.date }}
                                  td(style="cursor: pointer;" @click="updateEntry(entry)")
                                    | {{ entry.month }}
                                  td(style="cursor: pointer;" @click="updateEntry(entry)")
                                    | {{ entry.description }}
                                  td(style="cursor: pointer;" @click="updateEntry(entry)")
                                    | $ {{ parseFloat(entry.amount).toFixed(2) }}
                                  td.text-center(style='cursor: pointer;')
                                    v-btn(icon='' style='background: transparent;' color='red' small='' @click="deleteEntry(entry.id)" :disabled="entry.documentId && entry.documentId !== '' ? true : false")
                                      v-icon mdi-delete
                      v-col(cols="12" md="6" offset-md="6")
                        h3 Total: ${{totalIngreso.toFixed(2)}}
              v-col(cols='12' md='6' style="padding: 0px 0px 10px 32px; display:flex; justify-content:center;")
                v-card(style="margin-top: 0px; margin-right:15px;" elevation='4')
                  v-card-text
                    v-row(style="max-width: 100%;")
                      v-col(cols='7' style='display:flex; justify-content:flex-end; padding:0px;')
                        .text-h3.font-weight-light
                          h3 EGRESOS
                      v-col(cols='5' style='display:flex; justify-content:flex-end; padding:0px;')
                        v-tooltip(bottom='')
                          template(v-slot:activator='{ attrs, on }')
                            v-btn(v-on="on" color='primary' icon='' @click='newEgress' style='font-size:20px; background: transparent;')
                              v-icon(style='font-size:45px;')
                                | mdi-plus-circle
                          span.tooltips Crear Egreso
                        v-tooltip(bottom='')
                          template(v-slot:activator='{ attrs, on }')
                            v-btn(v-on="on" icon='' style='background: transparent;' color='secondary' @click='downloadCSVFileEgresos' :loading="loadingDownloadEgress")
                              v-icon(style="font-size: 45px;") mdi-arrow-down-bold-box
                          span.tooltips Descargar Lista de Egresos
                      v-container#process-table(fluid='' tag='section' style='padding: 0px 0px 10px 22px; margin-top: 20px;')
                        v-row(style='height: 30vh; max-width: 100%;')
                          v-col(cols='12' md='12' style='padding:0px; margin:0px; overflow: auto')
                            v-simple-table(style='height: 100px;' scrollable='')
                              thead
                                tr
                                  th.primary--text
                                    | Fecha
                                  th.primary--text
                                    | Mes
                                  th.primary--text
                                    | Descripción
                                  th.primary--text
                                    | Monto
                                  th.primary--text
                                    | Borrar
                              tbody
                                tr(v-for='(egress, index) in egressLst' :key='index')
                                  td(style="cursor: pointer;" @click="updateEgress(egress)")
                                    | {{ egress.date }}
                                  td(style="cursor: pointer;" @click="updateEgress(egress)")
                                    | {{ egress.month }}
                                  td(style="cursor: pointer;" @click="updateEgress(egress)")
                                    | {{ egress.description }}
                                  td(style="cursor: pointer;" @click="updateEgress(egress)")
                                    | $ {{ parseFloat(egress.amount).toFixed(2) }}
                                  td.text-center(style='cursor: pointer;')
                                    v-btn(icon='' style='background: transparent;' color='red' small='' @click="deleteEgress(egress.id)" :disabled="egress.documentId && egress.documentId !== '' ? true : false")
                                      v-icon mdi-delete
                      v-col(cols="12" md="6" offset-md="6")
                        h3 Total: ${{totalEgreso.toFixed(2)}}
              v-col(cols='12' lg='12' style='padding: 0px 0px 10px 22px;' v-if="banChart")
                base-material-card(style='padding: 0px 0px 10px 22px; margin:0px;')
                  template(v-slot:heading='')
                    .text-h3.font-weight-light
                      h3 Gráfico de flujo de caja
                  v-container#process-table(fluid='' tag='section' style='padding: 0px 0px 10px 22px;')
                    v-row(style="max-width: 100%;")
                      v-col(cols="12" md="3")
                      v-col(cols='12' md='6' style='padding:0px; margin:0px; overflow: auto')
                        canvas#myChart(width='400' height='400')
          v-tab-item(value='tab-2')
            reports-view

</template>
<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import moment from 'moment'
  import Chart from 'chart.js'
  import * as functions from '../../../functions.js'
  export default {
    components: {
      ReportsView: () => import('@/views/dashboard/component/application/ReportsView'),
    },
    data: () => ({
      banChart: true,
      tabsStatistics: 'tab-1',
      menu1: false,
      menu: false,
      loadingSaveEntry: false,
      loadingSaveEgress: false,
      confirmationDialogEntry: false,
      confirmationDialogEgress: false,
      months: ['ENERO', 'FEBRERO', 'MARZO', 'ABRIL', 'MAYO', 'JUNIO', 'JULIO', 'AGOSTO', 'SEPTIEMBRE', 'OCTUBRE', 'NOVIEMBRE', 'DICIEMBRE'],
      updateEgressVal: false,
      date: moment(new Date()).format('YYYY-MM-DD'),
      monthEgress: '',
      descriptionEgress: '',
      amountEgress: '',
      motivoEgress: '',
      dialogEgress: false,
      timeStampEgress: null,
      idEgressUpdate: '',
      disablesEntry: false,
      updateEntryVal: false,
      date1: moment(new Date()).format('YYYY-MM-DD'),
      monthEntry: '',
      descriptionEntry: '',
      amountEntry: '',
      motivoEntry: '',
      dialogEntry: false,
      timeStampEntry: null,
      idEntryUpdate: '',
      myChart: null,
      loadingTotalDownload: false,
      totalBillRecived: 0,
      recidueRecived: 0,
      totalPaid: 0,
      allProcessesForFinance: [],
      dialogTreatments: false,
      motivoLstFilter: [],
      valuePatient: '',
      loadingDownloadEgress: false,
      loadingDownloadEntry: false,
      menuUntil: false,
      menuFrom: false,
      dateUntil: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString().substr(0, 10),
      dateFrom: moment(new Date()).format('YYYY-MM-DD'),
      valorNeto: 0.00,
      // finanTab: null,
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
    }),
    computed: {
      entryLst () {
        return this.$store.state.financialFac.ingresos
      },
      egressLst () {
        return this.$store.state.financialFac.egresos
      },
      totalEgreso () {
        return this.$store.state.financialFac.totEgresos
      },
      totalIngreso () {
        return this.$store.state.financialFac.totIngresos
      },
    },
    watch: {
      totalIngreso () {
        this.createdChart()
        this.valorNeto = parseFloat(this.totalIngreso) - parseFloat(this.totalEgreso)
        this.updateChart()
      },
      totalEgreso () {
        this.createdChart()
        this.valorNeto = parseFloat(this.totalIngreso) - parseFloat(this.totalEgreso)
        this.updateChart()
      },
    },
    mounted () {
      setTimeout(() => {
        this.createdChart()
        this.filterByDate()
      }, 500)
    },
    methods: {
      removeData (chart) {
        // this.myChart.destroy()
        this.banChart = false
        chart.data.labels.pop()
        chart.data.datasets.forEach((dataset) => {
          dataset.data.pop()
        })
        chart.update()
        this.banChart = true
      },
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      saveEntry () {
        if (this.motivoEntry === '' || this.motivoEntry === null) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes agregar el motivo para continuar',
          }
          return
        }
        if (this.amountEntry === '' || this.amountEntry === null) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes agregar el monto para continuar',
          }
          return
        }
        this.loadingSaveEntry = true
        const date = new Date(this.date1)
        const data = {
          date: this.date1,
          month: this.months[date.getMonth()],
          description: this.descriptionEntry,
          amount: this.amountEntry,
          motivo: this.motivoEntry,
          created: this.timeStampEntry,
        }
        if (this.updateEntryVal) {
          data.id = this.idEntryUpdate
          this.$store.dispatch('financialFac/updateEntryVal', data).then(resolve => {
            this.loadingSaveEntry = false
            this.dialogEntry = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Registro guardado correctamente',
            }
            if (this.valuePatient) {
              // this.$store.dispatch('appointment/loadAppointmentsData', this.valuePatient.id)
              this.$store.dispatch('treatment/loadTreatmentsLst', this.valuePatient.id)
            }
          }, reject => {
            this.loadingSaveEntry = false
            this.dialogEntry = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar el registro',
            }
          })
        } else {
          this.$store.dispatch('financialFac/addEntryVal', data).then(resolve => {
            this.dialogEntry = false
            this.loadingSaveEntry = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Registro guardado correctamente',
            }
            if (this.valuePatient) {
              // this.$store.dispatch('appointment/loadAppointmentsData', this.valuePatient.id)
              this.$store.dispatch('treatment/loadTreatmentsLst', this.valuePatient.id)
            }
          }, reject => {
            this.dialogEntry = false
            this.loadingSaveEntry = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar el registro',
            }
          })
        }
      },
      saveEgress () {
        if (this.motivoEgress === '' || this.motivoEgress === null) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes agregar el motivo para continuar',
          }
          return
        }
        if (this.amountEgress === '' || this.amountEgress === null) {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes agregar el monto para continuar',
          }
          return
        }
        this.loadingSaveEgress = true
        const date = new Date(this.date)
        const data = {
          date: this.date,
          month: this.months[date.getMonth()],
          description: this.descriptionEgress,
          amount: this.amountEgress,
          motivo: this.motivoEgress,
          created: this.timeStampEgress,
        }
        if (this.updateEgressVal) {
          data.id = this.idEgressUpdate
          this.$store.dispatch('financialFac/updateEgressVal', data).then(resolve => {
            this.loadingSaveEgress = false
            this.dialogEgress = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Registro guardado correctamente',
            }
            if (this.valuePatient) {
              // this.$store.dispatch('appointment/loadAppointmentsData', this.valuePatient.id)
              this.$store.dispatch('treatment/loadTreatmentsLst', this.valuePatient.id)
            }
          }, reject => {
            this.dialogEgress = false
            this.loadingSaveEgress = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar el registro',
            }
          })
        } else {
          this.$store.dispatch('financialFac/addEgressVal', data).then(resolve => {
            this.dialogEgress = false
            this.loadingSaveEgress = false
            this.snackbar = {
              show: true,
              color: 'green',
              text: 'Registro guardado correctamente',
            }
            if (this.valuePatient) {
              // this.$store.dispatch('appointment/loadAppointmentsData', this.valuePatient.id)
              this.$store.dispatch('treatment/loadTreatmentsLst', this.valuePatient.id)
            }
          }, reject => {
            this.dialogEgress = false
            this.loadingSaveEgress = false
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Error al guardar el registro',
            }
          })
        }
      },
      acceptConfirmationEntry () {
        this.$store.dispatch('financialFac/rmEntry', this.entryRmId).then(() => {
          this.confirmationDialogEntry = false
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Registro eliminado correctamente',
          }
        }, () => {
          this.confirmationDialogEntry = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al eliminar el registro',
          }
        })
      },
      acceptConfirmationEgress () {
        this.$store.dispatch('financialFac/rmEgress', this.egressRmId).then(() => {
          this.confirmationDialogEgress = false
          this.snackbar = {
            show: true,
            color: 'green',
            text: 'Registro eliminado correctamente',
          }
        }, () => {
          this.confirmationDialogEgress = false
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Error al eliminar el registro',
          }
        })
      },
      createdChart () {
        this.valorNeto = parseFloat(this.totalIngreso) - parseFloat(this.totalEgreso)
        const ctx = document.getElementById('myChart')
        this.myChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: ['Ingresos', 'Egresos', 'Utilidad'],
            datasets: [{
              label: 'VALOR',
              data: [this.totalIngreso, this.totalEgreso, this.valorNeto],
              backgroundColor: [
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 99, 132, 0.2)',
                'rgba(63, 184, 44, 0.2)',
              ],
              borderColor: [
                'rgba(54, 162, 235, 1)',
                'rgba(255, 99, 132, 1)',
                'rgba(63, 184, 44, 1)',
              ],
              borderWidth: 1,
            }],
          },
          options: {
            scales: {
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
          },
        })
        this.myChart.update()
      },
      updateChart () {
        this.removeData(this.myChart)
        const labels = ['Ingresos', 'Egresos', 'Utilidad']
        const datasets = [{
          label: 'VALOR',
          data: [this.totalIngreso, this.totalEgreso, this.valorNeto],
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(63, 184, 44, 0.2)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(63, 184, 44, 1)',
          ],
          borderWidth: 1,
        }]
        this.addData(this.myChart, labels, datasets)
      },
      addData (chart, label, data) {
        chart.data.labels = label
        chart.data.datasets = data
        chart.update()
      },
      updateEntry (entry) {
        this.updateEntryVal = true
        this.dialogEntry = true
        this.date1 = entry.date
        this.descriptionEntry = entry.description
        this.monthEntry = entry.month
        this.amountEntry = entry.amount
        this.idEntryUpdate = entry.id
        if (entry.documentId) {
          this.disablesEntry = true
        } else {
          this.disablesEntry = false
        }
      },
      updateEgress (egress) {
        this.updateEgressVal = true
        this.dialogEgress = true
        this.date = egress.date
        this.descriptionEgress = egress.description
        this.monthEgress = egress.month
        this.amountEgress = egress.amount
        this.idEgressUpdate = egress.id
        if (egress.documentId) {
          this.disablesEgress = true
        } else {
          this.disablesEgress = false
        }
      },
      downloadCSVFileEgresos () {
        this.loadingDownloadEgress = true
        const data = []
        for (let i = 0; i < this.egressLst.length; i++) {
          data.push({
            Fecha: this.egressLst[i].date,
            Mes: this.egressLst[i].month,
            Descripcion: this.egressLst[i].description,
            Monto: '-' + this.egressLst[i].amount.replace('.', ','),
          })
        }
        functions.JSONToCSVConvertor(data, 'Egresos', true)
        this.loadingDownloadEgress = false
      },
      downloadCSVFileIngresos () {
        this.loadingDownloadEntry = true
        const data = []
        for (let i = 0; i < this.entryLst.length; i++) {
          data.push({
            Fecha: this.entryLst[i].date,
            Mes: this.entryLst[i].month,
            Descripcion: this.entryLst[i].description,
            Monto: this.entryLst[i].amount.replace('.', ','),
          })
        }
        functions.JSONToCSVConvertor(data, 'Ingresos', true)
        this.loadingDownloadEntry = false
      },
      newEntry () {
        this.disablesEntry = false
        this.updateEntryVal = false
        this.date1 = moment(new Date()).format('YYYY-MM-DD')
        const date = new Date(this.date1)
        this.monthEntry = this.months[date.getMonth()]
        this.descriptionEntry = ''
        this.amountEntry = ''
        this.motivoEntry = ''
        this.dialogEntry = true
        this.timeStampEntry = new Date().getTime()
        this.idEntryUpdate = ''
      },
      newEgress () {
        this.updateEgressVal = false
        this.date = moment(new Date()).format('YYYY-MM-DD')
        const date = new Date(this.date)
        this.monthEgress = this.months[date.getMonth()]
        this.descriptionEgress = ''
        this.amountEgress = ''
        this.motivoEgress = ''
        this.dialogEgress = true
        this.timeStampEgress = new Date().getTime()
        this.idEgressUpdate = ''
      },
      deleteEntry (id) {
        this.confirmationDialogEntry = true
        this.entryRmId = id
      },
      deleteEgress (id) {
        this.confirmationDialogEgress = true
        this.egressRmId = id
      },
      filterByDate () {
        const currentUser = firebase.auth().currentUser
        const data = {
          firstDay: this.dateFrom,
          lastDay: this.dateUntil,
          idUser: currentUser.uid,
        }
        this.$store.dispatch('financialFac/filterByDateEntry', data)
        this.$store.dispatch('financialFac/filterByDateEgress', data)
      },
      // tabSelect () {
      // },
      downloadCSVFileTotal () {
        this.loadingTotalDownload = true
        const data = []
        for (let i = 0; i < this.entryLst.length; i++) {
          data.push({
            Fecha: this.entryLst[i].date,
            Mes: this.entryLst[i].month,
            Descripcion: this.entryLst[i].description,
            Monto: this.entryLst[i].amount.replace('.', ','),
          })
        }
        for (let i = 0; i < this.egressLst.length; i++) {
          data.push({
            Fecha: this.egressLst[i].date,
            Mes: this.egressLst[i].month,
            Descripcion: this.egressLst[i].description,
            Monto: -this.egressLst[i].amount,
          })
        }
        functions.JSONToCSVConvertor(data, 'Total', true)
        this.loadingTotalDownload = false
      },
    },
  }
</script>
<style lang='scss'>
// span {
//   font-family: 'ceraProRegular', sans-serif !important;
// }
</style>
