<template>
  <div class="container">
    <v-snackbar
      v-model="snackbar.show"
      :multi-line="multiLine"
      :color="snackbar.color"
      top=""
      right=""
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.show = false"
          dark=''
          icon=''
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <v-snackbar
      v-model="snackbar.show"
      :color="snackbar.color"
      top=""
      right=""
    >
      {{ snackbar.text }}
      <v-btn
        dark
        text
        icon=""
        @click="snackbar.show = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar> -->
    <h3
      v-if="completeName!==''"
      class=" text-center"
    >
      {{ completeName }}
    </h3>
    <h3
      v-if="completeName===''"
      class=" text-center"
    >
      Mensajes
    </h3>
    <div class="messaging">
      <div class="inbox_msg">
        <div class="inbox_people">
          <div class="headind_srch">
            <div class="recent_heading">
              <h4>Usuarios</h4>
            </div>
            <div class="srch_bar">
              <div class="stylish-input-group">
                <input
                  v-model="searchUserToChat"
                  type="text"
                  class="search-bar"
                  placeholder="Busca usuarios"
                  @keyup="searchDr"
                >
                <span class="input-group-addon">
                  <button type="button"> <i
                    class="fa fa-search"
                    aria-hidden="true"
                  /> </button>
                </span>
              </div>
            </div>
          </div>
          <div class="inbox_chat">
            <div
              v-for="(user, index) in usersListFilter"
              :key="index"
              :class="[user.isSelected===true?'chat_list active_chat':'chat_list']"
              @click="changeChat(user)"
            >
              <div class="chat_people">
                <div class="chat_img">
                  <img
                    src="https://ptetutorials.com/images/user-profile.png"
                    alt="sunil"
                  >
                </div>
                <div class="chat_ib">
                  <h5>
                    {{ user.firstName }} {{ user.lastName }}
                    <!-- <span class="chat_date">Dec 25</span> -->
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mesgs">
          <div class="msg_history">
            <div
              v-for="(messageData, index) in messageList"
              :key="index"
              class="incoming_msg"
            >
              <!-- <div class="incoming_msg_img">
                <img
                  src="https://ptetutorials.com/images/user-profile.png"
                  alt="sunil"
                >
              </div> -->
              <div :class="[messageData.message.author==='support'?'sent_msg':'received_msg']">
                <div class="received_withd_msg">
                  <p>{{ messageData.message.data.text }}</p>
                  <span
                    v-if="messageData.message.author!=='support'"
                    class="time_date"
                  >
                    Doctor
                  </span>
                  <span
                    v-if="messageData.message.author==='support'"
                    class="time_date"
                  >
                    Soporte
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="type_msg">
            <div class="input_msg_write">
              <input
                v-model="textMessage"
                type="text"
                class="write_msg"
                placeholder="Escribe un mensaje"
                @keyup.enter="saveMessage"
              >
              <v-btn
                class="msg_send_btn"
                icon=""
                color="secondary"
                @click="saveMessage"
              >
                <v-icon>mdi-arrow-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AdminChat',
    components: {
    },
    data: () => ({
      completeName: '',
      // variables para busqueda de Drs
      usersListFilter: [],
      searchUserToChat: '',
      drSelected: null,
      currentDate: new Date().toString().substr(4, 12),
      textMessage: null,
      snackbar: {
        show: false,
        text: '',
        color: '',
        place: '',
      },
    }),
    computed: {
      messageList () {
        return this.$store.state.chat.lstAdminMessage
      },
      allUsersList () {
        return this.$store.state.chat.lstUsers
      },
    },
    watch: {
      messageList () {
        if (this.messageList.length > 0) {
          if (this.messageList[this.messageList.length - 1].creator !== 'Dental Prime Team') {
            this.snackbar = {
              show: true,
              color: 'success',
              text: `Mensaje de ${this.messageList[this.messageList.length - 1].creator}`,
            }
          }
        }
      },
    },
    mounted () {
      this.$store.dispatch('chat/loadChatUsersLst')
      this.$store.dispatch('chat/loadChatAdminMessageLst', [this.currentDate])
      setTimeout(() => {
        this.scrollToBottom()
      }, 2000)
    },
    beforeUpdate () {
      if (this.searchUserToChat === '') {
        this.usersListFilter = this.allUsersList
      }
    },
    methods: {
      searchDr () {
        this.usersListFilter = this.allUsersList.filter(user => {
          return (
            (user.firstName.toUpperCase().includes(this.searchUserToChat.toUpperCase())) ||
            (user.lastName.toUpperCase().includes(this.searchUserToChat.toUpperCase()))
          )
        })
      },
      async changeChat (userSelected) {
        this.completeName = userSelected.firstName + ' ' + userSelected.lastName
        this.messageList = []
        this.drSelected = userSelected.id
        // seccion para pintar Dr seleccionado
        for (let i = 0; i < this.usersListFilter.length; i++) {
          this.usersListFilter[i].isSelected = false
        }
        userSelected.isSelected = true
        await this.$store.dispatch('chat/loadChatAdminMessageLst', [this.currentDate, userSelected.id])
      },
      scrollToBottom () {
        const box = document.querySelector('.msg_history')
        box.scrollTop = box.scrollHeight
      },
      async saveMessage () {
        if (this.textMessage) {
          if (this.drSelected) {
            const message = { type: 'text', author: 'support', data: { text: this.textMessage } }
            this.messageList = [...this.messageList, message]
            await this.$store.dispatch('chat/saveChatMessage', [this.drSelected, message, this.currentDate, 'Dental Prime Team']).then(() => {
              this.scrollToBottom()
            })
            this.textMessage = null
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Debes seleccionar al Dr',
            }
          }
        } else {
          this.snackbar = {
            show: true,
            color: 'red',
            text: 'Debes escribir algo antes de enviar',
          }
        }
      },
    },
  }
</script>
<style>
.write_msg{
  padding: 10px;
}
.container{
  max-width:1170px;
  margin:auto;
}
img{ max-width:100%;}
.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: left;
  overflow: hidden;
  width: 40%; border-right:1px solid #c4c4c4;
}
.inbox_msg {
  border: 1px solid #c4c4c4;
  clear: both;
  overflow: hidden;
}
.top_spac{ margin: 20px 0 0;}

.recent_heading {float: left; width:40%;}
.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}
.headind_srch{ padding:10px 29px 10px 20px; overflow:hidden; border-bottom:1px solid #c4c4c4;}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
.srch_bar input{ border:1px solid #cdcdcd; border-width:0 0 1px 0; width:80%; padding:2px 0 4px 6px; background:none;}
.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
.srch_bar .input-group-addon { margin: 0 0 0 -27px;}

.chat_ib h5{ font-size:15px; color:#464646; margin:0 0 8px 0;}
.chat_ib h5 span{ font-size:13px; float:right;}
.chat_ib p{ font-size:14px; color:#989898; margin:auto}
.chat_img {
  float: left;
  width: 11%;
}
.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people{ overflow:hidden; clear:both;}
.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
  cursor: pointer
}
.inbox_chat { height: 550px; overflow-y: scroll;}

.active_chat{ background:#ebebeb;}

/* .incoming_msg_img {
  display: inline-block;
  width: 6%;
} */
.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
.mesgs {
  float: left;
  padding: 30px 15px 0 25px;
  width: 60%;
}
 .received_withd_msg p {
  background: #ffb7b7 none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
}
.sent_msg {
  display: flex;
  justify-content: flex-end;
}
 .sent_msg p {
  background: #05728f none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color:#fff;
  padding: 5px 10px 5px 12px;
  width:100%;
}
.received_msg {
  display: flex;
  justify-content: flex-start;
  padding: 0 0 0 10px;
  vertical-align: top;
  width: 100%;
 }
.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 100%;
}

.type_msg {border-top: 1px solid #c4c4c4;position: relative;}
.msg_send_btn {
  /* background: #05728f none repeat scroll 0 0; */
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 0;
  top: 5px;
  width: 33px;
}
.messaging { padding: 0 0 50px 0;}
.msg_history {
  height: 516px;
  overflow-y: auto;
}
</style>
