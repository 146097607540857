<template>
  <div>
    <beautiful-chat
      :participants="participants"
      :title-image-url="titleImageUrl"
      :on-message-was-sent="onMessageWasSent"
      :message-list="loadedMessages"
      :new-messages-count="newMessagesCount"
      :is-open="isChatOpen"
      :close="closeChat"
      :icons="icons"
      :open="openChat"
      :show-emoji="false"
      :show-file="false"
      :show-edition="false"
      :show-deletion="false"
      :show-typing-indicator="showTypingIndicator"
      :show-launcher="true"
      :show-close-button="true"
      :colors="colors"
      :always-scroll-to-bottom="alwaysScrollToBottom"
      :message-styling="messageStyling"
      style="z-index: 9999;"
    >
      <template v-slot:header>
        <img
          class="chatTitleLogo"
          src="@/assets/img/chatTitleLogo.png"
          alt="Logo"
        >
        Dental Prime APP Team
      </template>
    </beautiful-chat>
  </div>
</template>
<script>
  import CloseIcon from '../../../../assets/chat-img/close-icon.png'
  import OpenIcon from '../../../../assets/chat-img/logo-no-bg.svg'
  import FileIcon from '../../../../assets/chat-img/file.svg'
  import CloseIconSvg from '../../../../assets/chat-img/close.svg'
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/auth'
  import Chatlogo from '@/assets/img/chatLogo.png'

  export default {
    name: 'DrChat',
    data () {
      return {
        loadedMessages: [],
        currentDate: new Date().toString().substr(4, 12),
        currentDrId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : '',
        icons: {
          open: {
            img: OpenIcon,
            name: 'default',
          },
          close: {
            img: CloseIcon,
            name: 'default',
          },
          file: {
            img: FileIcon,
            name: 'default',
          },
          closeSvg: {
            img: CloseIconSvg,
            name: 'default',
          },
        },
        participants: [
          {
            id: 'support',
            name: 'Soporte',
            imageUrl: Chatlogo,
          },
        ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
        titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
        newMessagesCount: 0,
        isChatOpen: false, // to determine whether the chat window should be open or closed
        showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
        colors: {
          header: {
            bg: '#4e8cff',
            text: '#ffffff',
          },
          launcher: {
            bg: '#4e8cff',
          },
          messageList: {
            bg: '#ffffff',
          },
          sentMessage: {
            bg: '#4e8cff',
            text: '#ffffff',
          },
          receivedMessage: {
            bg: '#eaeaea',
            text: '#222222',
          },
          userInput: {
            bg: '#f4f7f9',
            text: '#565867',
          },
        }, // specifies the color scheme for the component
        alwaysScrollToBottom: true, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
        messageStyling: true, // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
      }
    },
    computed: {
      messageList () {
        return this.$store.state.chat.lstDrMessage
      },
      drData () {
        return this.$store.state.chat.lstDrData
      },
    },
    watch: {
      // para que se abra el chat del dr cuando ha recibido un mensaje desde soporte
      messageList () {
        this.loadedMessages = []
        if (this.messageList.length > 0) {
          if (this.isChatOpen === false && this.messageList[this.messageList.length - 1].message.author === 'support') {
            this.openChat()
          }
          for (let i = 0; i < this.messageList.length; i++) {
            this.loadedMessages.push(this.messageList[i].message)
          }
        }
      },
    },
    methods: {
      sendMessage (text) {
        if (text.length > 0) {
          this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
          this.onMessageWasSent({ author: 'me', type: 'text', data: text })
        }
      },
      onMessageWasSent (message) {
        // called when the user sends a message
        this.messageList = [...this.messageList, message]
        this.$store.dispatch('chat/saveChatMessage', [this.currentDrId, message, this.currentDate, this.drData.firstName + ' ' + this.drData.lastName])
      },
      openChat () {
        // bienvenida de parte de soporte, solo el primer mensaje
        if (this.messageList.length === 0) {
          const message = { type: 'text', author: 'support', data: { text: 'Hola, somos el equipo de Dental Prime APP, en que te podemos ayudar?' } }
          this.loadedMessages.push(message)
          this.$store.dispatch('chat/saveChatMessage', [this.currentDrId, message, this.currentDate, 'Dental Prime Team'])
        }
        // called when the user clicks on the fab button to open the chat
        this.isChatOpen = true
        this.newMessagesCount = 0
      },
      closeChat () {
        // called when the user clicks on the botton to close the chat
        this.isChatOpen = false
      },
    },
  }
</script>
<style>
  .chatTitleLogo{
    margin-right: 20px;
    margin-left: 10px;
  }
  .sc-header {
    min-height: 0%;
    font-weight: 800;
  }
  .v-application p{
    margin: 0px;
    font-weight: 400;
  }
  .sc-header {
    align-items: center;
  }
  .sc-chat-window {
    width: 370px;
    height: calc(100% - 120px);
    max-height: 590px;
    position: fixed;
    right: 25px;
    bottom: 100px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.1);
    box-shadow: 0px 7px 40px 2px rgba(148, 149, 150, 0.1);
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-radius: 10px;
    /* font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; */
    font-family: 'ceraProRegular', sans-serif;
    font-display: swap;
    -webkit-animation: fadeIn-data-v-353e1b70;
    animation: fadeIn-data-v-353e1b70;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
    z-index: 999999 !important;
  }
</style>
