<template lang="pug">
  div
    v-row(justify='center')
      v-col(cols='12' md='12')
        base-material-card
          template(v-slot:heading='')
            .display-2.font-weight-light
              | Checklist atención de calidad
            div(style="widht: 100% text-align: center;")
              a(class="download-pdf" href="https://firebasestorage.googleapis.com/v0/b/app-dental-ece93.appspot.com/o/files%2FChecklist%20atenci%C3%B3n%20de%20calidad%20-%20DENTALPRIME.pdf?alt=media&token=38daa9d6-415e-4b42-b602-a8bac2b7c4c1" target="_blank") Descargar Checklist atención de calidad
          vue-pdf-embed(:source="source1")
          //pdf(src='prueba.pdf')
          //input#file-upload.btn(type='file' @change='openDocument')
          //p-s-p-d-f-kit-container(:pdf-file='pdfFile' @loaded='handleLoaded')
</template>

<script>
  import PSPDFKitContainer from '@/views/dashboard/component/PSPDFKitContainer.vue'
  import pdf from 'vue-pdf'
  import VuePdfEmbed from 'vue-pdf-embed'
  export default {
    name: 'CheckList',
    components: {
      PSPDFKitContainer,
      pdf,
      VuePdfEmbed,
    },
    data () {
      return {
        pdfFile: this.pdfFile || 'prueba.pdf',
        source1: 'prueba.pdf',
      }
    },
    methods: {
      handleLoaded (instance) {
      },

      openDocument () {
        // To access the Vue instance data properties, use `this` keyword.
        if (this.pdfFile) {
          window.URL.revokeObjectURL(this.pdfFile)
        }
        this.pdfFile = window.URL.createObjectURL(
          event.target.files[0],
        )
      },
    },
  }
</script>

<style lang="scss">
  .download-pdf {
    color: #fff !important;
  }
  .download-pdf:hover {
    color: #fff !important;
    text-decoration: underline;
  }
</style>
