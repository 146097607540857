import Vue from 'vue'
import Vuex from 'vuex'
import * as functions from './functions.js'

import { patients } from './store/modules/patients'
import { users } from './store/modules/users'
import { countries } from './store/modules/countries'
import { paises } from './store/modules/paises'
import { general } from './store/modules/general'
import { odontogram } from './store/modules/odontogram'
import { appointment } from './store/modules/appointments'
import { financial } from './store/modules/financial'
import { questions } from './store/modules/questions'
import { antecedentes } from './store/modules/antecedentes'
import { signosvitales } from './store/modules/signosvitales'
import { chat } from './store/modules/chat'
import { menus } from './store/modules/menus'
import { configuraciones } from './store/modules/configuraciones'
import { treatment } from './store/modules/treatments'
import { agenda } from './store/modules/agenda'
import { doctors } from './store/modules/doctors'
import { suscriptionPlan } from './store/modules/suscriptionPlan'
import { notifications } from './store/modules/notifications'
import { logs } from './store/modules/logs'
import { reminders } from './store/modules/reminders'
import { proformas } from './store/modules/proformas'
import { factura } from './store/modules/factura'
import { facturacion } from './store/modules/facturacion'
import { product } from './store/modules/product'
import { profile } from './store/modules/profile'
import { client } from './store/modules/client'
import { provider } from './store/modules/provider'
import { transaction } from './store/modules/transaction'
import { financialFac } from './store/modules/financialFac'
import { ivaretenciones } from './store/modules/ivaretenciones'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    patients,
    users,
    countries,
    general,
    odontogram,
    appointment,
    financial,
    paises,
    questions,
    antecedentes,
    signosvitales,
    chat,
    menus,
    configuraciones,
    treatment,
    agenda,
    doctors,
    suscriptionPlan,
    notifications,
    logs,
    reminders,
    proformas,
    factura,
    facturacion,
    product,
    profile,
    client,
    provider,
    transaction,
    financialFac,
    ivaretenciones,
  },
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://firebasestorage.googleapis.com/v0/b/dental-produccion.appspot.com/o/sidebar%2Fconsultorio-dentistas.jpg?alt=media&token=62082fe0-04f4-4604-8bc3-7dc2a1c883c5',
    tokenImage: '8f51666b-e757-4988-b794-e1c0205569a3',
    drawer: null,
    userData: null,
    ultimaOrden: {
      concepto: '',
      movimiento: '',
      fecha: '',
      unidades: 0,
      valorUnitario: 0,
    },
  },
  mutations: {
    SET_USER_DATA (state, payload) {
      state.userData = payload
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_CLICK_BAR_STORE (modules, val) {
      modules.general.clickBar = val
    },
  },
  actions: {
    getCurrentDay () {
      const today = new Date()
      const dd = String(today.getDate()).padStart(2, '0')
      const mm = String(today.getMonth() + 1).padStart(2, '0')
      const yyyy = today.getFullYear()

      return yyyy + '-' + mm + '-' + dd
    },
    getCurrentTime () {
      const today = new Date()
      const time = today.getHours() + ':' + today.getMinutes()
      return time
    },
    getCurrentTimeInterval ({ state }, interval) {
      const today = new Date()
      today.setHours(today.getHours() + interval)
      const time = today.getHours() + ':' + today.getMinutes()
      return time
    },
    getLocalStorageData ({ commit, state }, item) {
      let obj = localStorage.getItem(item)
      if (obj) {
        obj = JSON.parse(obj)
        obj = functions.decrypt(obj)
        commit('SET_USER_DATA', obj)
        return obj
      } else {
        obj = []
        return obj
      }
    },
    setLocalStorageData ({ state }, data) {
      data.obj = functions.encrypt(data.obj)
      localStorage.setItem(data.item, JSON.stringify(data.obj))
    },
  },
})
