// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const client = {
  namespaced: true,
  state: {
    lstClients: [],
    endClient: null,
  },
  mutations: {
    LOAD_CLIENTS: (state, data) => {
      state.lstClients = data
    },
    LOAD_END_CLIENT: (state, data) => {
      state.endClient = data
    },
  },
  actions: {
    setClient ({ state, rootState, commit }, [clientId, clientData]) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`clients/${rootState.users.user.uid}/${clientId}`).set(clientData).then((res) => {
          commit('LOAD_END_CLIENT', clientData)
          resolve(res)
        }).catch((err) => {
          reject(err)
        })
      })
    },
    loadClients ({ commit, rootState }) {
      if (rootState.users.user) {
        firebase.database().ref(`clients/${rootState.users.user.uid}`).on('value', (snapshot) => {
          let arr = []
          const consumidor = {
            idType: {
              codigo: '07',
              texto: 'VENTA A CONSUMIDOR FINAL*',
            },
            address: 'Consumidor Final',
            clientKey: 'consumidorfinal',
            completeData: 'Consumidor Final | 9999999999999',
            lastName: 'Final',
            mail: '',
            businessName: '',
            name: 'Consumidor',
            phone: '',
            cellphone: '0999999999',
            ide: '9999999999999',
            id: 'consumidorfinal',
            viewName: 'Consumidor Final',
          }
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              arr.unshift(consumidor)
              commit('LOAD_CLIENTS', arr)
            } else {
              arr.unshift(consumidor)
              commit('LOAD_CLIENTS', arr)
            }
          } else {
            arr.unshift(consumidor)
            commit('LOAD_CLIENTS', arr)
          }
        })
      }
    },
    deleteClient ({ state, rootState }, clientId) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`clients/${rootState.users.user.uid}/${clientId}`).remove().then(res => {
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    },
  },
}
