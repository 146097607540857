<template lang="pug">
  v-container#config-price(fluid='' tag='section')
    h1(style='text-align:center;')
      | Tutorial ¿Cómo usar DentalPrime?
    v-row(style='display:flex; align-items:center; justify-content:center;')
      iframe(width="560" height="315" src="https://www.youtube.com/embed/JeFDKsSLoTs" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen)
    br
    br
    h2
      | Agradecimientos
    h3(style='text-align:center;')
      | Fuente de íconos de procedimientos generales
    a(href='https://thenounproject.com/')
      | The Noun Project
</template>
<script>
  export default {
    components: {
    },
    data: () => ({
    }),
  }
</script>
<style>
h1{
  display:flex;
  align-items: center;
  justify-content: center;
}
h2{
  display:flex;
  align-items: center;
  justify-content: center;
}
h3{
  display:flex;
  align-items: center;
  justify-content: center;
}
p{
  display:flex;
  align-items: center;
  justify-content: center;
}
a{
  display:flex;
  align-items: center;
  justify-content: center;
}
</style>
