<template lang="pug">
  v-container.col-md-12.col-12(style="padding: 0px;")
    div.imageDiv(v-for='(img,i) in imgsArray' :key='i')
      img(:src='img.src' :alt='img.alt' :width='img.width' :height='img.height' :class='img.class')
      p.desciptionProcess {{ img.alt }}
</template>
<script>
  export default {
    components: {
    },
    props: {
      imgsArray: {
        default: null,
        type: Array,
      },
    },
    data: () => ({
    }),
  }
</script>
<style scoped>
  .imageDiv {
    display: inline-block;
  }
  .pending {
    border: red solid 2px;
  }
  .alreadyDone {
    border: green solid 2px;
  }
  .concluded {
    border: blue solid 2px;
  }
  .desciptionProcess{
    font-size: 10px;
  }
</style>
