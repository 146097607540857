<template lang="pug">
  v-container#user-profile(fluid='' tag='section')
    v-snackbar(v-model='snackbar.show' :timeout='5000' :color='snackbar.color' top='' right='' :multi-line='multiLine')
      | {{ snackbar.text }}
      template(v-slot:action='{ attrs }')
        v-btn(dark='' text='' icon='' @click='snackbar.show = false' v-bind='attrs')
          v-icon mdi-close
    //- v-snackbar(:timeout='5000' top='' right='' :color='snackbar.color' v-model='snackbar.show')
    //-   | {{ snackbar.text }}
    //-   v-btn(dark='' text='' @click.native='snackbar.show = false' icon='' style='background: transparent;')
    //-     v-icon mdi-close
    v-card
      v-toolbar(text='' color='primary' dark='')
        v-toolbar-title
          h4 Registro de Proveedores
          v-btn(icon="" dark="" style="position: absolute; top: 0px; right: 10px;" @click="$emit('closeModal')")
            v-icon.primary.rounded mdi-close
      v-card-text
        v-row(justify='center')
          v-col(cols='12' md='12')
            v-form(ref='formProvider' v-model='validProvider' lazy-validation='' autocomplete="random-string")
              v-container.py-0
                v-row
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(:rules="requeridos" v-model='provider.name' label='Nombre del proveedor')
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(v-model='provider.mail' label='Correo del proveedor')
                  v-col(cols='12' md='4')
                    v-combobox(:rules="requeridos" v-model='provider.personType' :items='personTypeItems' label='Tipo de Personería')
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(:rules="requeridos" v-model='provider.ruc' label='RUC/CÉDULA' type="text" @keypress="isNumber($event)")
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(:rules="requeridos" v-model='provider.phone' label='Celular del proveedor' type="text" @keypress="isNumber($event)")
                  v-col(cols='12' md='4')
                    v-text-field.purple-input(v-model='provider.telephone' label='Teléfono del proveedor' type="text" @keypress="isNumber($event)")
                  v-col.text-right(cols='12')
                    v-btn.mr-0(color='primary' @click='saveProvider')
                      | GUARDAR PROVEEDOR
</template>

<script>
  // import firebase from 'firebase'
  import firebase from 'firebase/app'
  import 'firebase/database'
  import 'firebase/auth'
  import * as functions from '../../../../functions'
  export default {
    props: {
      providerToEdit: {
        default: function () {
          return {
            name: '',
            mail: '',
            personType: ['Natural'],
            ruc: '',
            telephone: '',
            phone: '',
            providerKey: '',
          }
        },
        type: Object,
      },
    },
    data: () => ({
      snackbar: {
        show: false,
        text: '',
        color: '',
      },
      requeridos: [
        value => !!value || 'Requerido',
      ],
      currentUserId: firebase.auth().currentUser.uid,
      validProvider: true,
      provider: {},
      personTypeItems: [
        'Natural',
        'Jurídica',
      ],
    }),
    computed: {
      providersLst () {
        return this.$store.state.provider.lstProviders
      },
    },
    watch: {
      providerToEdit () {
        this.provider = this.providerToEdit
      },
    },
    mounted () {
      // para no usar la variable de props porque da error al modificar una variable prop
      this.provider = this.providerToEdit
    },
    methods: {
      isNumber (evt) {
        evt = (evt) || window.event
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
          evt.preventDefault()
        } else {
          return true
        }
      },
      async saveProvider () {
        if (this.$refs.formProvider.validate()) {
          // validacion para RUC o cedula
          let result = 0
          if (this.provider.ruc.length > 10) { // se validac omo RUC
            result = functions.validIdentNumberRUC(false, this.provider.ruc)
          } else { // se valida como cedula
            result = functions.validIdentNumberRUC(true, this.provider.ruc)
          }
          if (result.toString() === '1') {
            if (this.provider.providerKey === '' || !this.provider.providerKey) {
              const filterItem = this.providersLst.filter(item => {
                return item.ruc === this.provider.ruc
              })
              if (filterItem.length > 0) {
                this.snackbar = {
                  show: true,
                  color: 'red',
                  text: 'Ya existe un proveedor con ese número de identificación',
                }
                return
              }
              this.provider.providerKey = firebase.database().ref(`providers/${this.currentUserId}`).push().getKey()
            }
            this.provider.completeData = this.provider.name + ' | ' + this.provider.ruc + ' | ' + this.provider.mail
            await this.$store.dispatch('provider/setProvider', [this.provider.providerKey, this.provider]).then(async resolve => {
              this.snackbar = {
                show: true,
                color: 'green',
                text: 'Proveedor registrado correctamente',
              }
              this.$emit('closeModal')
            }, reject => {
              this.snackbar = {
                show: true,
                color: 'red',
                text: 'Error al registrar el proveedor',
              }
            })
          } else {
            this.snackbar = {
              show: true,
              color: 'red',
              text: 'Verifique el número de identifación agregado',
            }
          }
        }
      },
    },
  }
</script>
