// import firebase from 'firebase'
import firebase from 'firebase/app'
import 'firebase/database'

export const antecedentes = {
  namespaced: true,
  state: {
    antecedentes: [],
  },
  mutations: {
    SET_ANTECEDENTES_LIST (state, data) {
      state.antecedentes = data
    },
  },
  actions: {
    loadAntecedentesLst ({ commit, rootState }, idPatient) {
      return new Promise((resolve, reject) => {
        firebase.database().ref(`/${rootState.users.user.selectAccount}/antecedentes/${rootState.users.user.uid}/${idPatient}`).once('value', (snapshot) => {
          let arr = []
          if (snapshot) {
            const res = snapshot.val()
            if (res) {
              arr = Object.keys(res).map(cou => {
                res[cou].id = cou
                return res[cou]
              })
              resolve(arr)
            } else {
              resolve([])
            }
          } else {
            resolve([])
          }
        })
      })
    },
  },
}
